import React, { Component } from 'react';
import { Table, Button, FormGroup, Input, Label } from 'reactstrap';
import { format as formatDate } from 'date-fns';

import MessageForm from '../hocs/MessageForm';
import { initialRoute, } from '../../utils';
import { routeLabel, } from '../../shared/util';
import { texts, routes, services, cancelReasons } from '../../config';
import TableDisplay from '../TableDisplay';

const { keys, entries } = Object;

export default MessageForm(class ReservationConfirmation extends Component {
  constructor() {
    super();
    this.state = {};
  }
  showMenu = () => this.setState({ showsMenu: true })
  render () {
    const { onAction, payload: { selectedReservation, route, startAt, endAt, peopleCount, tableIds, phone, name, nameKana, courseId, tagIds, privateNote, cancelReason, versions, mode }, data: { tenant, customRoutes, courses, tags, tables, }, isActive, } = this.props;
    const { showsMenu = false, notSyncs = {} } = this.state;
    return (
      <div className="reservation-confirmation">
        <Table>
          <thead>
            <tr>
              <th colSpan={3}>
                <div className="d-flex">
                  <span>内容を確認してください</span>
                  <span className="flex-grow-1" onDoubleClick={this.showMenu}></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                種別
              </td>
              <td>
                {texts[mode]}
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>
                開始日時
              </td>
              <td>
                {formatDate(startAt, 'YYYY/MM/DD HH:mm')}
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Date' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                終了日時
              </td>
              <td>
                {formatDate(endAt, 'YYYY/MM/DD HH:mm')}
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Date' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                人数
              </td>
              <td>
                {peopleCount}
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Date' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                テーブル
              </td>
              <td>
                {
                  keys(tableIds || {})
                    .map(tableId => tables.find(_ => _.id === tableId))
                    .map((table) => {
                      return (
                        <div key={table.id}>
                          <TableDisplay table={table} />
                        </div>
                      );
                    })
                }
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Tables' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                電話番号
              </td>
              <td>
                {phone}
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Phone' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                お名前
              </td>
              <td>
                <div>
                  {nameKana}
                </div>
                <div>
                  {name}
                </div>
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Name' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                コース
              </td>
              <td>
                {(courses.find(_ => _.id === courseId) || {}).name || '席のみ'}
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Course' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                予約タグ
              </td>
              <td>
                {
                  keys(tagIds).map(tagId => tags.find(_ => _.id === tagId)).map(_ => _.name).join(', ')
                }
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Tags' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                店舗メモ
              </td>
              <td>
                {privateNote}
              </td>
              <td>
                {
                  mode !== 'revoke' && (
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Note' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  )
                }
              </td>
            </tr>
            {
              ['update', 'revoke'].includes(mode) && (
                <tr>
                  <td>
                    流入経路
                  </td>
                  <td>
                    {routeLabel(initialRoute({ versions, route: selectedReservation.route, }), routes, customRoutes, tenant.routeAliases)}
                  </td>
                  <td>
                  </td>
                </tr>
              )
            }
            {
              mode === 'create' && (
                <tr>
                  <td>
                    経路
                  </td>
                  <td>
                    {routeLabel(route, routes, customRoutes, tenant.routeAliases)}
                  </td>
                  <td>
                    <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'Route' })} disabled={!isActive}>
                      <span className="fas fa-edit" />
                    </Button>
                  </td>
                </tr>
              )
            }
            {
              cancelReason && (
                <tr>
                  <td>
                    取消理由
                  </td>
                  <td>
                    {cancelReasons[cancelReason]}
                  </td>
                  <td>
                    {
                      mode === 'revoke' && (
                        <Button size="sm" color="link" onClick={onAction.bind(this, { type: 'edit', payload: 'CancelReason' })} disabled={!isActive}>
                          <span className="fas fa-edit" />
                        </Button>
                      )
                    }
                  </td>
                </tr>
              )
            }
            {
              showsMenu && (
                <tr>
                  <td colSpan="3" className="table-warning">
                    {
                      entries(services).map(([k, v]) => {
                        return (
                          <FormGroup check key={k}>
                            <Label check>
                              <Input type="checkbox" checked={notSyncs[k]} onChange={this.onCheck.bind(this, 'notSyncs', k)} />
                              {v}に反映しない
                            </Label>
                          </FormGroup>
                        );
                      })
                    }
                  </td>
                </tr>
              )
            }
            <tr>
              <td colSpan="3">
                <div className="d-flex justify-content-around">
                  <Button color="primary" onClick={onAction.bind(this, { type: 'confirmReservation', payload: { notSyncs } })} disabled={!isActive}>OK</Button>
                  {
                    !cancelReason && ['update'].includes(mode) && (
                      <Button color="danger" onClick={onAction.bind(this, { type: 'revokeReservation' })} disabled={!isActive}>予約取消</Button>
                    )
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
});

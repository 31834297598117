import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';
import Select from 'react-select';

import MessageForm from '../hocs/MessageForm';
import firebase from '../../firebase';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default MessageForm(class ReservationCourse extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { courseId } = this.state;
    onAction({ type: 'submitReservationCourse', payload: { courseId } });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['courseId'].some(_ => validations[_] === false);
  }
  render() {
    const { data: { courses }, isActive } = this.props;
    const { courseId } = this.state;
    const courseOptions = [{ label: '席のみ', value: null }, ...(courses || []).map(_ => ({ label: _.name, value: _.id }))];
    return (
      <div className="reservation-course">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>コースを選択してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Select
                    value={courseOptions.find(_ => _.value === courseId)}
                    onChange={this.onChangeSelect.bind(this, 'courseId')}
                    options={courseOptions}
                    isClearable={false}
                    isDisabled={!isActive}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

import React, { Component } from 'react';
import { Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';

import MessageForm from '../hocs/MessageForm';
import firebase from '../../firebase';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default MessageForm(class ReservationTag extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { tagIds } = this.state;
    onAction({ type: 'submitReservationTags', payload: { tagIds } });
  }
  isUnsubmittable() {
    return false;
  }
  render() {
    const { isActive, data: { tags } } = this.props;
    const { tagIds } = this.state;
    return (
      <div className="reservation-tag">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>予約タグを選択してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    (tags || []).map(({ id, name }) => {
                      return (
                        <FormGroup check inline key={id}>
                          <Label check>
                            <Input className="mr-1" type="checkbox" value={id} onChange={this.onCheck.bind(this, 'tagIds', id)} checked={tagIds[id]} disabled={!isActive} />
                            {name}
                          </Label>
                        </FormGroup>
                      );
                    })
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

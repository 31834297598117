import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const breadcrumbs = {
  '/admin': {
    title: 'Home',
  },
  '/admin/tenants': {
    title: 'テナント一覧',
    parent: '/admin',
  },
  '/admin/tenants/:slug': {
    title: 'テナント詳細',
    parent: '/admin/tenants',
  },
  '/admin/googleAuth': {
    title: 'Google連携',
    parent: '/admin',
  },
};

export default class BreadNav extends Component {
  breadcrumbs(path) {
    const breadcrumb = breadcrumbs[path];
    if(!breadcrumb) return [];
    return [...this.breadcrumbs(breadcrumb.parent), { ...breadcrumb, path }];
  }
  render() {
    const { currentPath } = this.props;
    return (
      <Breadcrumb>
        {
          this.breadcrumbs(currentPath).map(({ title, path }) => {
            const isActive = path === currentPath;
            return (
              <BreadcrumbItem key={path} active={isActive}>
                {
                  isActive ? (
                    <span>{title}</span>
                  ) : (
                    <Link to={path}>{title}</Link>
                  )
                }
              </BreadcrumbItem>
            );
          })
        }
      </Breadcrumb>
    );
  }
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { last, get, isEmpty, keyBy, sortBy, uniqBy, omit, debounce, pick, sumBy, orderBy, } from 'lodash';
import { parse as parseDate, format as formatDate, addDays, subDays, startOfDay, setHours, addHours, startOfHour, startOfMinute, addMinutes } from 'date-fns';
import numeral from 'numeral';
import qs from 'qs';

import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import Message from '../Message/';
import TenantHeaderNav from '../TenantHeaderNav';

const db = firebase.firestore();
const customRoutesRef = db.collection('customRoutes');
const tenantsRef = db.collection('tenants');
const { keys } = Object;

const initialState = {
  targetDate: startOfDay(new Date()),
  startAt: startOfHour(setHours(new Date(), 18)),
  peopleCount: 0,
  tableIds: {},
  phone: '',
  name: '',
  nameKana: '',
  courseId: null,
  tagIds: {},
  privateNote: '',
  cancelReason: null,
  isEditing: false,
  locksAllocation: false,
  route: 'phone',
  notSyncs: {},
  selectedId: null,
};

export default TenantPage(class TenantRoot extends Component {
  constructor() {
    super();
    this.state = {
      messages: [{
        from: 'bot',
        type: 'home',
        createdAt: new Date(),
      }],
      ...initialState,
    }
  }
  componentDidMount() {
    this.listenCustomRoutes();
    this.listenCourses();
    this.listenTags();
    this.listenTables();
    this.listenWaitings();
    this.listenOverflowings();
    this.listenViaPhoneNotes();
    this.listenOverStarts();
    this.listenOverEnds();
    this.applyQueryParams();
  }
  applyQueryParams() {
    const { history, location: { search, pathname } } = this.props;
    const { state, response } = qs.parse(search.slice(1));
    state && this.setState(state);
    get(state, 'startAt') && this.setState({ startAt: new Date(state.startAt) });
    response && setTimeout(_ => this.respond(response), 100);
    history.replace(pathname);
  }
  componentDidUpdate(_, prevState) {
    if(prevState.messages !== this.state.messages) {
      this.scrollToBottom();
    }
  }
  scrollToBottom = debounce(() => {
    const htmlNode = window.document.querySelector('html');
    window.scroll(0, htmlNode.scrollHeight);
  }, 100);
  listenCustomRoutes() {
    customRoutesRef
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customRoutes: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .orderBy('price')
      .onSnapshot((snapshot) => {
        this.setState({ courses: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenTags() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tags')
      .onSnapshot((snapshot) => {
        this.setState({ tags: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        const tables = snapshot.docs.map(_ => ({ ..._.data(), id: _.id }));
        this.setState({ tables, tablesById: keyBy(tables, 'id') });
      });
  }
  listenWaitings() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('reservations')
      .where('tableIds', '==', null)
      .where('startAt', '>=', startOfDay(new Date()))
      .onSnapshot((snapshot) => {
        this.setState({ waitings: sortBy(snapshot.docs.map(_ => ({ ..._.data(), id: _.id })), 'startAt') });
      });
  }
  listenOverflowings() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('reservations')
      .where('isOverflowing', '==', true)
      .where('startAt', '>=', startOfDay(new Date()))
      .onSnapshot((snapshot) => {
        const overflowings = sortBy(snapshot.docs.map(_ => ({ ..._.data(), id: _.id })), 'startAt');
        this.setState({ overflowings });
      });
  }
  listenViaPhoneNotes() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('viaPhoneNotes')
      .where('status', '==', 'initial')
      .onSnapshot((snapshot) => {
        const viaPhoneNotes = sortBy(snapshot.docs.map(_ => ({ ..._.data(), id: _.id })), 'createdAt');
        this.setState({ viaPhoneNotes });
      });
  }
  listenOverStarts() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('reservations')
      .where('status', '==', 'overStart')
      .where('startAt', '>=', startOfDay(new Date()))
      .onSnapshot((snapshot) => {
        this.setState({ overStarts: sortBy(snapshot.docs.map(_ => ({ ..._.data(), id: _.id })), 'startAt') });
      });
  }
  listenOverEnds() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('reservations')
      .where('status', '==', 'overEnd')
      .where('startAt', '>=', startOfDay(new Date()))
      .onSnapshot((snapshot) => {
        this.setState({ overEnds: sortBy(snapshot.docs.map(_ => ({ ..._.data(), id: _.id })), 'startAt') });
      });
  }
  onAction = ({ type, payload }) => {
    this.addMessage({ from: 'user', type, payload });
    this.respond({ type, payload });
  }
  addMessage = (message) => {
    const { messages } = this.state;
    this.setState({
      messages: [...messages, { ...message, createdAt: new Date() }]
    });
  }
  reset = () => {
    const { messages } = this.state;
    const [{ type }] = messages.slice(-1);
    if(type === 'home') return;
    if(!window.confirm('内容を破棄してホームに戻りますか？')) return;
    this.onAction({ from: 'user', type: 'reset' });
  }
  respond = ({ type, payload }) => {
    const { match: { params: { slug } } } = this.props;
    const { isEditing } = this.state;
    let fns;
    setTimeout((fns = {
      toHome: () => {
        this.clear();
        this.addMessage({ from: 'bot', type: 'home' });
      },
      reset: () => {
        this.clear();
        this.addMessage({ from: 'bot', type: 'home' });
      },
      walkIn: () => {
        const { tenant } = this.props;
        const data = {
          startAt: startOfMinute(new Date()),
          phone: '08000000000',
          name: 'ウォークイン',
          nameKana: 'うぉーくいん',
        };
        this.setState({
          mode: 'walkIn',
          ...data,
        });
        this.addMessage({ from: 'bot', type: 'reservationDate', payload: { ...this.state, ...data, tenant, peopleCount: this.state.peopleCount ? this.state.peopleCount : null } });
      },
      reserve: () => {
        const { tenant } = this.props;
        this.setState({ mode: 'create' });
        this.addMessage({ from: 'bot', type: 'reservationDate', payload: { ...this.state, tenant, peopleCount: this.state.peopleCount ? this.state.peopleCount : null } })
      },
      editReservation: () => {
        const { tenant } = this.props;
        this.setState({ mode: 'update' });
        this.addMessage({ from: 'bot', type: 'targetDate', payload: { ...this.state, tenant, peopleCount: this.state.peopleCount ? this.state.peopleCount : null } })
      },
      revokeReservation: () => {
        this.setState({ mode: 'revoke' });
        const { selectedId } = this.state;
        const { tenant } = this.props;
        if(selectedId) {
          this.addMessage({ from: 'bot', type: 'reservationCancelReason', payload: { ...this.state } });
        } else {
          this.addMessage({ from: 'bot', type: 'targetDate', payload: { ...this.state, tenant, peopleCount: this.state.peopleCount ? this.state.peopleCount : null } })
        }
      },
      submitReservationRoute: () => {
        this.setState({ ...payload });
        // const type = ({ create: 'reservationDate', update: 'targetDate', revoke: 'targetDate' })[mode];
        this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      viewWaitings: () => {
        this.setState({ mode: 'update' });
        const { match: { params: { slug } } } = this.props;
        const { waitings } = this.state;
        if(waitings.length > 0) {
          this.addMessage({ from: 'bot', type: 'waitings', payload: { ...this.state, waitings } });
        } else {
          fns.toHome();
        }
      },
      viewOverflowings: () => {
        this.setState({ mode: 'update' });
        const { match: { params: { slug } } } = this.props;
        const { overflowings } = this.state;
        if(overflowings.length > 0) {
          this.addMessage({ from: 'bot', type: 'overflowings', payload: { ...this.state, overflowings } });
        } else {
          fns.toHome();
        }
      },
      viewViaPhoneNotes: () => {
        this.setState({ mode: 'update' });
        const { match: { params: { slug } } } = this.props;
        const { viaPhoneNotes } = this.state;
        if(viaPhoneNotes.length > 0) {
          this.addMessage({ from: 'bot', type: 'viaPhoneNotes', payload: { ...this.state, viaPhoneNotes } });
        } else {
          fns.toHome();
        }
      },
      viewOverStarts: () => {
        this.setState({ mode: 'update' });
        const { match: { params: { slug } } } = this.props;
        const { overStarts } = this.state;
        if(overStarts.length > 0) {
          this.addMessage({ from: 'bot', type: 'overStarts', payload: { ...this.state, overStarts, } });
        } else {
          fns.toHome();
        }
      },
      viewOverEnds: () => {
        this.setState({ mode: 'update' });
        const { match: { params: { slug } } } = this.props;
        const { overEnds } = this.state;
        if(overEnds.length > 0) {
          this.addMessage({ from: 'bot', type: 'overEnds', payload: { ...this.state, overEnds: [...overEnds] } });
        } else {
          fns.toHome();
        }
      },
      updateStatus: async () => {
        const { match: { params: { slug } } } = this.props;
        const { status, reservationId, nextType, values = {} } = payload;
        const snap = await tenantsRef.doc(slug).collection('reservations').doc(reservationId).get();
        const prev = snap.data();
        const versions = [...(prev.versions || []), omit(prev, 'versions')];
        await tenantsRef
          .doc(slug)
          .collection('reservations')
          .doc(reservationId)
          .update({ ...values, status, versions });
        fns[nextType]();
      },
      cancelled: async () => {
        const { nextType, } = payload;
        fns[nextType]();
      },
      allowOverflowings: async () => {
        const { match: { params: { slug } } } = this.props;
        const { reservationId, nextType, values = {} } = payload;
        const snap = await tenantsRef.doc(slug).collection('reservations').doc(reservationId).get();
        const prev = snap.data();
        const versions = [...(prev.versions || []), omit(prev, 'versions')];
        await tenantsRef
          .doc(slug)
          .collection('reservations')
          .doc(reservationId)
          .update({ ...values, hasAllowedOverflowings: true, versions });
        fns[nextType]();
      },
      resolveViaPhoneNotes: async () => {
        const { match: { params: { slug } } } = this.props;
        const { viaPhoneNoteId, nextType } = payload;
        await tenantsRef
          .doc(slug)
          .collection('viaPhoneNotes')
          .doc(viaPhoneNoteId)
          .update({ status: 'resolved', });
        fns[nextType]();
      },
      editSpecifiedReservation: async () => {
        const { match: { params: { slug } } } = this.props;
        const { reservationId, locksAllocation = false } = payload;
        const snap = await tenantsRef.doc(slug).collection('reservations').doc(reservationId).get();
        const reservation = snap.data();
        const data = {
          ...omit(reservation, ['route']),
          startAt: reservation.startAt.toDate(),
          endAt: reservation.endAt.toDate(),
          route: 'direct',
          selectedReservation: reservation,
        };
        this.setState({ ...data, selectedId: reservationId, locksAllocation });
        this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...data } });
      },
      submitTargetDate: () => {
        this.setState({ ...payload });
        const { targetDate } = payload;
        const { match: { params: { slug } } } = this.props;
        tenantsRef
          .doc(slug)
          .collection('reservations')
          .where('startAt', '>=', targetDate)
          .where('startAt', '<=', addDays(targetDate, 1))
          .get()
          .then((snapshot) => {
            const reservations = snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).filter(_ => !_.cancelReason);
            if(reservations.length > 0) {
              this.addMessage({ from: 'bot', type: 'reservationSelection', payload: { reservations } });
            } else {
              this.addMessage({ from: 'bot', type: 'targetDate', payload: { isNotFound: true, ...this.state } });
            }
          });
      },
      submitReservationSelection: async () => {
        const { mode } = this.state;
        const { selectedReservationId } = payload;
        const selectedReservation = (await tenantsRef.doc(slug).collection('reservations').doc(selectedReservationId).get()).data() || {};
        const data = {
          selectedReservation,
          ...omit(selectedReservation, ['route', 'notSyncs']),
          startAt: selectedReservation.startAt.toDate(),
          endAt: selectedReservation.endAt.toDate(),
        };
        this.setState({ ...data, selectedId: selectedReservationId,  });
        ({
          update: () => this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...data } }),
          revoke: () => this.addMessage({ from: 'bot', type: 'reservationCancelReason', payload: { ...this.state } }),
        })[mode]();
      },
      submitReservationCancelReason: () => {
        this.setState({ ...payload });
        this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state } });
      },
      submitReservationDate: async () => {
        const { locksAllocation, disableAllocation, } = this.state;
        this.setState({ ...payload });
        if(locksAllocation) {
          this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state } });
        } else {
          if(disableAllocation) {
            fns.editTables();
          } else {
            const tables = await this.allocate(payload);
            !isEmpty(tables) && this.setState({ tableIds: tables.reduce((x, y) => ({ ...x, [y.id]: true }), {}) });
            this.addMessage({ from: 'bot', type: 'autoTableConfirmation', payload: { tables, isEditing } });
          }
        }
      },
      confirmAutoTable: () => {
        const { mode } = this.state;
        !isEditing && mode !== 'walkIn' ?
          this.addMessage({ from: 'bot', type: 'reservationPhone', payload: { ...this.state } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state } });
      },
      editTables: () => {
        const { selectedId, tableIds, startAt, endAt, peopleCount } = this.state;
        const { tenant, match: { params: { slug } } } = this.props;
        return Promise.all([
          tenantsRef.doc(slug).collection('reservations').where('endAt', '>=', startAt).where('endAt', '<=', addDays(startAt, 1)).get(),
          tenantsRef.doc(slug).collection('reservations').where('startAt', '<=', endAt).where('startAt', '>=', subDays(endAt, 1)).get(),
        ])
          .then(([...snapshots]) => {
            const reservations = uniqBy(snapshots.reduce((x, y) => [...x.docs, ...y.docs]).map(_ => ({ ..._.data(), id: _.id })), 'id').filter(_ => !_.cancelReason).filter(_ => _.id !== selectedId);
            this.addMessage({ from: 'bot', type: 'reservationTables', payload: { selectedId, tableIds, reservations, startAt, endAt, tenant, peopleCount } });
          });
      },
      submitReservationTables: () => {
        const { mode } = this.state;
        this.setState({ ...payload });
        !isEditing && mode !== 'walkIn' ?
          this.addMessage({ from: 'bot', type: 'reservationPhone', payload: { ...this.state } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      submitReservationPhone: async () => {
        const { match: { params: { slug } } } = this.props;
        const snap = await tenantsRef.doc(slug).collection('customers').doc(payload.phone).get();
        const { name, nameKana } = snap.data() || {};
        const customerData = {
          name: name || this.state.name,
          nameKana: nameKana || this.state.nameKana,
        };
        this.setState({ ...payload, ...customerData });
        !isEditing ?
          this.addMessage({ from: 'bot', type: 'reservationName', payload: { ...this.state, ...customerData, validateOnMount: (snap.data() != null) } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      submitReservationName: () => {
        this.setState({ ...payload });
        !isEditing ?
          this.addMessage({ from: 'bot', type: 'reservationCourse', payload: { ...this.state } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      submitReservationCourse: () => {
        this.setState({ ...payload });
        !isEditing ?
          this.addMessage({ from: 'bot', type: 'reservationTags', payload: { ...this.state } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      submitReservationTags: () => {
        this.setState({ ...payload });
        !isEditing ?
          this.addMessage({ from: 'bot', type: 'reservationNote', payload: { ...this.state } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      submitReservationNote: () => {
        this.setState({ ...payload });
        !isEditing ?
          this.addMessage({ from: 'bot', type: 'reservationRoute', payload: { ...this.state, validateOnMount: true } })
        : this.addMessage({ from: 'bot', type: 'reservationConfirmation', payload: { ...this.state, ...payload } });
      },
      confirmReservation: () => {
        this.setState({ ...payload });
        const selectedId = this.state.selectedId;
        this.fix().then(() => {
          this.addMessage({ from: 'bot', type: 'fixed', payload: { ...this.state, selectedId, } });
          this.addMessage({ from: 'bot', type: 'home' });
          this.clear();
        });
      },
      edit: () => {
        const { tenant } = this.props;
        this.setState({ isEditing: true });
        payload === 'Tables' ?
          fns.editTables()
        : this.addMessage({ from: 'bot', type: `reservation${payload}`, payload: { ...this.state, tenant, shouldApplyValues: true } });
      },
    })[type], 0);
  }
  clear() {
    this.setState({ ...initialState });
  }
  allocate = ({ startAt, endAt, peopleCount }) => {
    const { tenant: { marginDuration = 0 }, match: { params: { slug } } } = this.props;
    const { selectedId, tablesById = {}, } = this.state;
    return Promise.all([
      tenantsRef.doc(slug).collection('tables').where('capacity', '>=', peopleCount).get(),
      tenantsRef.doc(slug).collection('tableCombinables').get(),
      tenantsRef.doc(slug).collection('reservations').where('endAt', '>=', startAt).where('endAt', '<=', addDays(startAt, 1)).get(),
      tenantsRef.doc(slug).collection('reservations').where('startAt', '<=', endAt).where('startAt', '>=', subDays(endAt, 1)).get(),
    ])
      .then(([candidateTablesSnapshot, tableCombinablesSnapshot, ...snapshots]) => {
        const reservations = snapshots.reduce((x, y) => [...x.docs, ...y.docs]).map(_ => ({ ..._.data(), id: _.id })).filter(_ => !_.cancelReason).filter(_ => _.id !== selectedId);
        const tableCandidates = candidateTablesSnapshot.docs
          .map(_ => ({ ..._.data(), id: _.id }))
          .filter(({ id, name }) => {
            return reservations.every((reservation) => {
              return !(reservation.tableIds || {})[id] || (startAt >= addMinutes(reservation.endAt.toDate(), marginDuration) || addMinutes(endAt, marginDuration) <= reservation.startAt.toDate());
            });
          })
        const [tableCandidate] = sortBy(tableCandidates, 'capacity');
        if(tableCandidate) return [tableCandidate];
        const tableCombinables = tableCombinablesSnapshot
          .docs
          .map(_ => ({ ..._.data(), id: _.id }))
          .map((tableCombinable) => {
            const { tables: combinableTableIds = {} } = tableCombinable;
            const vacantTables = keys(combinableTableIds)
              .map(_ => tablesById[_])
              .filter(({ id }) => {
                return reservations.every((reservation) => {
                  return !(reservation.tableIds || {})[id] || (startAt >= addMinutes(reservation.endAt.toDate(), marginDuration) || addMinutes(endAt, marginDuration) <= reservation.startAt.toDate());
                });
              })
            const totalCapacity = vacantTables.reduce((x, y) => x + y.capacity, 0);
            return { ...tableCombinable, vacantTables, totalCapacity };
          });
        const candidateCombinable = tableCombinables.find(_ => _.totalCapacity >= peopleCount);
        if(!candidateCombinable) return [];

        // NOTE: 例) 全部で20人。10, 9, 8, 7, 6, 5のテーブル結合だったとする。
        const sortedVacantTables = orderBy(candidateCombinable.vacantTables, 'capacity', 'desc');
        return sortedVacantTables.reduce((x, table, i) => {
          const prevsCapacity = sumBy(x, 'capacity');
          // NOTE: [10, 9, 5]になった場合
          if(prevsCapacity >= peopleCount) {
            return x;

          // NOTE: [10]の場合
          } else if ((prevsCapacity + table.capacity) <= peopleCount) {
            return [...x, table];

          // NOTE: [10, 9]の場合
          } else {
            const minTable = last(sortedVacantTables.slice(i).filter(_ => _.capacity + prevsCapacity >= peopleCount));
            return [...x, minTable];
          }
        }, []);
      });
  }
  fix = async () => {
    const { user, match: { params: { slug } } } = this.props;
    const { route, selectedId, startAt, endAt, peopleCount, tableIds, phone, name, nameKana, courseId, tagIds, privateNote, mode, cancelReason = null, tables = [], notSyncs = {} } = this.state;
    const now = new Date();
    const targetTables = keys(tableIds || {}).map(tableId => tables.find(_ => _.id === tableId));
    const capacity = sumBy(targetTables, _ => _.capacity);
    const data = { type: mode, startAt, endAt, peopleCount, tableIds, phone, name, nameKana, courseId, tagIds, cancelReason, receptedBy: pick(user, ['uid', 'name']), receptedAt: now, route: mode === 'walkIn' ? 'walkIn' : route, capacity, isOverflowing: peopleCount > capacity, notSyncs, privateNote, };
    const reservationsRef = tenantsRef.doc(slug).collection('reservations');
    const create = async ({ status = 'initial' } = {}) => {
      // TODO: 後々、必ずユニークになるようにする
      const { docs: [{ id: prevId = '' } = {}] } = await reservationsRef.orderBy('createdAt', 'desc').limit(1).get();
      const [, prevNumber] = prevId.match(new RegExp(`${formatDate(now, 'YYMMDD')}(\\d{3})`)) || [];
      const number = prevNumber ? numeral(parseInt(prevNumber, 10) + 1).format('000') : '001';
      const id = formatDate(now, 'YYMMDD') + number;
      return reservationsRef.doc(id).set({ ...data, status, createdAt: now });
    }
    const update = async () => {
      const snap = await reservationsRef.doc(selectedId).get();
      const prev = snap.data();
      const status = (({
        overStart: () => startAt > now ? 'initial' : 'overStart',
        overEnd: () => endAt > now ? 'came' : 'overEnd',
      })[prev.status] || (() => prev.status))();
      const versions = [...(prev.versions || []), omit(prev, 'versions')];
      return reservationsRef.doc(selectedId).update({ ...prev, ...data, status, versions });
    };
    await ({
      walkIn: create.bind(this, { status: 'came' }),
      create, 
      update,
      revoke: update,
    })[mode]();
    this.setState({ selectedId: null });
  }
  render() {
    const { tenant, user, match: { params: { slug } } } = this.props;
    const { customRoutes = [], messages, courses, tags, tables, waitings, overflowings, viaPhoneNotes, overStarts, overEnds } = this.state;
    return (
      <div className="tenant-root">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} onClickHome={this.reset} showsDate />
        <div className="container p-4 pt-5 mt-5">
          {
            messages.map((message, i) => {
              return (
                <Message key={i} {...message} isActive={i === messages.length - 1} onAction={this.onAction} data={{ tenant, customRoutes, courses, tags, tables, waitings, overflowings, viaPhoneNotes, overStarts, overEnds }} slug={slug} />
              );
            })
          }
        </div>
      </div>
    );
  }
});

import React from 'react';
import { format as formatDate, distanceInWordsToNow } from 'date-fns';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Home from './Home';
import ReservationRoute from './ReservationRoute';
import ReservationDate from './ReservationDate';
import AutoTableConfirmation from './AutoTableConfirmation';
import ReservationTables from './ReservationTables';
import ReservationPhone from './ReservationPhone';
import ReservationName from './ReservationName';
import ReservationCourse from './ReservationCourse';
import ReservationTags from './ReservationTags';
import ReservationNote from './ReservationNote';
import ReservationConfirmation from './ReservationConfirmation';
import TargetDate from './TargetDate';
import ReservationSelection from './ReservationSelection';
import Waitings from './Waitings';
import Overflowings from './Overflowings';
import ViaPhoneNotes from './ViaPhoneNotes';
import OverStarts from './OverStarts';
import OverEnds from './OverEnds';
import ReservationCancelReason from './ReservationCancelReason';
import Fixed from './Fixed';
import TableDisplay from '../TableDisplay';
import { routes, statuses } from '../../config';
import { routeLabel, } from '../../shared/util';

const { keys } = Object;
function FromBot({ type, payload, onAction, data, isActive, slug }) {
  const Component = {
    home: Home,
    reservationRoute: ReservationRoute,
    targetDate: TargetDate,
    waitings: Waitings,
    overflowings: Overflowings,
    viaPhoneNotes: ViaPhoneNotes,
    overStarts: OverStarts,
    overEnds: OverEnds,
    reservationSelection: ReservationSelection,
    reservationDate: ReservationDate,
    autoTableConfirmation: AutoTableConfirmation,
    confirmAutoTable: AutoTableConfirmation,
    reservationTables: ReservationTables,
    reservationPhone: ReservationPhone,
    reservationName: ReservationName,
    reservationCourse: ReservationCourse,
    reservationTags: ReservationTags,
    reservationNote: ReservationNote,
    reservationConfirmation: ReservationConfirmation,
    reservationCancelReason: ReservationCancelReason,
    fixed: Fixed,
  }[type];
  return (
    <div className="from-bot d-flex justify-content-start">
      <div className="balloon p-3" style={{ borderRadius: '0 20px 20px 20px', background: '#eee', color: '#666' }}>
        <Component {...{ payload, data, onAction, isActive, slug, }} />
      </div>
    </div>
  );
}

function FromUser({ type, onAction, data: { tenant, customRoutes, tables, courses, tags }, payload, slug }) {
  const { route, targetDate, selectedReservationId, reservations, startAt, endAt, peopleCount, tableIds, phone, name, nameKana, courseId, tagIds, privateNote, mode, cancelReason, table } = payload || {};
  const text = ({
    reset: () => '内容を破棄してホームへ',
    toHome: () => 'ホームへ',
    walkIn: () => 'ウォークイン',
    reserve: () => '新規予約',
    editReservation: () => '予約変更',
    revokeReservation: () => '予約取消',
    viewWaitings: () => 'ウェイティング予約',
    viewOverflowings: () => '定員オーバー予約',
    viewViaPhoneNotes: () => 'AIロボ経由の連絡事項',
    viewOverStarts: () => '来店時刻到来済み予約',
    viewOverEnds: () => '退店時刻到来済み予約',
    updateStatus: () => {
      const { reservationId, status } = payload;
      return (
        <div>
          <div>{statuses[status].name}</div>
          <div>
            (予約ID: <Link target="_blank" className="text-white" to={`/t/${slug}/reservations/${reservationId}`}><u>{reservationId} <span className="ml-1 fas fa-external-link-alt" /></u></Link>)
          </div>
        </div>
      );
    },
    cancelled: () => {
      const { reservationId, } = payload;
      return (
        <div>
          <div>キャンセル</div>
          <div>(予約ID: {reservationId})</div>
        </div>
      );
    },
    allowOverflowings: () => {
      const { reservationId } = payload;
      return (
        <div>
          <div>定員オーバー了承</div>
          <div>(予約ID: {reservationId})</div>
        </div>
      );
    },
    resolveViaPhoneNotes: () => {
      const { reservationId } = payload;
      return (
        <div>
          <div>解決済み</div>
        </div>
      );
    },
    editSpecifiedReservation: () => {
      const { reservationId, status } = payload;
      return (
        <div>
          <div>変更</div>
          <div>(予約ID: {reservationId})</div>
        </div>
      );
    },
    submitReservationRoute: () => routeLabel(route, routes, customRoutes, tenant.routeAliases),
    submitTargetDate: () => `${formatDate(targetDate, 'YYYY/MM/DD')}`,
    submitReservationSelection: () => {
      const selectedReservation = reservations.find(_ => _.id === selectedReservationId);
      const { startAt, endAt, peopleCount, tableIds, name } = selectedReservation;
      return `${formatDate(startAt.toDate(), 'HH:mm')} - ${formatDate(endAt.toDate(), 'HH:mm')} ${keys(tableIds || {}).map(id => tables.find(_ => _.id === id).name).join(', ')} ${name} ${peopleCount}名様`;
    },
    submitReservationCancelReason: () => cancelReason || '(取消理由なし)',
    submitReservationDate: () => `${formatDate(startAt, 'YYYY/MM/DD HH:mm')} - ${formatDate(endAt, 'YYYY/MM/DD HH:mm')} ${peopleCount}名`,
    confirmAutoTable: () => <span>OK。{payload.tables.map((table) => <div key={table.id}><TableDisplay table={table} /></div>)}</span>,
    editTables: () => '手動配席',
    submitReservationTables: () => keys(tableIds || {}).map(tableId => tables.find(_ => _.id === tableId)).map(_ => _.name).join(', ') || 'テーブルなし',
    submitReservationPhone: () => phone,
    submitReservationName: () => `${name} (${nameKana})`,
    submitReservationCourse: () => (courses.find(_ => _.id === courseId) || {}).name || '席のみ',
    submitReservationTags: () => keys(tagIds).map(tagId => tags.find(_ => _.id === tagId)).map(_ => _.name).join(', ') || '(タグなし)',
    submitReservationNote: () => privateNote || '(店舗メモなし)',
    confirmReservation: () => 'OK',
    edit: () => '編集',
  })[type]();
  return (
    <div className="from-user d-flex justify-content-end">
      <div className="balloon p-3 bg-primary text-light" style={{ borderRadius: '20px 0 20px 20px' }}>
        {text}
      </div>
    </div>
  );
}

export default function Message({ from, type, payload, onAction, data, isActive, createdAt, slug }) {
  const Component = {
    bot: FromBot,
    user: FromUser,
  }[from];
  return (
    <div className="message mb-4">
      <Component {...{ type, payload, onAction, data, isActive, slug }} />
      <div className={classnames('text-muted', 'small', 'mt-2', `text-${from === 'bot' ? 'left' : 'right'}`)}>
        {formatDate(createdAt, 'HH:mm')}
      </div>
    </div>
  );
};

import React, { Component } from 'react';
import { Button } from 'reactstrap';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import initAuth2 from '../../googleApi';

const db = firebase.firestore();
const settingsRef = db.collection('settings');

export default AdminPage(class AdminGoogleAuth extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.listenSetting();
  }
  listenSetting() {
    settingsRef.doc('gmailAuth').onSnapshot((snap) => {
      this.setState({ gmailAuth: snap.data() });
    });
  }
  integrate = async () => {
    const auth2 = await initAuth2();
    auth2.grantOfflineAccess().then(({ code }) => {
      settingsRef
        .doc('gmailAuth')
        .set({ googleAuthorizationCode: code });
    });
  }
  unintegrate = () => {
    if(!window.confirm('本当に解除しますか？')) return;
    settingsRef
      .doc('gmailAuth')
      .update({ googleAuthorizationCode: null, tokens: null });
  }
  render() {
    const { gmailAuth } = this.state;
    return (
      <div className="admin-google-auth">
        <div className="d-flex justify-content-center mb-3">
          <h4>Google連携</h4>
        </div>
        <div className="d-flex justify-content-center">
          {
            (gmailAuth || {}).googleAuthorizationCode ? (
              <Button color="danger" onClick={this.unintegrate}>
                連携解除
              </Button>
            ) : (
              <Button color="primary" onClick={this.integrate}>
                連携
              </Button>
            )
          }
        </div>
      </div>
    );
  }
});

import React, { Component, } from 'react';
import { Button, Nav, NavItem, NavLink, } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const tabs = [
  { name: 'list', label: 'リスト', path: 'reservations' },
  { name: 'courseStats', label: 'コース集計', path: 'reservationCourseStats' },
  { name: 'routeStats', label: '流入経路集計', path: 'reservationRouteStats' },
];

export default function ReservationsHeader (props) {
  const { activeTab, slug, location } = props;

  return (
    <div>
      <Nav tabs>
        {
          tabs.map(({ name, label, path }) => {
            return (
              <NavItem>
                <NavLink
                  tag={Link}
                  className={classnames({ active: activeTab === name })}
                  to={`/t/${slug}/${path}${location.search}`}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })
        }
      </Nav>
    </div>
  );
};

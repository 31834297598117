import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { isEmpty, mapValues } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';

import { routes } from '../../config';

const { entries } = Object;

export default function CustomRouteFormModal(props) {
  const { isOpen, values, customRoutes = [], onClickClose } = props;
  const [privateNote, setPrivateNote] = useState(values.privateNote);
  const [initialRoute, setInitialRoute] = useState(values.initialRoute);
  const routeOptions = [
    ...entries(routes).map(([value, label]) => ({ label, value })),
    ...customRoutes.map(_ => ({ label: _.label, value: _.id })),
  ];
  const onSubmit = (event) => {
    event.preventDefault();
    props.onSubmit({ privateNote, initialRoute });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        予約編集
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>店舗メモ</Label>
            <Input type="textarea" value={privateNote} onChange={_ => setPrivateNote(_.target.value)} rows={5} />
          </FormGroup>
          <FormGroup>
            <Label>流入経路</Label>
            <Select
              value={routeOptions.find(_ => _.value === initialRoute)}
              onChange={_ => setInitialRoute(_ && _.value)}
              options={routeOptions}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>Cancel</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

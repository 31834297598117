import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import TenantAdminPage from '../hocs/TenantAdminPage';
import { texts } from '../../config';
import TenantGeneralFormModal from '../modals/TenantGeneralFormModal';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default TenantAdminPage(class TenantAdminGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  onSubmitForm = async (values) => {
    const { match: { params: { slug } } } = this.props;
    try {
      await tenantsRef.doc(slug).update(values);
      toast.success('更新しました');
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    } finally {
      this.closeFormModal();
    }
  }
  render() {
    const { tenant, match: { params: { slug } } } = this.props;
    const { shouldShowFormModal } = this.state;
    const { lunchStart, lunchEnd, dinnerStart, dinnerEnd, lunchDuration, dinnerDuration, otherDuration, marginDuration, rbOtherServiceMailIntegrationEnabled = false, tableOnlyPrice, } = tenant;
    return (
      <div className="tenant-admin-general">
        <div className="d-flex justify-content-center mb-2">
          <h4>一般設定</h4>
        </div>
        <div className="d-flex justify-content-end">
          <Button color="primary" onClick={this.openFormModal}>
            <span className="fas fa-edit mr-1" />
            編集
          </Button>
        </div>
        <div className="mt-3">
          <table className="table">
            <tbody>
              <tr>
                <th>ランチ時間帯</th>
                <td>
                  {
                    lunchStart && lunchEnd ? (
                      <span>{formatDate(lunchStart.toDate(), 'HH:mm')} - {formatDate(lunchEnd.toDate(), 'HH:mm')}</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <th>ディナー時間帯</th>
                <td>
                  {
                    dinnerStart && dinnerEnd ? (
                      <span>{formatDate(dinnerStart.toDate(), 'HH:mm')} - {formatDate(dinnerEnd.toDate(), 'HH:mm')}</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <th>ランチ確保時間</th>
                <td>
                  {
                    lunchDuration ? (
                      <span>{lunchDuration}分</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <th>ディナー確保時間</th>
                <td>
                  {
                    dinnerDuration ? (
                      <span>{dinnerDuration}分</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <th>その他確保時間</th>
                <td>
                  {
                    otherDuration ? (
                      <span>{otherDuration}分</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <th>リセット時間</th>
                <td>
                  {
                    marginDuration ? (
                      <span>{marginDuration}分</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <th>レストランボード グルメサイトメール取り込みの設定</th>
                <td>
                  <span>{({ true: 'ON', false: 'OFF' })[rbOtherServiceMailIntegrationEnabled]}</span>
                </td>
              </tr>
              <tr>
                <th>席のみ料金</th>
                <td>
                  {
                    tableOnlyPrice ? (
                      <span>{numeral(tableOnlyPrice).format()}円</span>
                    ) : (
                      <span>(未設定)</span>
                    )
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <TenantGeneralFormModal slug={slug} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} tenant={tenant} />
      </div>
    );
  }
});

import React, { Component, Fragment } from 'react';
import { format as formatDate } from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, groupBy, sortBy, uniqBy, omit, debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { Badge, Button, Table, FormGroup, Label, Input } from 'reactstrap';
import { parse as parseDate, differenceInMinutes, startOfDay, endOfDay, addDays } from 'date-fns';
import moment from 'moment';
import qs from 'qs';

import ExportButton from '../ExportButton';
import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import TenantHeaderNav from '../TenantHeaderNav';
import ReservationListItem from '../ReservationListItem';
import ReservationsHeader from '../ReservationsHeader';
import { routes, statuses, reminderStatuses } from '../../config';
import { routeLabel, } from '../../shared/util';
import { initialRoute } from '../../utils';
import ReservationStatusBadge from '../ReservationStatusBadge';
import ReservationCancelProtectionBadge from '../ReservationCancelProtectionBadge';

import './TenantReservations.css';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const customRoutesRef = db.collection('customRoutes');
const { entries, keys } = Object;

export default TenantPage(class TenantReservations extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.fetchReservations();
    this.listenCustomRoutes();
    this.listenTables();
    this.listenCourses();
    this.setInitialDateRange();
  }
  queryParams = () => {
    const { location: { search } } = this.props;
    return qs.parse(search.slice(1));
  }
  setInitialDateRange() {
    const { history, location: { pathname } } = this.props;
    const { dateRangeStart, dateRangeEnd } = this.queryParams();
    if(!isEmpty(dateRangeStart) && !isEmpty(dateRangeEnd)) return;

    history.replace(`${pathname}?${qs.stringify({
      dateRangeStart: formatDate(addDays(new Date(), 0), 'YYYY-MM-DD'),
      dateRangeEnd: formatDate(addDays(new Date(), 30), 'YYYY-MM-DD'),
    })}`);
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location !== this.props.location) {
      this.fetchReservations();
    }
  }
  fetchReservations = debounce(async () => {
    const { match: { params: { slug } } } = this.props;
    const { dateRangeStart, dateRangeEnd } = this.queryParams();
    if(isEmpty(dateRangeStart) || isEmpty(dateRangeEnd)) return;

    const reservationsRef = tenantsRef
      .doc(slug)
      .collection('reservations')
      .orderBy('startAt')
      .where('startAt', '>=', startOfDay(parseDate(dateRangeStart)))
      .where('startAt', '<=', endOfDay(parseDate(dateRangeEnd)))
    const { docs } = await reservationsRef.get();
    this.setState({
      reservations: docs.map(_ => ({ ..._.data(), id: _.id })),
    });
  }, 500)
  listenCustomRoutes() {
    customRoutesRef
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customRoutes: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ tables: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y}), {}) });
      });
  }
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .onSnapshot((snapshot) => {
        this.setState({ courses: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  onClickCancelRemind = async (reservationId) => {
    if(!window.confirm('本当にリマインド停止しますか？')) return;
    const { tenant, match: { params: { slug } } } = this.props;
    const { reservations = [] } = this.state;
    const reservation = reservations.find(_ => _.id === reservationId);
    const versions = [...(reservation.versions || []), omit(reservation, 'versions')];
    await tenantsRef
      .doc(slug)
      .collection('reservations')
      .doc(reservationId)
      .update({ status: 'remindCancelled', versions });
    toast.success('リマインド停止しました');
    this.fetchReservations();
  }
  reservationsForExport = async () => {
    const { match: { params: { slug } } } = this.props;
    const { reservations, tables = {}, courses = {}, } = this.state;
    return reservations.map((reservation) => {
      const { id, receptedAt, status, versions, route, hpOtherLabel, name, nameKana, startAt, endAt, tableIds, courseId, peopleCount, note, cancelReason, } = reservation;
      const relatedTables = keys(tableIds || {}).map(_ => (tables || {})[_]).filter(_ => _);
      const course = courses[courseId] || {};
      return {
        '予約ID': id,
        '予約受付日時': formatDate(receptedAt.toDate(), 'YYYY/MM/DD HH:mm:ss'),
        '状態': ({
          customer: 'お客様都合キャンセル',
          tenant: 'お店都合キャンセル',
        })[cancelReason] || (statuses[status] || {}).name,
        'リマインド状態': (reminderStatuses[status] || {}).name || null,
        '流入経路': initialRoute(reservation),
        '看板名': hpOtherLabel && hpOtherLabel.nameForRB,
        '氏名': name,
        '氏名（かな）': nameKana,
        '予約開始日時': formatDate(startAt.toDate(), 'YYYY/MM/DD HH:mm:ss'),
        '予約終了日時': formatDate(endAt.toDate(), 'YYYY/MM/DD HH:mm:ss'),
        'テーブル名': relatedTables.map(_ => _.name).join(','),
        'コース名': course.name,
        'コース金額': course.price,
        '人数': peopleCount,
        'お客様要望欄': note,
      };
    });
  }
  onChangeDate = (paramName, moment) => {
    const { history, location: { pathname } } = this.props;

    history.replace(`${pathname}?${qs.stringify({
      ...this.queryParams(),
      [paramName]: formatDate(moment.toDate(), 'YYYY-MM-DD'),
    })}`);
  }
  render() {
    const { tenant, user, match: { params: { slug } }, location } = this.props;
    const { customRoutes = [], reservations = [], tables = {}, courses = {}, } = this.state;
    const { dateRangeStart, dateRangeEnd } = this.queryParams();
    const reservationsGroupedByDate = groupBy(reservations, _ => formatDate(_.startAt.toDate(), 'YYYYMMDD'));
  
    return (
      <div className="tenant-reservations">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
        <div className="container p-4 pt-5 mt-5">
          <h4 className="text-center mb-4">予約一覧</h4>
          <div className="d-flex mb-3 align-items-center justify-content-between">
            <div style={{ width: 140 }} className="ml-3 d-flex align-items-center">
              <DatePicker
                dateFormat="YYYY/MM/DD"
                selected={dateRangeStart && moment(dateRangeStart)}
                onChange={this.onChangeDate.bind(this, 'dateRangeStart')}
              />
              <span className="mx-2"> - </span>
              <DatePicker
                dateFormat="YYYY/MM/DD"
                selected={dateRangeEnd && moment(dateRangeEnd)}
                onChange={this.onChangeDate.bind(this, 'dateRangeEnd')}
              />
            </div>
          </div>
          <ReservationsHeader location={location} slug={slug} activeTab="list" />
          <div className="d-flex my-3 align-items-center justify-content-end">
            <ExportButton fileName="予約.csv" rows={this.reservationsForExport} />
          </div>
          <Table className="mt-4">
            <thead className="text-center">
              <tr>
                <th>状態</th>
                <th>
                  予約日時
                </th>
                <th>
                  名前
                </th>
                <th>
                  人数
                </th>
                <th>
                  テーブル
                </th>
                <th>
                  コース
                </th>
                <th>
                  お客様要望欄
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                entries(reservationsGroupedByDate).map(([date, reservations]) => {
                  const reservationsForCount = reservations.filter( (r) => !r.cancelReason)
                  const totalPeopleCount = reservationsForCount.map(_ => Number(_.peopleCount)).reduce((x, y) => x + y, 0);
                    
                  return (
                    <Fragment key={date}>
                      {
                        reservations.map((reservation) => {
                          const { id, name, nameKana, charge, phone, peopleCount, route, startAt, endAt, tableIds, courseId, cancelReason, versions = [], status, receptedAt, note, shouldConfirmEmail = false, } = reservation;
                          const relatedTables = keys(tableIds || {}).map(_ => (tables || {})[_]).filter(_ => _);
                          const course = (courses || {})[courseId] || {};
                          const willBeReminded = differenceInMinutes(startOfDay(startAt.toDate()), new Date()) > 11.5 * 60;
                          return (
                            <tr key={id}>
                              <td>
                                <div>
                                  <ReservationStatusBadge status={status} />
                                  <ReservationStatusBadge reminder status={status} />
                                  {
                                    shouldConfirmEmail && (
                                      <Badge pill color="danger" className="ml-1 text-nowrap">
                                        本人未確認
                                      </Badge>
                                    )
                                  }
                                  <ReservationCancelProtectionBadge reservation={reservation} className="ml-1 text-nowrap" />
                                  {
                                    status === 'initial' && tenant.smsEnabled && willBeReminded && (
                                      <Button className="mt-1" color="warning" size="sm" onClick={this.onClickCancelRemind.bind(this, id)}>
                                        リマインド停止
                                      </Button>
                                    )
                                  }
                                </div>
                                {
                                  cancelReason && (
                                    <div className="small text-danger text-nowrap">[取消済み]</div>
                                  )
                                }
                                {
                                  !cancelReason && tableIds === null && (
                                    <div className="small text-warning text-nowrap">
                                      <span className="mr-2 fas fa-exclamation-triangle" />
                                      ウェイティング
                                    </div>
                                  )
                                }
                              </td>
                              <td>
                                <div className="text-nowrap">{formatDate(startAt.toDate(), 'YYYY/MM/DD')}</div>
                                <div className="text-nowrap">{formatDate(startAt.toDate(), 'HH:mm')} - {formatDate(endAt.toDate(), 'HH:mm')}</div>
                              </td>
                              <td>
                                <div className="text-nowrap">{name}</div>
                                <div className="text-nowrap">({nameKana}) 様</div>
                                <div className="text-nowrap">{phone}</div>
                              </td>
                              <td>
                                {peopleCount}名
                              </td>
                              <td>
                              { 
                                relatedTables.length < 4 ? (
                                  relatedTables.map(({id, name}) => {
                                    return(
                                      <div key={id} className="text-truncate truncated-column-td" title={name}>{name}</div>
                                    );
                                  })
                                ) : (
                                  <Fragment>
                                    {
                                      relatedTables.slice(0, 2).map(({ id, name }) => (
                                        <div key={id} className="text-truncate truncated-column-td" title={name}>{name}</div>
                                      ))
                                    }
                                    <div>※配席卓数全{relatedTables.length}卓</div>
                                  </Fragment>
                                )
                              }
                              </td>
                              <td>
                                <div className="text-truncate truncated-column-td" title={course.name}>{course.name || '席のみ'}</div>
                              </td>
                              <td>
                                <div key={id} className="text-truncate truncated-column-td" title={note}>{note}</div>
                              </td>
                              <td>
                                <Link to={`/t/${slug}/reservations/${id}`} target="_blank">詳細</Link>
                                <div>{id}</div>
                                <div className="text-nowrap">{routeLabel(initialRoute(reservation), routes, customRoutes, tenant.routeAliases)}</div>
                              </td>
                            </tr>
                          );
                        })
                      }
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="summary-row-item-name">{formatDate(date, 'YYYY/MM/DD')} 計:</td>
                        <td className="summary-row-item-value">{totalPeopleCount}名</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </Fragment>
                  );
                })
              }
            </tbody>
          </Table>
          <hr />
          <div>
          </div>
        </div>
      </div>
    );
  }
});

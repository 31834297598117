import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { debounce } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';
import { isEmail } from 'validator';

import firebase from '../../firebase';
import { texts } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const defaultState = {
  uid: undefined,
  role: 'admin',
  validations: {},
  isSubmitting: false,
};
// TODO: config等に移動
const roles = ['admin', 'superStaff', 'staff'];
const { entries } = Object;

export default class UserSelectModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  setValues() {
    const { values } = this.props;
    this.setState({ ...values });
    entries(values || {}).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      default:
        this.setState({ validations: { ...validations, [propName]: !!value } });
    }
  }
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSelect = (name, { value } ) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    const { uid, role } = this.state;
    this.setState({ isSubmitting: true });
    onSubmit({ uid, role });
  }
  isUnsubmittable() {
    const { isValidating, isSubmitting, validations } = this.state;
    return isSubmitting || isValidating || !validations.uid || !validations.role;
  }
  render() {
    const { isOpen, onClickClose, users = [] } = this.props;
    const { uid, role } = this.state;
    const roleOptions = roles.map(_ => ({ label: texts[_], value: _ }));
    const userOptions = users.map(_ => ({ label: `${_.name}(${_.email})`, value: _.uid }));
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          既存ユーザーから選択
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>ユーザー</Label>
              <Select
                value={userOptions.find(_ => _.value === uid)}
                onChange={this.onSelect.bind(this, 'uid')}
                options={userOptions}
                isClearable={false}
              />
            </FormGroup>
            <FormGroup>
              <Label>権限</Label>
              <Select
                value={roleOptions.find(_ => _.value === role)}
                onChange={this.onSelect.bind(this, 'role')}
                options={roleOptions}
                isClearable={false}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};


import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { debounce, omitBy, pick } from 'lodash';
import classnames from 'classnames';

import firebase from '../../firebase';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { entries } = Object;

const defaultState = {
  name: '',
  servicesIntegrationEnabled: false,
  aiPhoneEnabled: false,
  cancelProtectionEnabled: false,
  smsEnabled: false,
  aossmsClientId: '',
  aossmsCode: '',
  validations: {},
};

export default class TenantAdminSettingsFormModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  setValues() {
    const { values } = this.props;
    this.setState({ ...omitBy(values, _ => _ === undefined) });
    entries(values || {}).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      case 'name': 
        this.setState({ validations: { ...validations, name: !!value } });
        break;
      case 'servicesIntegrationEnabled': 
      case 'aiPhoneEnabled': 
      case 'cancelProtectionEnabled': 
      case 'smsEnabled': 
        this.setState({ validations: { ...validations, [propName]: true } });
        break;
      case 'aossmsClientId':
      case 'aossmsCode':
        this.setState({ validations: { ...validations, [propName]: this.state.smsEnabled ? !!value : true } });
        break;
      default:
    }
  }
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    onSubmit({ ...pick(this.state, ['name', 'servicesIntegrationEnabled', 'aiPhoneEnabled', 'cancelProtectionEnabled', 'smsEnabled', 'aossmsClientId', 'aossmsCode']), });
  }
  onCheck = (name, { target: { checked: value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['name', 'servicesIntegrationEnabled', 'aiPhoneEnabled', 'cancelProtectionEnabled', 'smsEnabled', 'aossmsClientId', 'aossmsCode'].some(_ => !validations[_]);
  }
  render() {
    const { isOpen, onClickClose } = this.props;
    const { name, servicesIntegrationEnabled, aiPhoneEnabled, cancelProtectionEnabled, smsEnabled, aossmsClientId, aossmsCode } = this.state;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          テナント設定
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>テナント名</Label>
              <Input value={name} onChange={this.onChange.bind(this, 'name')} className={this.validationCss('name')}/>
            </FormGroup>
            <FormGroup>
              <Label>グルメサイト連携</Label>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={servicesIntegrationEnabled} onChange={this.onCheck.bind(this, 'servicesIntegrationEnabled')} className={this.validationCss('servicesIntegrationEnabled')} />
                  ON
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>AIロボ</Label>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={aiPhoneEnabled} onChange={this.onCheck.bind(this, 'aiPhoneEnabled')} className={this.validationCss('aiPhoneEnabled')} />
                  ON
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>キャンセル・プロテクション機能</Label>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={cancelProtectionEnabled} onChange={this.onCheck.bind(this, 'cancelProtectionEnabled')} className={this.validationCss('cancelProtectionEnabled')} />
                  ON
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>SMSリマインダー機能</Label>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={smsEnabled} onChange={this.onCheck.bind(this, 'smsEnabled')} className={this.validationCss('smsEnabled')} />
                  ON
                </Label>
              </FormGroup>
            </FormGroup>
            {
              smsEnabled && (
                <Fragment>
                  <FormGroup>
                    <Label>AOSSMS Client ID</Label>
                    <Input value={aossmsClientId} onChange={this.onChange.bind(this, 'aossmsClientId')} className={this.validationCss('aossmsClientId')}/>
                  </FormGroup>
                  <FormGroup>
                    <Label>AOSSMS SMS Code</Label>
                    <Input value={aossmsCode} onChange={this.onChange.bind(this, 'aossmsCode')} className={this.validationCss('aossmsCode')}/>
                  </FormGroup>
                </Fragment>
              )
            }
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};


import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { generate as generatePassword } from 'generate-password';

import firebase, { config } from '../../firebase';
import UserFormModal from '../modals/UserFormModal';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { apiKey } = config;

export default function UserCrudableHOC(WrappedComponent) {
  return class UserCrudable extends WrappedComponent {
    constructor() {
      super();
      this.state = {
        ...this.state,
        shouldShowFormModal: false,
      };
    }
    componentDidMount() {
      super.componentDidMount && super.componentDidMount();
      this.listenUsers();
    }
    listenUsers() {
      const { match: { params: { slug } } } = this.props;
      tenantsRef
        .doc(slug)
        .collection('users')
        .onSnapshot((snapshot) => {
          this.setState({ users: snapshot.docs.map(_ => _.data()) });
        });
    }
    openFormModal = () => this.setState({ shouldShowFormModal: true })
    closeFormModal = () => this.setState({ shouldShowFormModal: false })
    onSubmitForm = async ({ uid, name, email, role }) => {
      const { match: { params: { slug } } } = this.props;
      const isNew = uid == null;
      (isNew ? this.createUser(email).then(_ => _.json()).then(_ => _.localId) : Promise.resolve(uid))
        .then((uid) => {
          tenantsRef
            .doc(slug)
            .collection('users')
            .doc(uid)
            [isNew ? 'set' : 'update']({ name, email, uid, role })
            .then(() => {
              this.closeFormModal();
              toast.success(`${isNew ? '追加' : '更新'}しました`);
            });
        })
        .catch(({ message }) => {
          toast.error('失敗しました');
          this.closeFormModal();
          window.alert(message);
        });;
    }
    createUser(email) {
      // NOTE: REST APIで作成する。sdkのcreateUserWithEmailAndPasswordを利用すると、作成と同時にそのユーザーでsign inしてしまう。
      const url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${apiKey}`;
      const body = JSON.stringify({
        email,
        password: generatePassword({ length: 10, numbers: true }),
      });
      const headers = { 'Content-Type': 'application/json' };
      return fetch(url, { method: 'POST', headers, body });
    }
    delete = (uid) => {
      const { match: { params: { slug } } } = this.props;
      if (!window.confirm('本当に削除しますか？')) return;
      tenantsRef
        .doc(slug)
        .collection('users')
        .doc(uid)
        .delete()
        .then(toast.success.bind(this, '削除しました'));
    }
    invite = (email, role) => {
      const { match: { params: { slug } } } = this.props;
      if (!window.confirm('本当に招待しますか？')) return;
      auth.sendPasswordResetEmail(email, { url: `${window.location.origin}/t/${slug}${role === 'admin' ? '/admin' : ''}` })
        .then(toast.success.bind(this, '招待しました'));
    }
    onClickAdd = () => {
      this.setState({ targetUserValues: null });
      this.openFormModal();
    }
    onClickEdit = (values) => {
      this.setState({ targetUserValues: values });
      this.openFormModal();
    }
    render() {
      const { match: { params: { slug } } } = this.props;
      const { shouldShowFormModal, targetUserValues } = this.state;
      return (
        <Fragment>
          {super.render()}
          <UserFormModal values={targetUserValues} slug={slug} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} />
        </Fragment>
      );
    }
  };
};

const statuses = {
  initial: { label: '未処理', color: '' },
  failed: { label: '失敗', color: 'danger' },
  completed: { label: '処理済', color: 'success' },
  finished: { label: '', color: '' },
};

module.exports = {
  statuses,
};

import React, { Component, Fragment } from 'react';
import { Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { functions } from '../../firebase';
import { cancelFeeFields } from '../../shared/models/reservation';
import MessageForm from '../hocs/MessageForm';
import ModelFormModal from '../modals/ModelFormModal';

const { keys } = Object;
const cancelReservation = functions.httpsCallable('cancelReservation');

export default MessageForm(class OverStarts extends Component {
  onClickCame = (reservationId) => {
    if(!window.confirm('本当に来店済みにしますか？')) return;
    const { onAction } = this.props;
    onAction({ type: 'updateStatus', payload: { status: 'came', reservationId, nextType: 'viewOverStarts' } });
  }
  onClickCancel = (reservationId) => {
    if(!window.confirm('本当にキャンセルにしますか？')) return;
    const { onAction } = this.props;
    onAction({ type: 'updateStatus', payload: { status: 'cancel', reservationId, values: { cancelReason: 'customer' }, nextType: 'viewOverStarts' } });
  }
  render() {
    const { slug, onAction, data: { overStarts, tables, courses }, isActive } = this.props;
    return (
      <div className="over-starts">
        {
          overStarts.length > 0 ? (
            <Table>
              <tbody>
                {
                  overStarts.map((reservation) => {
                    const { id, startAt, endAt, peopleCount, nameKana, tableIds } = reservation;
                    const onCancelled = () => {
                      onAction({ type: 'cancelled', payload: { reservationId: id, nextType: 'viewOverStarts' } });
                    };

                    return (
                      <tr key={id}>
                        <td>
                          <div className="small">
                            <span className="text-muted">[{id}]</span>
                          </div>
                          <div>
                            {formatDate(startAt.toDate(), 'MM/DD HH:mm')} - {formatDate(endAt.toDate(), 'HH:mm')} {keys(tableIds || {}).map(id => tables.find(_ => _.id === id).name).join(', ')}
                            <br />
                            {nameKana} {peopleCount}名様
                          </div>
                          <div className="mt-1 d-flex justify-content-around">
                            <Button color="success" size="sm" onClick={this.onClickCame.bind(this, id)} disabled={!isActive}>
                              来店済み
                            </Button>
                            <Button color="danger" size="sm" onClick={this.onClickCancel.bind(this, id)} disabled={!isActive}>
                              キャンセル
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          ) : (
            <div>来店時刻到来済み予約はありません</div>
          )
        }
        <div className="mt-2">
          <Button color="primary" onClick={() => onAction({ type: 'toHome' })} disabled={!isActive}>
            終了
          </Button>
        </div>
      </div>
    );
  }
});


function CancelButton (props) {
  const { tenantSlug, reservation, courses, ...extraProps } = props;
  const course = courses.find(_ => _.id === reservation.courseId);
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    //    const { onAction } = this.props;
    //    onAction({ type: 'updateStatus', payload: { status: 'cancel', reservationId: reservation.id, values: { cancelReason: 'customer' }, nextType: 'viewOverStarts' } });

    if(!window.confirm('本当にキャンセルにしますか？')) return;

    try {
      await cancelReservation({
        tenantSlug,
        reservationId: reservation.id,
        cancelReason: 'customer',
        ...values,
      });
      toggleModal(false);
      props.onFinished();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  }
  return (
    <Fragment>
      <Button color="danger" size="sm" onClick={toggleModal.bind(null, true)} {...extraProps}>
        キャンセル
      </Button>
      {showsModal && <ModelFormModal isOpen title="キャンセル料" submitButtonLabel="予約をキャンセルする" fields={cancelFeeFields({ price: get(course, 'price', 0), peopleCount: reservation.peopleCount, })} onSubmit={onSubmit} onClickClose={toggleModal.bind(null, false)} />}
    </Fragment>
  );
}

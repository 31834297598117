import React, { Fragment } from 'react';
import { format as formatDate } from 'date-fns';
import { Card, CardBody, div, Badge } from 'reactstrap';
import numeral from 'numeral';

import { routes, types, cancelReasons } from '../config';
import { routeLabel, } from '../shared/util';
import ReservationStatusBadge from './ReservationStatusBadge';
import ReservationCancelProtectionBadge from './ReservationCancelProtectionBadge';

const { keys } = Object;

export default function ReservationCard({ id, className, customRoutes = [], tables, courses, tags, reservation, reservation: { customer, type, status, route, startAt, endAt, peopleCount, tableIds, phone, name, nameKana, courseId, tagIds, note, cancelReason, receptedAt, receptedBy = {}, versions = [], main: isMain = false, shouldConfirmEmail = false, cancelCharge, }, dateParser = _ => _.toDate(), forCustomer = false, tenant, isInitial = false, }) {
  const relatedTables = keys(tableIds || {}).map(_ => (tables || {})[_]).filter(_ => _);
  const course = (courses || {})[courseId] || {};
  const relatedTags = keys(tagIds || {}).map(_ => (tags || {})[_]).filter(_ => _);
  return (
    <Card className={className}>
      <CardBody>
        {
          forCustomer && (
            <div className="text-center mb-4 text-secondary">ご予約内容</div>
          )
        }
        {
          !forCustomer && (
            <div className="mb-3">
              <ReservationStatusBadge status={status} />
              <ReservationStatusBadge className="ml-1" reminder status={status} />
              {
                shouldConfirmEmail && (
                  <Badge pill color="danger" className="ml-1 text-nowrap">
                    本人未確認
                  </Badge>
                )
              }
              <ReservationCancelProtectionBadge reservation={reservation} className="ml-1 text-nowrap" />
            </div>
          )
        }
        <div className="mb-3">
          <div className="text-muted small">予約日時</div>
          {formatDate(dateParser(startAt), 'YYYY/MM/DD HH:mm')} - {formatDate(dateParser(endAt), 'HH:mm')}
        </div>
        <div className="mb-3">
          <div className="text-muted small">名前</div>
          {name}({nameKana}) 様
        </div>
        <div className="mb-3">
          <div className="text-muted small">電話番号</div>
          {phone}
        </div>
        <div className="mb-3">
          <div className="text-muted small">人数</div>
          {peopleCount}名
        </div>
        <div className="mb-3">
          <div className="text-muted small">コース</div>
          {course.name || '席のみ'}
        </div>
        {
          !forCustomer && (
            <Fragment>
              <div className="mb-3">
                <div className="text-muted small">テーブル</div>
                <div>
                  {
                    relatedTables.map(({ id, name }) => {
                      return (
                        <div key={id}>{name}</div>
                      );
                    })
                  }
                </div>
              </div>
              <div className="mb-3">
                <div className="text-muted small">予約タグ</div>
                {relatedTags.map(_ => _.name).join(', ') || 'タグなし'}
              </div>
              <div className="mb-3">
                <div className="text-muted small">取消理由</div>
                {cancelReasons[cancelReason]}
              </div>
              {
                cancelCharge != null && (
                  <div className="mb-3">
                    <div className="text-muted small">徴収したキャンセル料</div>
                    {numeral(cancelCharge.amount).format()}円
                  </div>
                )
              }
              <div className="mb-3">
                <div className="text-muted small">お客様要望欄</div>
                <div className="pre-line">
                  {note}
                </div>
              </div>
              <div className="mb-3" className="text-right small text-muted">
                <span className="small">
                  <Badge color={(types[type] || {}).color}>
                    {(types[type] || {}).name}
                  </Badge>
                  {
                    isInitial && (
                      <span className="ml-2">{routeLabel(route, routes, customRoutes, tenant.routeAliases)}</span>
                    )
                  }
                  <span className="ml-2">{id}</span>
                  <span className="ml-2">{receptedBy.name}</span>
                  <span className="ml-2">{formatDate(dateParser(receptedAt), 'YYYY/MM/DD HH:mm')}</span>
                </span>
              </div>
            </Fragment>
          )
        }
      </CardBody>
    </Card>
  );
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, uniqBy, omit, debounce, sumBy, sum } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Button, Tab, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import qs from 'qs';
import { format as formatDate, parse as parseDate, startOfMonth, endOfMonth, addMonths, getDaysInMonth, addDays } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import TenantHeaderNav from '../TenantHeaderNav';
import AppPieChart from './TenantStats/AppPieChart';
import AppLineChart from './TenantStats/AppLineChart';
import { routes, summaryRoutes } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { values, entries } = Object;

export default TenantPage(class TenantStats extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: 'initialRoute',
      isFetching: false,
    };
  }
  componentDidMount() {
    this.listenReservationStats();
    this.listenRevokeStats();
    this.listenCourses();
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location.search !== this.props.location.search || prevState.activeTab !== this.state.activeTab) {
      this.setState({ isFetching: true });
      this.unlistenReservationStats && this.unlistenReservationStats();
      this.unlistenRevokeStats && this.unlistenRevokeStats();
      this.listenReservationStats();
      this.listenRevokeStats();
    }
  }
  listenCourses() {
    const { match: { params : { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ courses: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenReservationStats() {
    const { match: { params : { slug } } } = this.props;
    const currentDate = this.currentDate();
    this.unlistenReservationStats = tenantsRef
      .doc(slug)
      .collection('stats')
      .doc('reservation')
      .collection('startDates')
      .where('date', '>=', currentDate)
      .where('date', '<=', endOfMonth(currentDate))
      .onSnapshot((snap) => {
        this.setState({ isFetching: false, reservationStats: (snap.docs || []).map(_ => _.data()) });
      });
  }
  listenRevokeStats() {
    const { match: { params : { slug } } } = this.props;
    const currentDate = this.currentDate();
    this.unlistenReservationStats = tenantsRef
      .doc(slug)
      .collection('stats')
      .doc('revoke')
      .collection('receptedDates')
      .where('date', '>=', currentDate)
      .where('date', '<=', endOfMonth(currentDate))
      .onSnapshot((snap) => {
        this.setState({ isFetching: false, revokeStats: (snap.docs || []).map(_ => _.data()) });
      });
  }
  currentDate() {
    const { location: { search } } = this.props;
    const { d } = qs.parse(search.slice(1));
    return d ? parseDate(d) : startOfMonth(new Date());
  }
  linkTo = (value) => {
    const { location, history } = this.props;
    const params = qs.parse(location.search.slice(1));
    return `${location.pathname}?${qs.stringify({ ...params, d: formatDate(addMonths(this.currentDate(), value), 'YYYY/MM/DD') })}`;
  }
  reservationRoutesPieData = () => {
    const { reservationStats } = this.state;
    return sortBy(values(summaryRoutes).map(({ name, routes }) => {
      const value = sumBy(reservationStats || [], _ =>
        sum(entries((_.reservationCount || {}).routes || {}).filter(([k, v]) => routes.includes(k)).map(_ => _[1])));
      return { name, value };
    }), _ => _.value).reverse();
  }
  reservationRoutesLineData = () => {
    const statsByDate = this.statsByDate();
    return this.dates().map((date) => {
      const dateData = statsByDate[formatDate(date, 'YYYYMMDD')] || {};
      return {
        name: formatDate(date, 'YYYY/MM/DD'),
        ...values(summaryRoutes)
          .map(({ name, routes }) => ({ name, value: sum(routes.map(_ => ((dateData.reservationCount || {}).routes || {})[_] || 0)) }))
          .reduce((x, y) => ({ ...x, [y.name]: y.value }), {})
      };
    });
  }
  revokePieData = () => {
    const { revokeStats } = this.state;
    return sortBy(values(summaryRoutes).map(({ name, routes }) => {
      const value = sumBy(revokeStats || [], _ =>
        sum(entries(_.routes || {}).filter(([k, v]) => routes.includes(k)).map(_ => _[1])));
      return { name, value };
    }), _ => _.value).reverse();
  }
  statsByDate() {
    const { reservationStats } = this.state;
    return (reservationStats || []).reduce((x, y) => ({ ...x, [formatDate(y.date.toDate(), 'YYYYMMDD')]: y }), {});
  }
  dates() {
    const currentDate = this.currentDate();
    return Array(getDaysInMonth(currentDate)).fill().map((_, i) => addDays(currentDate, i));
  }
  selectTab = activeTab => this.setState({ activeTab })
  courseStatTable() {
    const { reservationStats, courses } = this.state;
    const coursesStatData =  sortBy([{ id: null, name: '席のみ' }, ...(courses || [])].map(({ id, name, price = 0 }) => {
      const reservationCount = sumBy(reservationStats || [], _ => ((_.reservationCount || {}).courses || {})[id] || 0);
      const peopleCount = sumBy(reservationStats || [], _ => ((_.peopleCount || {}).courses || {})[id] || 0);
      return { name, reservationCount, peopleCount, price };
    }), _ => _.peopleCount * _.price).reverse();
    return (
      <table className="table">
        <thead>
          <tr>
            <th>コース名</th>
            <th>単価</th>
            <th>件数</th>
            <th>人数</th>
            <th>合計金額</th>
          </tr>
        </thead>
        <tbody>
          {
            coursesStatData.map(({ name, reservationCount, peopleCount, price = 0 }, i) => {
              return (
                <tr key={i}>
                  <td>{name}</td>
                  <td className="text-right">{numeral(price).format('0,0')}</td>
                  <td className="text-right">{numeral(reservationCount).format('0,0')}</td>
                  <td className="text-right">{numeral(peopleCount).format('0,0')}</td>
                  <td className="text-right">{numeral(price * peopleCount).format('0,0')}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }
  render() {
    const { tenant, user, match: { params: { slug } } } = this.props;
    const { activeTab, isFetching } = this.state;
    return (
      <div className="tenant-stats">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
        <div className="container p-4 pt-5 mt-5">
          <div className="d-flex justify-content-between align-items-center">
            <Button tag={Link} to={this.linkTo(-1)} outline>
              <span className="fas fa-angle-left" />
            </Button>
            <div>
              {
                formatDate(this.currentDate(), 'YYYY/MM')
              }
            </div>
            <Button tag={Link} to={this.linkTo(1)} outline>
              <span className="fas fa-angle-right" />
            </Button>
          </div>
          <div className="mt-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'initialRoute' })}
                  onClick={this.selectTab.bind(this, 'initialRoute')}
                >
                  経路別
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'course' })}
                  onClick={this.selectTab.bind(this, 'course')}
                >
                  コース別
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pt-4">
              <TabPane tabId="initialRoute">
                {
                  !isFetching && (
                    <div>
                      <h4>予約数</h4>
                      <div className="mb-4">
                        <AppLineChart data={this.reservationRoutesLineData()} />
                      </div>
                      <div className="mb-4">
                        <AppPieChart data={this.reservationRoutesPieData()} />
                      </div>
                      <h4 className="mt-5">キャンセル数</h4>
                      <AppPieChart data={this.revokePieData()} />
                    </div>
                  )
                }
              </TabPane>
              <TabPane tabId="course">
                {
                  !isFetching && this.courseStatTable()
                }
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
});

import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { format as formatDate, parse as parseDate, startOfDay, } from 'date-fns';
import { Link } from 'react-router-dom';
import { useToggle, useAsync } from 'react-use';

import firebase, { functions } from '../../firebase';
import PublicPage from '../hocs/PublicPage';
import PublicTenantBlock from '../PublicTenantBlock';
import ReservationForm from '../forms/ReservationForm';

const getPublicTenant = functions.httpsCallable('getPublicTenant');
const confirmReservation = functions.httpsCallable('confirmReservation');

export default PublicPage(function PublicReservationConfirmation(props) {
  const { match: { params: { slug: tenantId, reservationId, } } } = props;
  const { value: tenant } = useAsync(async () => {
    const { data: tenant } = await getPublicTenant({ tenantId });
    return tenant;
  }, [tenantId]);
  const { value: isConfirmed } = useAsync(async () => {
    await confirmReservation({ tenantId, reservationId, });
    return true;
  }, [tenantId, reservationId]);

  return tenant != null && (
    <div className="container py-3">
      <PublicTenantBlock tenant={tenant} />
      <div className="mt-4 row">
        <div className="col-md-6 offset-md-3">
          <div className="card p-3">
            <div>
              {
                isConfirmed ? (
                  <div>
                    <p>
                      ありがとうございます。
                      <br />
                      予約申し込みの確認が完了しました。
                    </p>

                    <Link to={`/p/reservation?tenantSlug=${tenantId}&reservationId=${reservationId}`}>
                      <span className="fas fa-angle-double-right mr-1" />
                      予約内容の確認はこちら
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="p-3">
                      <span className="fas fa-spinner fa-spin mr-1" />
                      確認中...
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});



import React, { Fragment } from 'react';
import classnames from 'classnames';
import { isMobilePhone } from 'validator';
import { omit } from 'lodash';
import { isHiragana } from 'wanakana';

import firebase from '../../firebase';

const { entries } = Object;

export default function MessageFormHOC(WrappedComponent) {
  return class MessageForm extends WrappedComponent {
    constructor() {
      super();
      this.state = {
        ...this.state,
        validations: {},
      };
    }
    componentWillMount() {
      super.componentWillMount && super.componentWillMount();
      const { payload } = this.props;
      const { isEditing, validateOnMount } = payload || {};
      this.setState(payload);
      if(isEditing || validateOnMount) {
        entries(payload).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
      }
    }
    validate = (propName, value) => {
      const { validations } = this.state;
      switch (propName) {
        case 'route':
        case 'startAt':
        case 'endAt':
        case 'cancelReason':
          this.setState({ validations: { ...validations, [propName]: !!value } });
          break;
        case 'peopleCount':
          this.setState({ validations: { ...validations, peopleCount: value > 0 } });
          break;
        case 'phone':
          this.setState({ validations: { ...validations, [propName]: !!value } });
          break;
        case 'nameKana':
          this.setState({ validations: { ...validations, [propName]: (value || '').length > 0 && isHiragana(value) } });
          break;
        case 'courseId':
          this.setState({ validations: { ...validations, [propName]: value !== undefined } });
          break;
        default:
          this.setState({ validations: { ...validations, [propName]: true } });
      }
    }
    validationCss(name) {
      const { validations: { [name]: isValid } } = this.state;
      return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
    }
    onChangeDate = (name, value) => {
      this.validate(name, value.toDate());
      this.setState({ [name]: value.toDate() });
    }
    onChangeNumber = (name, { target: { value: _value } }) => {
      const value = parseInt(_value, 10) || null;
      this.validate(name, value);
      this.setState({ [name]: value });
    }
    onChangeText = (name, { target: { value } }) => {
      this.validate(name, value);
      this.setState({ [name]: value });
    }
    onChangeSelect = (name, { value }) => {
      this.validate(name, value);
      this.setState({ [name]: value });
    }
    onCheck = (name, id, { target: { checked } }) => {
      const { [name]: values } = this.state;
      this.setState({ [name]: checked ? { ...values, [id]: true } : omit(values, id) });
    }
    onChoose = (name, id, { target: { checked } }) => {
      const { [name]: value } = this.state;
      this.setState({ [name]: checked && id});
    }
  };
};

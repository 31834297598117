import React, { useState, } from 'react';
import { TextEncoder, TextDecoder } from 'text-encoding';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { pick, omit, keyBy, chunk } from 'lodash';
import classnames from 'classnames';
import { Button, Input, } from 'reactstrap';
import { toast } from 'react-toastify';

import firebase from '../firebase';
import { readFile } from '../utils';

const db = firebase.firestore();

export default function ImportButton ({ label = 'インポート', processRow, processRows = _ => _, beforeSave = _ => _, ...extraProps }) {
  const [isImporting, setIsImporting] = useState(false);

  const onSelectFiles = async ({ target, target: { files: [file] } }) => {
    if(!file) return;
    setIsImporting(true);
    try {
      const decoder = new TextDecoder('Shift_JIS');
      const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
      const { data: rows } = parseCsv(fileContent, { header: true });
      const processedRows = await processRows(rows);
      await beforeSave(processedRows);
      await chunk(rows, 500).reduce(async (x, rows) => {
        await x;
        const batch = db.batch();
        rows.forEach(row => processRow(batch, row, rows.indexOf(row)));
        await batch.commit();
      }, Promise.resolve());
      toast.success('インポートしました');
    } catch(e) {
      console.error(e);
      toast.error('インポート失敗しました');
    }
    setIsImporting(false);
    target.value = '';
  };

  return (
    <Button color="secondary" disabled={isImporting} {...extraProps}>
      <label className="m-0 cursor-pointer">
        <span className={classnames('fas mr-1', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting})} />
        {label}
        <Input type="file" className="d-none" onChange={onSelectFiles} accept="text/*" />
      </label>
    </Button>
  );
};

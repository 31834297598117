const { range, isEmpty, } = require('lodash');
const numeral = require('numeral');
const moment = require('moment');
const { isHiragana } = require('wanakana');
const { isMultibyte, isEmail, } = require('validator');
const { addDays, format: formatDate, addHours, startOfDay, endOfDay, } = require('date-fns');

const candidateFields = () => {
  const base = startOfDay(new Date());
  return {
    peopleCount: {
      label: '人数',
      type: 'select',
      options: range(1, 201).map(_ => ({ label: `${numeral(_).format('0,0')}人`, value: _ })),
    },
    date: {
      label: '日付',
      type: 'date',
      inputProps: {
        minDate: moment(addDays(new Date(), 1)),
      },
    },
    time: {
      label: '開始時刻',
      type: 'time',
    },
  };
};

const reservationFields = ({ courses = [] } = {}) => {
  return {
    courseId: {
      label: 'コース',
      type: 'select',
      options: [{ label: '席のみ', value: null }, ...courses.map(_ => ({ label: _.name, value: _.id }))],
      initialValue: null,
    },
    name: {
      label: 'お名前',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    nameKana: {
      type: 'string',
      label: 'お名前ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
    },
    phone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
      },
      placeholder: '08012345678',
    },
    email: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    note: {
      type: 'text',
      label: '連絡事項',
    },
  };
};

module.exports = {
  fields: (...args) => {
    return {
      ...candidateFields(...args),
      ...reservationFields(...args),
    };
  },
  candidateFields,
  reservationFields,
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import TenantPage from './TenantPage';
import HeaderNav from '../HeaderNav';
import BreadNav from '../BreadNav';
import SideNav from '../SideNav';
import firebase from '../../firebase';

import TenantHeaderNav from '../TenantHeaderNav';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default function TenantAdminPageHOC(WrappedComponent) {
  return TenantPage(class TenantAdminPage extends Component {
    componentWillMount() {
      this.checkAdmin();
    }
    checkAdmin() {
      const { user, match: { path, params: { slug } }, history } = this.props;
      if(user.role !== 'admin') {
        toast.error('Not Authorized.');
        history.push(`/t/${slug}`);
      }
    }
    signOut = () => {
      auth.signOut();
      window.location.reload();
    }
    render() {
      const { user, match: { path, params: { slug} }, tenant } = this.props;
      return (user.role === 'admin' ? (
        <div className="tenant-admin-page"> <TenantHeaderNav slug={slug} user={user} tenant={tenant} /> <div className="container p-4 pt-5 mt-5">
            {
              path !== '/t/:slug/admin' && (
                <div className="mb-2">
                  <Link to={`/t/${slug}/admin`}>
                    <span className="fas fa-angle-left mr-1" />
                    管理画面TOP
                  </Link>
                </div>
              )
            }
            <WrappedComponent {...this.props} />
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          Not Authorized
          <span className="btn btn-link" onClick={this.signOut}>
            ログアウト
          </span>
        </div>
      ));
    }
  });
};

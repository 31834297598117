import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { parse as parseDate, format as formatDate } from 'date-fns';
import { pick, mapValues, omitBy, uniqBy, keyBy, } from 'lodash';
import numeral from 'numeral';

import firebase from '../../firebase';
import { services } from '../../config';
import AdminPage from '../hocs/AdminPage';
import UserCrudable from '../hocs/UserCrudable';
import UserSelectModal from '../modals/UserSelectModal';
import TenantAdminSettingsFormModal from '../modals/TenantAdminSettingsFormModal';
import ImportButton from '../ImportButton';
import { texts } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default AdminPage(UserCrudable(class AdminTenant extends Component {
  componentDidMount() {
    this.listenTenant();
    this.listenTables();
    this.listenCourses();
    this.listenFailedReceptions();
    this.listenAllUsers();
  }
  listenTenant() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .onSnapshot((snapshot) => {
        this.setState({ tenant: snapshot.data() });
      });
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .onSnapshot(({ docs }) => {
        const tables = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ tables, tablesByName: keyBy(tables, 'name') });
      });
  }
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .onSnapshot(({ docs }) => {
        const courses = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ courses, coursesByName: keyBy(courses, 'name') });
      });
  }
  listenFailedReceptions() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('receptions')
      .where('failedAt', '>=', new Date(1900, 0, 1))
      .onSnapshot(({ docs }) => {
        this.setState({ failedReceptions: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenAllUsers() {
    const { match: { params: { slug } } } = this.props;
    db.collectionGroup('users')
      .onSnapshot(({ docs }) => {
        this.setState({ allUsers: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  onClickAddFromExists = () => {
    this.openUserSelectModal();
  }
  openUserSelectModal = () => this.setState({ shouldShowUserSelectModal: true })
  closeUserSelectModal = () => this.setState({ shouldShowUserSelectModal: false })
  onSubmitUserSelect = async ({ uid, role }) => {
    const { match: { params: { slug } } } = this.props;
    const { allUsers = [] } = this.state;
    const { name, email } = allUsers.find(_ => _.id === uid);
    try {
      await tenantsRef
        .doc(slug)
        .collection('users')
        .doc(uid)
        .set({ name, email, uid, role });
      this.closeUserSelectModal();
      toast.success('追加しました');
    } catch(e) {
      toast.error('失敗しました');
    }
  }
  onSubmitTenantAdminSettingsForm = async (values) => {
    const { match: { params: { slug } } } = this.props;
    try {
      await tenantsRef
        .doc(slug)
        .update(values);
      this.closeModal('TenantAdminSettingsFormModal');
      toast.success('更新しました');
    } catch(e) {
      toast.error('失敗しました');
    }
  }
  onClickSetting = () => this.openModal('TenantAdminSettingsFormModal')
  openModal = _ => this.setState({ [`shouldShow${_}`]: true })
  closeModal = _ => this.setState({ [`shouldShow${_}`]: false })
  processRow = (batch, row, index) => {
    const { match: { params: { slug } } } = this.props;
    const { tablesByName = {}, coursesByName = {}, } = this.state;
    const { tableName, courseName, endAt, peopleCount: _peopleCount, name, nameKana, startAt, note, route, phone, } = row;
    const tableNames = tableName.split(',');
    const targetTables = tableNames.map(_ => tablesByName[_]);
    const tableIds = targetTables.reduce((x, y) => ({ ...x, [y.id]: true }), {});
    const { id: courseId = null } = coursesByName[courseName] || {};
    const now = new Date();
    const capacity = targetTables.reduce((x, y) => x + (y.capacity || 0), 0);
    const peopleCount = parseInt(_peopleCount, 10);
    const id = formatDate(now, 'YYMMDD') + numeral(index + 1).format('000');
    batch.set(tenantsRef.doc(slug).collection('reservations').doc(id), {
      ...pick(row, ['name', 'nameKana', 'note', 'phone', 'route']),
      cancelReason: null,
      capacity,
      courseId,
      createdAt: now,
      endAt: parseDate(endAt),
      isOverflowing: capacity < peopleCount,
      notSyncs: mapValues(services, _ => true),
      peopleCount,
      receptedAt: now,
      receptedBy: { name: 'インポート', uid: 'import' },
      startAt: parseDate(startAt),
      status: 'initial',
      tableIds,
      tagIds: {},
      type: 'initial',
    }, _ => _ === undefined);
  }
  render() {
    const { match: { params: { slug } } } = this.props;
    const { tenant, users = [], allUsers = [], failedReceptions = [], shouldShowUserSelectModal = false, shouldShowTenantAdminSettingsFormModal = false } = this.state;
    const { name } = tenant || {};
    const memberIds = users.map(_ => _.uid);
    return (
      <div className="admin-tenants">
        <div className="container p-5">
          <div>
            <h4 className="text-center">{name}</h4>
            <div className="d-flex justify-content-end">
              <ImportButton label="予約インポート" processRow={this.processRow} color="secondary" />
              <Button className="ml-2" color="primary" onClick={this.onClickSetting}>
                <span className="fas fa-cog mr-1" />
                設定
              </Button>
            </div>
          </div>
          <hr className="my-5 py-4" />
          <div className="mt-5">
            <div className="d-flex justify-content-center">
              <h5>ユーザー一覧</h5>
            </div>
            <div className="d-flex justify-content-end">
              <Button color="primary" onClick={this.onClickAdd}>
                <span className="fas fa-plus mr-1" />
                新規ユーザー
              </Button>
              <Button color="primary" className="ml-2" onClick={this.onClickAddFromExists}>
                <span className="fas fa-plus mr-1" />
                既存ユーザーから追加
              </Button>
            </div>
            <table className="table table-bordered mt-2">
              <thead className="thead-light text-center text-nowarap">
                <tr>
                  <th>ユーザー名</th>
                  <th>メールアドレス</th>
                  <th>権限</th>
                  <th>最終アクセス日時</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  (users || []).map(({ uid, name, email, role, lastAccessedAt }, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {name}
                        </td>
                        <td>
                          {email}
                        </td>
                        <td>
                          {texts[role]}
                        </td>
                        <td>
                          {lastAccessedAt && formatDate(lastAccessedAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
                        </td>
                        <td className="text-right">
                          <Button className="mr-2" color="secondary" onClick={this.onClickEdit.bind(this, { uid, name, email, role })}>
                            <span className="fas fa-edit mr-1" />
                            編集
                          </Button>
                          <Button className="mr-2" color="secondary" onClick={this.invite.bind(this, email, role)}>
                            <span className="fas fa-envelope mr-1" />
                            招待
                          </Button>
                          <Button color="danger" onClick={this.delete.bind(this, uid)}>
                            <span className="fas fa-trash mr-1" />
                            削除
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          <hr className="my-5 py-4" />
          <div className="mt-5">
            <div className="d-flex justify-content-center">
              <h5>補足情報取得失敗Receptions</h5>
            </div>
            <table className="table mt-1 table-condensed">
              <tbody>
                {
                  failedReceptions.map(({ id, failedAt, errorMessage }) => {
                    return (
                      <tr key={id}>
                        <td className="text-nowrap">
                          {id}
                        </td>
                        <td className="text-nowrap">
                          {formatDate(failedAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
                        </td>
                        <td>
                          {errorMessage}
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <UserSelectModal isOpen={shouldShowUserSelectModal} onSubmit={this.onSubmitUserSelect} onClickClose={this.closeUserSelectModal} users={uniqBy(allUsers.filter(_ => !memberIds.includes(_.uid)), _ => _.uid)} />
        <TenantAdminSettingsFormModal values={tenant} isOpen={shouldShowTenantAdminSettingsFormModal} onSubmit={this.onSubmitTenantAdminSettingsForm} onClickClose={this.closeModal.bind(this, 'TenantAdminSettingsFormModal')} />
      </div>
    );
  }
}));

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import TenantFormModal from '../modals/TenantFormModal';
import AdminPage from '../hocs/AdminPage';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default AdminPage(class AdminTenant extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowFormModal: false,
    };
  }
  componentDidMount() {
    this.listenTenants();
  }
  listenTenants() {
    tenantsRef
      .onSnapshot(({ docs }) => {
        this.setState({ tenants: docs.map(_ => _.data()) });
      });
  }
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  onSubmitForm = ({ name, slug }) => {
    tenantsRef.doc(slug).set({
      name, slug
    }).then(() => {
      this.closeFormModal();
      toast.success('追加しました');
    });
  }
  onClickDelete = (slug) => {
    if (!window.confirm('本当に削除しますか？')) return;
    this.deleteUsers(slug)
      .then(() => tenantsRef.doc(slug).delete())
      .then(
        toast.success.bind(this, '削除しました')
      );
  }
  deleteUsers = (slug) => {
    return tenantsRef.doc(slug).collection('users').get().then(({ docs }) => {
      return Promise.all(docs.map(_ => _.ref.delete()));
    });
  }
  render() {
    const { shouldShowFormModal, tenants } = this.state;
    return (
      <div className="admin-tenant">
        <div className="container p-5">
          <div className="d-flex justify-content-center">
            <h4>テナント一覧</h4>
          </div>
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={this.openFormModal}>
              <span className="fas fa-plus mr-1" />
              新規テナント
            </Button>
          </div>
          <table className="table mt-5">
            <tbody>
              {
                (tenants || []).map(({ name, slug }, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Link to={`/admin/tenants/${slug}`}>{name}</Link>
                      </td>
                      <td>
                        <Button color="danger" onClick={this.onClickDelete.bind(this, slug)}>
                          <span className="fas fa-trash mr-1" />
                          削除
                        </Button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <TenantFormModal isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} />
      </div>
    );
  }
});

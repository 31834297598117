import React  from 'react';
import { Table, Button } from 'reactstrap';
import { isEmpty } from 'lodash';

import TableDisplay from '../TableDisplay';

export default function AutoTableConfirmation({ payload: { tables }, onAction, isActive }) {
  return (
    <div className="auto-table-confirmation">
      {
        !isEmpty(tables) ? (
          <Table>
            <thead>
              <tr>
                <th>自動配席しました</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    tables.map((table) => (
                      <div key={table.id}>
                        <TableDisplay key={table.id} table={table} />
                      </div>
                    ))
                  }
                </td>
              </tr>
              <tr>
                <td className="d-flex justify-content-around">
                  <Button color="primary" onClick={onAction.bind(this, { type: 'confirmAutoTable', payload: { tables } })} disabled={!isActive}>OK</Button>
                  <Button color="secondary" onClick={onAction.bind(this, { type: 'editTables' })} disabled={!isActive}>編集</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>自動配席できませんでした</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Button color="primary" onClick={onAction.bind(this, { type: 'editTables' })} disabled={!isActive}>手動で配席</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )
      }
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { isEmpty, mapValues } from 'lodash';
import classnames from 'classnames';
import { useMap } from 'react-use';

const { values } = Object;

export default function TenantRouteAliasesFormModal(props) {
  const { isOpen, tenant, routes, onClickClose } = props;
  const [routeAliases, { set: setRouteAliases, }] = useMap(tenant.routeAliases || {});
  const isUnsubmittable = values(routeAliases).some(_ => _ != null && _.length === 0);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ routeAliases });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        経路表示名設定
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            routes.map(({ id, label }) => {
              const value = routeAliases[id] != null ? routeAliases[id] : routes.find(_ => _.id === id).label;
              const validationCss = classnames({ 'is-valid': !isEmpty(value), 'is-invalid': isEmpty(value) });

              return (
                <FormGroup key={id}>
                  <Label>{label}</Label>
                  <Input value={value} onChange={_ => setRouteAliases(id, _.target.value)} className={validationCss} />
                </FormGroup>
              );
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};



import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';
import Select from 'react-select';

import MessageForm from '../hocs/MessageForm';
import { routes } from '../../config';
import { routeLabel, } from '../../shared/util';

const { entries } = Object;

export default MessageForm(class ReservationRoute extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { route } = this.state;
    onAction({ type: 'submitReservationRoute', payload: { route } });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['route'].some(_ => !validations[_]);
  }
  render() {
    const { isActive, data: { tenant, customRoutes = [] }, } = this.props;
    const { route } = this.state;
    const routeOptions = [
      ...entries(routes).filter(_ => _[0].match(/^phone/)).map(([value, label]) => ({ label: routeLabel(value, routes, customRoutes, tenant.routeAliases), value })),
      ...customRoutes.map(_ => ({ label: routeLabel(_.id, routes, customRoutes, tenant.routeAliases), value: _.id })),
    ];
    return (
      <div className="reservation-route">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>経路を選択してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Select
                    value={routeOptions.find(_ => _.value === route)}
                    onChange={this.onChangeSelect.bind(this, 'route')}
                    options={routeOptions}
                    isClearable={false}
                    isDisabled={!isActive}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

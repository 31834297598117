import React, { Component, Fragment } from 'react';
import { format as formatDate, setHours, startOfHour, endOfDay, differenceInMinutes, startOfDay, addHours } from 'date-fns';

import ReservationBox from './ReservationBox';
import ReservationFrame from './ReservationFrame';

export default class TenantScheduleRow extends Component {
  render() {
    const { slug, date, hours = [], startHours, table, reservations = [], rowHeight, columnWidth, onClickReservation, onReservationMoveEnd } = this.props;
    const hourWidth = columnWidth * 4;
    const isWaiting = !table;
    return (
      <tr style={{ height: rowHeight }}>
        <th className="text-nowrap border-right-0 align-middle" style={{ width: columnWidth * 7, height: rowHeight }}>
          <div className="d-flex justify-content-between">
            <div>{isWaiting ? '未割り当て' : table.name}</div>
            <div>{!isWaiting && `${table.capacity}席`}</div>
          </div>
        </th>
        <td className="position-relative p-0" style={{ width: 0, height: rowHeight }}>
          {
            reservations.map((reservation, index) => {
              const { id, startAt, endAt, nameKana, peopleCount, route, versions = [] } = reservation;
              const diffHours = differenceInMinutes(endAt.toDate(), startAt.toDate()) / 60;
              const offsetHours = differenceInMinutes(startAt.toDate(), startOfHour(setHours(startAt.toDate(), startHours))) / 60;
              return (
                <ReservationBox key={reservation.id} {...{ startHours, reservation }} style={{ top: rowHeight * 0.15, left: offsetHours * hourWidth + columnWidth * 2, width: diffHours * hourWidth, height: rowHeight * 0.7 }} onClick={onClickReservation.bind(null, reservation)} onMoveEnd={onReservationMoveEnd} table={table} prevReservations={reservations.slice(0, index)} index={index} />
              );
            })
          }
        </td>
        {
          hours.map((hour) => {
            const baseDate = setHours(startOfDay(date), hour);
            return (
              <Fragment key={hour}>
                <ReservationFrame slug={slug} table={table} date={addHours(baseDate, -2 * 0.25)} className="border-right-0" style={{ borderLeftStyle: 'dashed', width: columnWidth, height: rowHeight, }} isWaiting={isWaiting}>
                </ReservationFrame>
                <ReservationFrame slug={slug} table={table} date={addHours(baseDate, -1 * 0.25)} className="border-left-0 border-right-0" style={{ width: columnWidth, height: rowHeight, }} isWaiting={isWaiting}>
                </ReservationFrame>
                <ReservationFrame slug={slug} table={table} date={addHours(baseDate, 0 * 0.25)} className="border-right-0" style={{ width: columnWidth, height: rowHeight, }} isWaiting={isWaiting}>
                </ReservationFrame>
                <ReservationFrame slug={slug} table={table} date={addHours(baseDate, 1 * 0.25)} className="border-left-0 border-right-0" style={{ width: columnWidth, height: rowHeight, }} isWaiting={isWaiting}>
                </ReservationFrame>
              </Fragment>
            );
          })
        }
      </tr>
    );
  }
};

import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';

import useDocumentSubscription from './hooks/useDocumentSubscription';

export default function EditButton ({ label = '編集', validateOnSubmit = _ => true, itemRef, FormModal, formProps, processValues = _ => _, beforeUpdate = _ => _, onFinish = _ => _, ...extraProps }) {
  const [showsModal, toggleModal] = useToggle(false);
  const item = useDocumentSubscription(showsModal && itemRef, [showsModal]);
  const onSubmit = async (values) => {
    if(!(await validateOnSubmit({ id: itemRef.id, ...values }))) return;
    try {
      if((await beforeUpdate({ id: itemRef.id, ...values })) === false) return;

      const processedValues = await processValues(values);
      await itemRef.update(processedValues);
      await onFinish(processedValues);
      toast.success('保存しました');
      toggleModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Fragment>
      <Button color="secondary" onClick={_ => toggleModal(true)} {...extraProps}>
        <span className="fas fa-edit" />
        {label && <span className="ml-1">{label}</span>}
      </Button>
      {
        item && showsModal && (
          <FormModal itemRef={itemRef} isOpen={showsModal} values={item} onClickClose={_ => toggleModal(false)} onSubmit={onSubmit} {...formProps} />
        )
      }
    </Fragment>
  );
};

import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { keyBy, get, isEmpty, sortBy, uniqBy, omit, debounce } from 'lodash';
import qs from 'qs';
import { addDays, endOfDay, startOfDay, } from 'date-fns';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import dedent from 'dedent';

import firebase, { functions } from '../../firebase';
import { routeLabel, initialRoute, } from '../../shared/util';
import { cancelFeeFields } from '../../shared/models/reservation';
import TenantPage from '../hocs/TenantPage';
import ReservationEditFormModal from '../modals/ReservationEditFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import TenantHeaderNav from '../TenantHeaderNav';
import ReservationCard from '../ReservationCard';

const chargeCancelFee = functions.httpsCallable('chargeCancelFee');
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const customRoutesRef = db.collection('customRoutes');
const { keys } = Object;

export default TenantPage(class TenantReservations extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.listenCustomRoutes();
    this.listenReservation();
    this.listenTables();
    this.listenCourses();
    this.listenTags();
  }
  listenCustomRoutes() {
    customRoutesRef
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customRoutes: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenReservation() {
    const { match: { params: { slug, reservationId } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('reservations')
      .doc(reservationId)
      .onSnapshot((snap) => {
        this.setState({ reservation: { id: snap.id, ref: snap.ref, ...snap.data() } });
      });
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ tables: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y}), {}) });
      });
  }
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .onSnapshot((snapshot) => {
        const courses = snapshot.docs.map(_ => ({ ..._.data(), id: _.id }));
        this.setState({ courses, coursesById: keyBy(courses, 'id'), });
      });
  }
  listenTags() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tags')
      .onSnapshot((snapshot) => {
        this.setState({ tags: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  onClickEnableCancelProtection = async () => {
    const { reservation, } = this.state;
    const message = dedent`
      キャンセル・プロテクションは一度有効にしたら解除することはできません。
      本当にこちらの予約のキャンセル・プロテクションを有効にしますか？
    `;
    if(!window.confirm(message)) return;

    await reservation.ref.update({ cancelProtectionEnabled: true });
  }
  onSubmitEditForm = ({ privateNote, initialRoute }) => {
    const { tenant, match: { params: { slug, reservationId } } } = this.props;
    const { reservation, } = this.state;
    try {
      tenantsRef.doc(slug).collection('reservations').doc(reservationId).update({
        ...(
          isEmpty(reservation.versions)
          ? { route: initialRoute }
          : { versions: [{ ...reservation.versions[0], route: initialRoute }, ...reservation.versions.slice(1)] }
        ),
        privateNote,
      });
      toast.success('編集しました');
      this.setState({ showsModal: false });
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
  }
  render() {
    const { tenant, history, user, match: { params: { slug, reservationId } } } = this.props;
    const { customRoutes = [], reservation, tables = {}, tags = {}, courses = [], coursesById = {}, showsModal = false, } = this.state;
    const initialReservation = isEmpty(reservation && reservation.versions) ? reservation : reservation.versions[0];
    return (
      <div className="tenant-reservation">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
        <div className="container p-4 pt-4 mt-5">
          <h4 className="text-center mb-4">
            予約詳細
            <span>
              {
                reservation && (
                  reservation.cancelReason && (
                    <span className="ml-2 text-danger">[取消済み]</span>
                  )
                )
              }
            </span>
          </h4>
          {
            reservation && (
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="d-flex justify-content-between mb-2 align-items-center">
                    <audio src={reservation.voiceUrl} controls preload className={classnames({ invisible: !reservation.voiceUrl })} />
                    <div className="d-flex align-items-center">
                      {
                        tenant.cancelProtectionEnabled && (
                          reservation.cancelProtectionEnabled ? (
                            <CancelFeeButton tenant={tenant} tenantSlug={slug} reservation={reservation} courses={courses} disabled={addDays(startOfDay(reservation.startAt.toDate()), 7) < startOfDay(new Date())} />
                          ) : (
                            <Button onClick={this.onClickEnableCancelProtection} disabled={new Date() > addDays(reservation.startAt.toDate(), -2)}>
                              キャンセル・プロテクションを有効にする
                            </Button>
                          )
                        )
                      }
                      <Button className="ml-2" tag={Link} to={`/t/${slug}?${qs.stringify({ state: { mode: 'update' }, response: { type: 'submitReservationSelection', payload: { selectedReservationId: reservationId } } })}`} target="_blank" disabled={new Date() > endOfDay(reservation.startAt.toDate())}>
                        <span className="fas fa-edit mr-1" />
                        予約変更する
                      </Button>
                      <Button className="ml-2" onClick={_ => this.setState({ showsModal: true })}>
                        <span className="fas fa-edit mr-1" />
                        編集する
                      </Button>
                    </div>
                  </div>
                  {
                    reservation.privateNote && (
                      <div className="border border-info rounded p-2 mb-3" style={{ whiteSpace: 'pre-line', }}>
                        <div className="mb-2 text-muted small">店舗メモ</div>
                        {reservation.privateNote}
                      </div>
                    )
                  }
                  <ReservationCard className="mb-3" id={reservationId} customRoutes={customRoutes} reservation={reservation} tables={tables} courses={coursesById} tags={tags} tenant={tenant} main isInitial={reservation === initialReservation} />
                  {
                    reservation.versions && (
                      <div className="mt-5">
                        <h5>過去履歴</h5>
                        {
                          [...reservation.versions].reverse().map((version, i) => {
                            return <ReservationCard customRoutes={customRoutes} key={i} className="mb-3" reservation={version} tables={tables} courses={coursesById} tenant={tenant} tags={tags} isInitial={version === initialReservation} />;
                          })
                        }
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
        {
          showsModal && <ReservationEditFormModal isOpen values={{ privateNote: reservation.privateNote, initialRoute: initialRoute(reservation) }} customRoutes={customRoutes} onClickClose={_ => this.setState({ showsModal: false })} onSubmit={this.onSubmitEditForm} />
        }
      </div>
    );
  }
});

function CancelFeeButton (props) {
  const { tenant, tenantSlug, reservation, courses, ...extraProps } = props;
  const course = courses.find(_ => _.id === reservation.courseId);
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    if(!window.confirm('キャンセル料を徴収します。よろしいですか？')) return;

    try {
      await chargeCancelFee({
        tenantSlug,
        reservationId: reservation.id,
        ...values,
      });
      toggleModal(false);
      toast.success('キャンセル料を徴収しました');
      props.onFinished && props.onFinished();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  }
  return (
    <Fragment>
      <Button color="warning" onClick={toggleModal.bind(null, true)} disabled={reservation.stripeCustomer == null || reservation.cancelCharge != null} {...extraProps}>
        キャンセル料を徴収する
      </Button>
      {showsModal && <ModelFormModal isOpen title="キャンセル料" submitButtonLabel="キャンセル料を徴収する" fields={cancelFeeFields({ price: course == null ? get(tenant, 'tableOnlyPrice', 0) : get(course, 'price', 0), peopleCount: reservation.peopleCount, })} onSubmit={onSubmit} onClickClose={toggleModal.bind(null, false)} />}
    </Fragment>
  );
}

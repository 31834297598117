import React, { Component } from 'react';
import { Button } from 'reactstrap';

import TenantAdminPage from '../hocs/TenantAdminPage';
import UserCrudable from '../hocs/UserCrudable';
import { texts } from '../../config';

export default TenantAdminPage(UserCrudable(class TenantAdminUsers extends Component {
  render() {
    const { tenant, match: { params: { slug } } } = this.props;
    const { users } = this.state;
    return (
      <div className="admin-tenants">
        <div className="d-flex justify-content-center mb-3">
          <h4>ユーザー管理</h4>
        </div>
        <div className="d-flex justify-content-end">
          <Button color="primary" onClick={this.onClickAdd}>
            <span className="fas fa-plus mr-1" />
            新規ユーザー
          </Button>
        </div>
        <table className="table mt-5">
          <tbody>
            {
              (users || []).map(({ uid, name, email, role }, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {name}
                    </td>
                    <td>
                      {texts[role]}
                    </td>
                    <td>
                      <Button className="mr-2" color="secondary" onClick={this.onClickEdit.bind(this, { uid, name, email, role })}>
                        <span className="fas fa-edit mr-1" />
                        編集
                      </Button>
                      <Button className="mr-2" color="secondary" onClick={this.invite.bind(this, email, role)}>
                        <span className="fas fa-envelope mr-1" />
                        招待
                      </Button>
                      <Button color="danger" onClick={this.delete.bind(this, uid)}>
                        <span className="fas fa-trash mr-1" />
                        削除
                      </Button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}));

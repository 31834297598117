import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { parse as parseDate, startOfDay, } from 'date-fns';
import { Button, Form, FormGroup, Label, } from 'reactstrap';
import { StripeProvider, Elements, CardElement, injectStripe } from 'react-stripe-elements';
import { useToggle, useAsync, useCounter } from 'react-use';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { omit, get } from 'lodash';

import env from '../../env';
import firebase, { functions } from '../../firebase';
import PublicPage from '../hocs/PublicPage';
import PublicTenantBlock from '../PublicTenantBlock';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import ReservationCard from '../ReservationCard';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const findReservationByReceptionId = functions.httpsCallable('findReservationByReceptionId');
const findReservationByReservationId = functions.httpsCallable('findReservationByReservationId');
const createReservationCustomer = functions.httpsCallable('createReservationCustomer');
const cancelReservationOnCall = functions.httpsCallable('cancelReservationOnCall');

export default PublicPage(function PublicReservation(props) {
  const { location } = props;
  const [version, { inc: incVersion }] = useCounter(0);
  const queryParams = qs.parse(location.search.slice(1));
  const { tenantSlug, receptionId, reservationId, } = queryParams;
  const { value: reservation } = useAsync(async () => {
    const { data: reservation } = await (reservationId != null ? findReservationByReservationId({ tenantSlug, reservationId }) : findReservationByReceptionId({ tenantSlug, receptionId }));
    return reservation;
  }, [tenantSlug, receptionId, reservationId, version]);
  const course = useDocumentSubscription(get(reservation, 'courseId') && tenantsRef.doc(tenantSlug).collection('courses').doc(reservation.courseId), [reservation]);
  const amount = Math.floor(get(reservation, 'peopleCount', 0) * get(course, 'price', 0) * (1 + 0.1));
  const onSubmit = async ({ token }) => {
    const { data, } = (await createReservationCustomer({
      tenantSlug,
      reservationId: reservation.id,
      reservationName: reservation.name || reservation.nameKana,
      token,
      amount,
    }));
    if(get(data, 'error') != null) {
      throw data.error;
    } else {
      toast.success('決済情報が登録されました');
      incVersion();
    }
  };
  const onCancelled = () => {
    incVersion();
  };

  return (
    <div className="container py-3">
      <div className="row">
        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
          {
            reservation === null ? (
              <div className="text-center">
                予約が見つかりません
              </div>
            ) : reservation != null ? (
              <div>
                <PublicTenantBlock tenant={reservation.tenant} />
                {
                  reservation.cancelReason == null ? (
                    new Date() < startOfDay(parseDate(reservation.startAt._seconds * 1000)) && (
                      <div className="mt-3 d-flex justify-content-end">
                        <CancelButton {...{ tenantSlug, reservation, onCancelled, }} />
                      </div>
                    )
                  ) : (
                    <div className="alert alert-danger">
                      この予約は取り消されました。
                    </div>
                  )
                }
                <div className="mt-4">
                  <ReservationCard reservation={reservation} dateParser={_ => parseDate(_._seconds * 1000)} courses={{ [get(course, 'id')]: course }} forCustomer />
                </div>
                {
                  reservation.cancelProtectionEnabled && (
                    <div className="mt-5">
                      <h5 className="text-">
                        キャンセル料支払情報
                      </h5>
                      {
                        reservation.stripeCustomer != null ? (
                          <div className="card p-3">
                            <div className="text-success">
                              登録済み
                            </div>
                          </div>
                        ) : (
                          <div className="mt-3">
                            <div className="card p-3">
                              <FormApp onSubmit={onSubmit} />
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div>
                  <span className="fas fa-spin fa-spinner mr-1" />
                  Loading...
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

function FormApp (props) {
  return (
    <StripeProvider apiKey={env('STRIPE_API_KEY')}>
      <FormContainer {...props} />
    </StripeProvider>
  );
};

function FormContainer (props) {
  return (
    <Elements>
      <InjectedForm {...props} />
    </Elements>
  );
};

function CardForm (props) {
  const { stripe, onFinished, } = props;
  const isUnsubmittable = false;
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isSubmitting) return;

    toggleSubmitting(true);
    try {
      const { token } = await stripe.createToken({ type: 'card' });
      await props.onSubmit({ token, });
      toggleSubmitting(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div>
        <div>
          <FormGroup className="mt-4">
            <Label>
              クレジットカード
              <span className="text-danger small">【必須】</span>
            </Label>
            <CardElement hidePostalCode />
          </FormGroup>
        </div>
      </div>
      <div className="mt-4">
        <Button block className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>送信する</Button>
      </div>
    </Form>
  );
};

const InjectedForm = injectStripe(CardForm);

function CancelButton (props) {
  const { tenantSlug, reservation, onCancelled } = props;
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    if(!window.confirm('本当にキャンセルしますか？')) return;

    toggleProcessing(true);
    try {
      await cancelReservationOnCall({ tenantSlug, reservationId: reservation.id });
      toast.success('キャンセルしました');
      onCancelled();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleProcessing(false);
  };

  return (
    <Button size="sm" color="danger" outline onClick={onClick} disabled={isProcessing}>
      この予約をキャンセルする
    </Button>
  );
};

import React, { Component } from 'react';
import { Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { differenceBy } from 'lodash';

import MessageForm from '../hocs/MessageForm';

const { keys } = Object;

export default MessageForm(class Overflowings extends Component {
  onClickAllowOverflowing = (reservationId) => {
    const { onAction } = this.props;
    onAction({ type: 'allowOverflowings', payload: { reservationId, nextType: 'viewOverflowings' } });
  }
  onClickEdit = (reservationId) => {
    const { onAction } = this.props;
    onAction({ type: 'editSpecifiedReservation', payload: { reservationId, locksAllocation: true } });
  }
  render() {
    const { onAction, payload: { overflowings = [] }, data: { tables, waitings = [] }, isActive } = this.props;
    const { selectedId } = this.state;
    const targetReservations = differenceBy(overflowings, waitings, _ => _.id).filter(_ => !_.cancelReason && !_.hasAllowedOverflowings);
    return (
      <div className="overflowings">
        {
          targetReservations.length > 0 ? (
            <Table>
              <tbody>
                {
                  targetReservations.map(({ id, startAt, endAt, peopleCount, nameKana, tableIds }) => {
                    return (
                      <tr key={id}>
                        <td>
                          <div className="small">
                            <span className="text-muted">[{id}]</span>
                          </div>
                          <div>
                            {formatDate(startAt.toDate(), 'MM/DD HH:mm')} - {formatDate(endAt.toDate(), 'HH:mm')} {keys(tableIds || {}).map(id => tables.find(_ => _.id === id).name).join(', ')}
                            <br />
                            {nameKana} {peopleCount}名様
                          </div>
                          <div className="mt-1 d-flex justify-content-around">
                            <Button color="success" size="sm" onClick={this.onClickAllowOverflowing.bind(this, id)} disabled={!isActive}>
                              定員オーバー了承
                            </Button>
                            <Button color="default" size="sm" onClick={this.onClickEdit.bind(this, id)} disabled={!isActive}>
                              変更
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          ) : (
            <div>定員オーバー予約はありません</div>
          )
        }
        <div className="mt-2">
          <Button color="primary" onClick={() => onAction({ type: 'toHome' })} disabled={!isActive}>
            終了
          </Button>
        </div>
      </div>
    );
  }
});

const CLIENT_ID = process.env[`REACT_APP_${process.env.REACT_APP_ENV.toUpperCase()}_GOOGLE_CLIENT_ID`];
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.modify';
const { gapi } = window;

export default async function initAuth2() {
  return new Promise((resolve) => {
    gapi.load('client:auth2', () => {
      resolve(gapi.auth2.init({
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }));
    });
  });
};

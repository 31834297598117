import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { omitBy } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';
import { isEmail } from 'validator';

import firebase from '../../firebase';
import { texts, tableSmokings, tablePartitions, tableShoes } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const defaultState = {
  id: null,
  name: '',
  tableTypeId: null,
  capacity: 0,
  nameHP: '',
  nameGN: '',
  nameTB: '',
  validations: {},
  smoking: null,
  partition: null,
  shoe: null,
};
const { entries } = Object;

export default class TableFormModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidMount(prevProps, prevState) {
    this.setValues();
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  setValues() {
    const { values } = this.props;
    this.setState({ ...omitBy(values, _ => _ === undefined) });
    entries(values || {}).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      case 'name': 
      case 'tableTypeId': 
      case 'nameHP': 
      case 'nameGN': 
      case 'nameTB': 
        this.setState({ validations: { ...validations, [propName]: !!value } });
        break;
      case 'capacity': 
        this.setState({ validations: { ...validations, capacity: value > 0 } });
        break;
      default:
    }
  }
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onChangeNumber = (name, { target: { value: _value } }) => {
    const value = parseInt(_value, 10) || null;
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSelect = (name, option) => {
    const value = option ? option.value : null;
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    const { id, name, tableTypeId, capacity, nameHP, nameGN, nameTB, smoking, partition, shoe } = this.state;
    onSubmit({ id, name, tableTypeId, capacity, nameHP, nameGN, nameTB, smoking, partition, shoe });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['name', 'capacity', 'tableTypeId', 'nameHP', 'nameGN', 'nameTB'].some(_ => !validations[_]);
  }
  render() {
    const { isOpen, tableTypes, onClickClose } = this.props;
    console.log(this.state.validations);
    const { id, name, tableTypeId, capacity, nameHP, nameGN, nameTB, smoking, partition, shoe } = this.state;
    const isNew = !id;
    const tableTypeOptions = (tableTypes || []).map(_ => ({ label: _.name, value: _.id }));
    const tableSmokingOptions = entries(tableSmokings).map(([k, v]) => ({ label: v, value: k }));
    const tablePartitionOptions = entries(tablePartitions).map(([k, v]) => ({ label: v, value: k }));
    const tableShoeOptions = entries(tableShoes).map(([k, v]) => ({ label: v, value: k }));
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          テーブル
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>テーブル名</Label>
              <Input value={name} onChange={this.onChange.bind(this, 'name')} className={this.validationCss('name')} disabled={!isNew} />
            </FormGroup>
            <FormGroup>
              <Label>タイプ</Label>
              <Select
                value={tableTypeOptions.find(_ => _.value === tableTypeId) || null}
                onChange={this.onSelect.bind(this, 'tableTypeId')}
                options={tableTypeOptions}
                isClearable={false}
              />
            </FormGroup>
            <FormGroup>
              <Label>定員</Label>
              <Input value={capacity} type="number" onChange={this.onChangeNumber.bind(this, 'capacity')} className={this.validationCss('capacity')}/>
            </FormGroup>
            <FormGroup>
              <Label>HPセット名</Label>
              <Input value={nameHP} onChange={this.onChange.bind(this, 'nameHP')} className={this.validationCss('nameHP')}/>
            </FormGroup>
            <FormGroup>
              <Label>GNセット名</Label>
              <Input value={nameGN} onChange={this.onChange.bind(this, 'nameGN')} className={this.validationCss('nameGN')}/>
            </FormGroup>
            <FormGroup>
              <Label>TBセット名</Label>
              <Input value={nameTB} onChange={this.onChange.bind(this, 'nameTB')} className={this.validationCss('nameTB')}/>
            </FormGroup>
            <FormGroup>
              <Label>喫煙可</Label>
              <Select
                value={tableSmokingOptions.find(_ => _.value === smoking) || null}
                onChange={this.onSelect.bind(this, 'smoking')}
                options={tableSmokingOptions}
                isClearable={true}
              />
            </FormGroup>
            <FormGroup>
              <Label>間仕切り</Label>
              <Select
                value={tablePartitionOptions.find(_ => _.value === partition) || null}
                onChange={this.onSelect.bind(this, 'partition')}
                options={tablePartitionOptions}
                isClearable={true}
              />
            </FormGroup>
            <FormGroup>
              <Label>土足可</Label>
              <Select
                value={tableShoeOptions.find(_ => _.value === shoe) || null}
                onChange={this.onSelect.bind(this, 'shoe')}
                options={tableShoeOptions}
                isClearable={true}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

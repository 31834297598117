import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input } from 'reactstrap';

import firebase from '../../firebase';

const auth = firebase.auth();
const db = firebase.firestore();

export default function PageHOC(WrappedComponent) {
  return class Page extends Component {
    constructor() {
      super();
      this.state = {
        shouldShowLoginModal: false,
      };
    }
    componentWillMount() {
      auth.onAuthStateChanged((firebaseUser) => {
        if (!firebaseUser) {
          this.openLoginModal();
        } else {
          this.closeLoginModal();
          const { uid, email } = firebaseUser;
          this.setState({ uid, email });
        }
      });
    }
    openLoginModal = () => this.setState({ shouldShowLoginModal: true })
    closeLoginModal = () => this.setState({ shouldShowLoginModal: false })
    onSubmit = (event) => {
      const { email, password } = this.state;
      event.preventDefault();
      auth.signInWithEmailAndPassword(email, password)
        .catch(({ message: errorMessage }) => {
          this.setState({ errorMessage });
        });
    }
    onChange = (name, { target: { value } }) => this.setState({ [name]: value })
    render() {
      const { shouldShowLoginModal, email, password, errorMessage, uid } = this.state;
      return (
        <div className="page">
          {
            uid && <WrappedComponent {...this.props} {...{ uid }} />
          }
          {
            <Modal isOpen={shouldShowLoginModal}>
              <ModalHeader>
                RSBLにログイン
              </ModalHeader>
              <Form onSubmit={this.onSubmit}>
                <ModalBody className="d-flex flex-column justify-content-center align-items-stretch p-5">
                  <div className="text-danger mb-3">
                    {errorMessage}
                  </div>
                  <FormGroup>
                    <Input type="email" onChange={this.onChange.bind(this, 'email')} placeholder="メールアドレス" />
                  </FormGroup>
                  <FormGroup>
                    <Input type="password" onChange={this.onChange.bind(this, 'password')} placeholder="パスワード" />
                  </FormGroup>
                  <Button type="submit" color="primary" block onClick={this.onSubmit} disabled={!email || !password}>
                    ログインする
                  </Button>
                </ModalBody>
              </Form>
            </Modal>
          }
        </div>
      );
    }
  }
};

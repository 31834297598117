import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { omit, keyBy } from 'lodash';

import firebase, { config } from '../../firebase';
import TableCombinableFormModal from '../modals/TableCombinableFormModal';

import TenantAdminPage from '../hocs/TenantAdminPage';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { entries, keys } = Object;

export default TenantAdminPage(class TenantAdminTables extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.listenTables();
    this.listenTableCombinables();
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ tables: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenTableCombinables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tableCombinables')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ tableCombinables: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  onSubmitForm = async (values) => {
    const { id } = values;
    const { match: { params: { slug } } } = this.props;
    const isNew = id == null;
    const tableCombinablesRef = tenantsRef.doc(slug).collection('tableCombinables');
    try {
      await (isNew ? tableCombinablesRef.doc(values.name).set({ ...omit(values, 'id'), createdAt: new Date() }) : tableCombinablesRef.doc(id).update(omit(values, 'id')));
      toast.success(`${isNew ? '追加' : '更新'}しました`);
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    } finally {
      this.closeFormModal()
    }
  }
  delete = (id) => {
    const { match: { params: { slug } } } = this.props;
    if (!window.confirm('本当に削除しますか？')) return;
    tenantsRef
      .doc(slug)
      .collection('tableCombinables')
      .doc(id)
      .delete()
      .then(toast.success.bind(this, '削除しました'));
  }
  onClickAdd = () => {
    this.setState({ targetTableValues: null });
    this.openFormModal();
  }
  onClickEdit = (values) => {
    this.setState({ targetTableValues: values });
    this.openFormModal();
  }
  render() {
    const { match: { params: { slug } } } = this.props;
    const { tables, tableCombinables, shouldShowFormModal, targetTableValues } = this.state;
    const tablesById = keyBy(tables, 'id');
    return (
      <div className="tenant-admin-table-groups">
        <div className="d-flex justify-content-center mb-3">
          <h4>結合可能テーブル群設定</h4>
        </div>
        <div className="d-flex justify-content-end">
          <Button color="primary" onClick={this.onClickAdd}>
            <span className="fas fa-plus mr-1" />
            新規 結合可能テーブル群
          </Button>
        </div>
        <table className="table mt-5">
          <thead>
            <tr>
              <th>結合可能テーブル群名</th>
              <th>テーブル</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              (tableCombinables || []).map(({ id, tables, name, }) => {
                return (
                  <tr key={id}>
                    <td>
                      {name}
                    </td>
                    <td>
                      {keys(tables || {}).map(_ => tablesById[_]).map(_ => _ && _.name).join(', ')}
                    </td>
                    <td>
                      <Button className="mr-2" color="secondary" onClick={this.onClickEdit.bind(this, { id, name, tables, })}>
                        <span className="fas fa-edit mr-1" />
                        編集
                      </Button>
                      <Button color="danger" onClick={this.delete.bind(this, id)}>
                        <span className="fas fa-trash mr-1" />
                        削除
                      </Button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <TableCombinableFormModal values={targetTableValues} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} tables={tables} />
      </div>
    );
  }
});

import React, { Component } from 'react';

import Page from './Page';
import HeaderNav from '../HeaderNav';
import BreadNav from '../BreadNav';
import SideNav from '../SideNav';
import firebase from '../../firebase';

const auth = firebase.auth();
const db = firebase.firestore();
const adminUsersRef = db.collection('adminUsers');

export default function AdminPageHOC(WrappedComponent) {
  return Page(class AdminPage extends Component {
    constructor() {
      super();
      this.state = {
        isAdmin: false,
        hasCheckedAdmin: false,
      };
    }
    componentDidMount() {
      this.checkAdminUser();
    }
    signOut = () => {
      auth.signOut();
      window.location.reload();
    }
    checkAdminUser() {
      const { uid } = this.props;
      adminUsersRef
        .doc(uid)
        .onSnapshot((snapshot) => {
          const { exists } = snapshot;
          this.setState({ adminUser: snapshot.data(), isAdmin: exists, hasCheckedAdmin: true });
        });
    }
    render() {
      const { uid, match: { path, params } } = this.props;
      const { isAdmin, hasCheckedAdmin, adminUser } = this.state;
      return hasCheckedAdmin && (isAdmin ? (
        <div className="page d-flex flex-row">
          <SideNav user={adminUser} currentPath={path} />
          <div className="d-flex flex-column flex-grow-1">
            <HeaderNav currentPath={path} />
            <BreadNav currentPath={path} currentParams={params} />
            <WrappedComponent {...this.props} />
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          Not Authorized
          <span className="btn btn-link" onClick={this.signOut}>
            ログアウト
          </span>
        </div>
      ));
    }
  });
};

import React, { Fragment } from 'react';
import { LineChart, Line, XAxis, YAxis, Legend, CartesianGrid, Tooltip } from 'recharts';
import { omit } from 'lodash';

import { colors, summaryRoutes } from '../../../config';

const { keys } = Object;

export default function AppLineChart({ data }) {
  const dataKeys = keys(data[0] || {}).filter(_ => _ !== 'name');
  return (
    <LineChart width={320} height={320} data={data} margin={{ right: 26, top: 10 }}>
      {
        dataKeys.map((k, i) => {
          return (
            <Line dataKey={k} key={i} stroke={colors[i]} />
          );
        })
      }
      <XAxis dataKey="name" fontSize={9} interval={9} />
      <YAxis fontSize={9} width={40} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend wrapperStyle={{ fontSize: '9px' }} />
    </LineChart>
  );
};

import React, { Component, Fragment } from 'react';
import { format as formatDate } from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { keyBy, sumBy, isEmpty, groupBy, sortBy, uniqBy, omit, debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { Button, Table, FormGroup, Label, Input } from 'reactstrap';
import { parse as parseDate, differenceInMinutes, startOfDay, endOfDay, addDays } from 'date-fns';
import moment from 'moment';
import qs from 'qs';
import numeral from 'numeral';

import ExportButton from '../ExportButton';
import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import TenantHeaderNav from '../TenantHeaderNav';
import ReservationListItem from '../ReservationListItem';
import ReservationsHeader from '../ReservationsHeader';
import { statuses } from '../../config';
import ReservationStatusBadge from '../ReservationStatusBadge';

import './TenantReservations.css';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { entries, keys } = Object;

export default TenantPage(class TenantReservationCourseStats extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.fetchReservations();
    this.listenCourses();
    this.setInitialDateRange();
  }
  queryParams = () => {
    const { location: { search } } = this.props;
    return qs.parse(search.slice(1));
  }
  setInitialDateRange() {
    const { history, location: { pathname } } = this.props;
    const { dateRangeStart, dateRangeEnd } = this.queryParams();
    if(!isEmpty(dateRangeStart) && !isEmpty(dateRangeEnd)) return;

    history.replace(`${pathname}?${qs.stringify({
      dateRangeStart: formatDate(addDays(new Date(), 0), 'YYYY-MM-DD'),
      dateRangeEnd: formatDate(addDays(new Date(), 30), 'YYYY-MM-DD'),
    })}`);
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location !== this.props.location) {
      this.fetchReservations();
    }
  }
  fetchReservations = debounce(async () => {
    const { match: { params: { slug } } } = this.props;
    const { dateRangeStart, dateRangeEnd } = this.queryParams();
    if(isEmpty(dateRangeStart) || isEmpty(dateRangeEnd)) return;

    const reservationsRef = tenantsRef
      .doc(slug)
      .collection('reservations')
      .orderBy('startAt')
      .where('startAt', '>=', startOfDay(parseDate(dateRangeStart)))
      .where('startAt', '<=', endOfDay(parseDate(dateRangeEnd)))
    const { docs } = await reservationsRef.get();
    this.setState({
      reservations: docs.map(_ => ({ ..._.data(), id: _.id })),
    });
  }, 500)
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .onSnapshot((snapshot) => {
        this.setState({ courses: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  onChangeDate = (paramName, moment) => {
    const { history, location: { pathname } } = this.props;

    history.replace(`${pathname}?${qs.stringify({
      ...this.queryParams(),
      [paramName]: formatDate(moment.toDate(), 'YYYY-MM-DD'),
    })}`);
  }
  render() {
    const { tenant, user, match: { params: { slug } }, location } = this.props;
    const { reservations = [], courses = [], } = this.state;
    const { dateRangeStart, dateRangeEnd } = this.queryParams();
    const activeReservations = reservations.filter(_ => !_.cancelReason);
    const reservationsGroupedByCourseId = groupBy(activeReservations, 'courseId');
    const coursesById = keyBy(courses, 'id');
  
    return (
      <div className="tenant-reservation-stats">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
        <div className="container p-4 pt-5 mt-5">
          <h4 className="text-center mb-4">予約一覧</h4>
          <div className="d-flex mb-3 align-items-center justify-content-between">
            <div style={{ width: 140 }} className="ml-3 d-flex align-items-center">
              <DatePicker
                dateFormat="YYYY/MM/DD"
                withPortal
                selected={dateRangeStart && moment(dateRangeStart)}
                onChange={this.onChangeDate.bind(this, 'dateRangeStart')}
              />
              <span className="mx-2"> - </span>
              <DatePicker
                dateFormat="YYYY/MM/DD"
                withPortal
                selected={dateRangeEnd && moment(dateRangeEnd)}
                onChange={this.onChangeDate.bind(this, 'dateRangeEnd')}
              />
            </div>
          </div>
          <ReservationsHeader location={location} slug={slug} activeTab="courseStats" />
          <Table className="mt-4">
            <thead className="text-center">
              <tr>
                <th>コース</th>
                <th>価格</th>
                <th>組数</th>
                <th>人数</th>
              </tr>
            </thead>
            <tbody>
              {
                entries(reservationsGroupedByCourseId).map(([courseId, reservations]) => {
                  const course = coursesById[courseId];
                  return (
                    <tr key={courseId}>
                      <td>{course != null ? course.name : '席のみ'}</td>
                      <td className="text-right">{course != null ? numeral(course.price).format('0,0') : 0}</td>
                      <td className="text-right">{numeral(reservations.length).format('0,0')}</td>
                      <td className="text-right">{numeral(sumBy(reservations, 'peopleCount')).format('0,0')}</td>
                    </tr>
                  );
                })
              }
              <tr>
                <td colSpan={2}>合計</td>
                <td className="text-right">{numeral(activeReservations.length).format('0,0')}</td>
                <td className="text-right">{numeral(sumBy(activeReservations, 'peopleCount')).format('0,0')}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
});

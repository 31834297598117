import React from 'react';
import { Route, Switch } from 'react-router';

import AdminRoot from './components/pages/AdminRoot';
import AdminTenants from './components/pages/AdminTenants';
import AdminTenant from './components/pages/AdminTenant';
import AdminGoogleAuth from './components/pages/AdminGoogleAuth';
import AdminCustomRoutes from './components/pages/AdminCustomRoutes';
import AdminServiceSyncJobs from './components/pages/AdminServiceSyncJobs';
import TenantRoot from './components/pages/TenantRoot';
import TenantReservations from './components/pages/TenantReservations';
import TenantReservationCourseStats from './components/pages/TenantReservationCourseStats';
import TenantReservationRouteStats from './components/pages/TenantReservationRouteStats';
import TenantSchedule from './components/pages/TenantSchedule';
import TenantReservation from './components/pages/TenantReservation';
import TenantCustomers from './components/pages/TenantCustomers';
import TenantCustomer from './components/pages/TenantCustomer';
import TenantViaPhoneNotes from './components/pages/TenantViaPhoneNotes';
import TenantAdminRoot from './components/pages/TenantAdminRoot';
import TenantAdminGeneral from './components/pages/TenantAdminGeneral';
import TenantAdminContact from './components/pages/TenantAdminContact';
import TenantAdminUsers from './components/pages/TenantAdminUsers';
import TenantAdminCourses from './components/pages/TenantAdminCourses';
import TenantAdminTags from './components/pages/TenantAdminTags';
import TenantAdminTableTypes from './components/pages/TenantAdminTableTypes';
import TenantAdminTables from './components/pages/TenantAdminTables';
import TenantAdminTableGroups from './components/pages/TenantAdminTableGroups';
import TenantAdminTableCombinables from './components/pages/TenantAdminTableCombinables';
import TenantAdminCustomerTags from './components/pages/TenantAdminCustomerTags';
import TenantAdminAllergies from './components/pages/TenantAdminAllergies';
import TenantAdminHpOtherLabels from './components/pages/TenantAdminHpOtherLabels';
import TenantAdminServiceCredentials from './components/pages/TenantAdminServiceCredentials';
import TenantAdminCalendar from './components/pages/TenantAdminCalendar';
import TenantAdminPublicReservationUrl from './components/pages/TenantAdminPublicReservationUrl';
import TenantAdminRouteAliases from './components/pages/TenantAdminRouteAliases';
import TenantStats from './components/pages/TenantStats';
import TenantServiceSyncJobs from './components/pages/TenantServiceSyncJobs';
import TenantServiceMails from './components/pages/TenantServiceMails';
import PublicNewReservation from './components/pages/PublicNewReservation';
import PublicReservationConfirmation from './components/pages/PublicReservationConfirmation';
import PublicReservation from './components/pages/PublicReservation';
import ShortUrl from './components/pages/ShortUrl';
import NoMatch from './components/pages/NoMatch';

const routeObjs = [
  {
    exact: true,
    path: '/admin',
    component: AdminRoot,
  },
  {
    exact: true,
    path: '/admin/tenants',
    component: AdminTenants,
  },
  {
    exact: true,
    path: '/admin/tenants/:slug',
    component: AdminTenant,
  },
  {
    exact: true,
    path: '/admin/googleAuth',
    component: AdminGoogleAuth,
  },
  {
    exact: true,
    path: '/admin/serviceSyncJobs',
    component: AdminServiceSyncJobs,
  },
  {
    exact: true,
    path: '/admin/customRoutes',
    component: AdminCustomRoutes,
  },
  {
    exact: true,
    path: '/t/:slug',
    component: TenantRoot,
  },
  {
    exact: true,
    path: '/t/:slug/reservations',
    component: TenantReservations,
  },
  {
    exact: true,
    path: '/t/:slug/reservationCourseStats',
    component: TenantReservationCourseStats,
  },
  {
    exact: true,
    path: '/t/:slug/reservationRouteStats',
    component: TenantReservationRouteStats,
  },
  {
    exact: true,
    path: '/t/:slug/schedule',
    component: TenantSchedule,
  },
  {
    exact: true,
    path: '/t/:slug/reservations/:reservationId',
    component: TenantReservation,
  },
  {
    exact: true,
    path: '/t/:slug/viaPhoneNotes',
    component: TenantViaPhoneNotes,
  },
  {
    exact: true,
    path: '/t/:slug/customers',
    component: TenantCustomers,
  },
  {
    exact: true,
    path: '/t/:slug/customers/:customerId',
    component: TenantCustomer,
  },
  {
    exact: true,
    path: '/t/:slug/stats',
    component: TenantStats,
  },
  {
    exact: true,
    path: '/t/:slug/serviceSyncJobs',
    component: TenantServiceSyncJobs,
  },
  {
    exact: true,
    path: '/t/:slug/serviceMails',
    component: TenantServiceMails,
  },
  {
    exact: true,
    path: '/t/:slug/admin',
    component: TenantAdminRoot,
  },
  {
    exact: true,
    path: '/t/:slug/admin/general',
    component: TenantAdminGeneral,
  },
  {
    exact: true,
    path: '/t/:slug/admin/contact',
    component: TenantAdminContact,
  },
  {
    exact: true,
    path: '/t/:slug/admin/users',
    component: TenantAdminUsers,
  },
  {
    exact: true,
    path: '/t/:slug/admin/courses',
    component: TenantAdminCourses,
  },
  {
    exact: true,
    path: '/t/:slug/admin/tags',
    component: TenantAdminTags,
  },
  {
    exact: true,
    path: '/t/:slug/admin/tableTypes',
    component: TenantAdminTableTypes,
  },
  {
    exact: true,
    path: '/t/:slug/admin/tables',
    component: TenantAdminTables,
  },
  {
    exact: true,
    path: '/t/:slug/admin/tableGroups',
    component: TenantAdminTableGroups,
  },
  {
    exact: true,
    path: '/t/:slug/admin/tableCombinables',
    component: TenantAdminTableCombinables,
  },
  {
    exact: true,
    path: '/t/:slug/admin/customerTags',
    component: TenantAdminCustomerTags,
  },
  {
    exact: true,
    path: '/t/:slug/admin/allergies',
    component: TenantAdminAllergies,
  },
  {
    exact: true,
    path: '/t/:slug/admin/hpOtherLabels',
    component: TenantAdminHpOtherLabels,
  },
  {
    exact: true,
    path: '/t/:slug/admin/serviceCredentials',
    component: TenantAdminServiceCredentials,
  },
  {
    exact: true,
    path: '/t/:slug/admin/calendar',
    component: TenantAdminCalendar,
  },
  {
    exact: true,
    path: '/t/:slug/admin/publicReservationUrl',
    component: TenantAdminPublicReservationUrl,
  },
  {
    exact: true,
    path: '/t/:slug/admin/routeAliases',
    component: TenantAdminRouteAliases,
  },
  {
    exact: true,
    path: '/p/:slug/reservations/new',
    component: PublicNewReservation,
  },
  {
    exact: true,
    path: '/p/:slug/reservations/:reservationId/confirm',
    component: PublicReservationConfirmation,
  },
  {
    exact: true,
    path: '/p/reservation',
    component: PublicReservation,
  },
  {
    exact: true,
    path: '/st/:shortUrlId',
    component: ShortUrl,
  },
  {
    component: NoMatch,
  },
]

export default function routes (extraProps) {
  return (
    <Switch>
      {
        routeObjs.map((route , i) => {
          const {exact, path, render, props } = route;
          return (
            <Route exact={exact} path={path} render={render || (_ =>
              <route.component {..._} {...extraProps} {...props} />
            )} key={i} />
          );
        })
      }
    </Switch>
  );
};

const statuses = {
  initial: { label: '未完了', color: '' },
  failed: { label: '失敗', color: 'danger' },
  completed: { label: '完了', color: 'success' },
  retrying: { label: '再実行中', color: 'secondary' },
};

module.exports = {
  statuses,
};

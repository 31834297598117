import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, uniqBy, omit, debounce, omitBy, isUndefined } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Button, Badge } from 'reactstrap';

import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import TenantHeaderNav from '../TenantHeaderNav';
import CustomerFormModal from '../modals/CustomerFormModal';
import { canReadCustomers } from '../../abilities';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { keys } = Object;

export default TenantPage(class TenantCustomers extends Component {
  constructor() {
    super();
    this.state = {
      sort: {
        field: 'name',
        direction: 'asc',
      },
      limit: 20,
      isFetching: false,
      isLast: false,
    };
  }
  componentDidMount() {
    this.authorizeRole();
    this.fetchCustomers();
    this.listenCustomerTags();
    this.listenAllergies();
  }
  authorizeRole() {
    const { history, user: { role }, match: { params: { slug } } } = this.props;
    if(canReadCustomers(role)) return;
    history.replace(`/t/${slug}`);
    toast.error('Not Authorized');
  }
  componentDidUpdate(_, prevState) {
    if(prevState.sort !== this.state.sort || prevState.searchedDate !== this.state.searchedDate) {
      this.refetchCustomers();
    }
  }
  refetchCustomers() {
    this.setState({ customers: [], lastCustomerSnap: null });
    setTimeout(this.fetchCustomers);
  }
  fetchCustomers = debounce(() => {
    const { match: { params: { slug } } } = this.props;
    const { customers, sort, searchedDate, limit, lastCustomerSnap } = this.state;
    const ref = tenantsRef
      .doc(slug)
      .collection('customers')
      .orderBy(sort.field, sort.direction)
      .limit(limit);
    const withStartAfterRef = lastCustomerSnap ? ref.startAfter(lastCustomerSnap) : ref;
    this.setState({ isFetching: true });
    withStartAfterRef
      .get()
      .then((snap) => {
        const [lastCustomerSnap] = snap.docs.slice(-1);
        this.setState({
          isFetching: false,
          isLast: snap.docs.length < limit,
          lastCustomerSnap,
          customers: [...(customers || []), ...snap.docs.map(_ => _.data())],
        });
      });
  }, 300)
  listenCustomerTags() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('customerTags')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customerTags: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  listenAllergies() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('allergies')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ allergies: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  sort = (targetField) => {
    const { sort: { field, direction } } = this.state;
    this.setState({
      sort: {
        field: targetField,
        direction: targetField === field && direction === 'asc' ? 'desc' : 'asc'
      },
    });
  }
  onChangeDate = (name, value) => {
    this.setState({ [name]: value });
  }
  clearSearchdDate = () => this.setState({ searchedDate: null })
  loadMore = () => this.fetchCustomers()
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  onSubmitForm = async ({ phone, name, nameKana, gender, email, address, division, position, receiptTitle, birthday, allergy, favorites, dislikes, smoking, dm, note }) => {
    const { match: { params: { slug } } } = this.props;
    const error = await tenantsRef
      .doc(slug)
      .collection('customers')
      .doc(phone)
      .set(omitBy({ phone, name, nameKana, gender, email, address, division, position, receiptTitle, birthday, allergy, favorites, dislikes, smoking, dm, note }, isUndefined))
      .catch((e) => {
        toast.error(`失敗しました / ${e.message}`);
        return e;
      });
    if (!error) {
      this.refetchCustomers();
      toast.success('追加しました');
    }
    this.closeFormModal();
  }
  onClickAdd = () => {
    this.openFormModal();
  }
  render() {
    const { tenant, user, match: { params: { slug } } } = this.props;
    const { customers = [], customerTags = {}, allergies = {}, sort, isFetching, isLast, shouldShowFormModal } = this.state;
    return (
      <div className="tenant-customers">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} renderButtons={() =>
          <Button color="primary" onClick={this.onClickAdd}>
            <span className="fas fa-plus" />
          </Button>
        } />
        <div className="container p-4 pt-5 mt-5">
          <h4 className="text-center mb-4">顧客一覧</h4>
          <div className="d-flex mb-3 align-items-center">
            <a onClick={this.sort.bind(this, 'name')} className={classnames('mr-3', 'text-primary')}>
              名前
              <span className={classnames('fas', sort.field === 'name' && { 'fa-sort-up': sort.direction === 'asc', 'fa-sort-down': sort.direction === 'desc' })} />
            </a>
            <a onClick={this.sort.bind(this, 'phone')} className="text-primary">
              電話番号
              <span className={classnames('fas', sort.field === 'phone' && { 'fa-sort-up': sort.direction === 'asc', 'fa-sort-down': sort.direction === 'desc' })} />
            </a>
          </div>
          <hr />
          <div>
            {
              customers.map((customer) => {
                const { phone, name, nameKana } = customer;
                return (
                  <Link key={phone} className="d-block mb-3" to={`/t/${slug}/customers/${phone}`}>
                    <div className="d-flex align-items-center">
                      <div className="small">
                        <span>{phone}</span>
                      </div>
                      <div className="ml-2">
                        {
                          keys(customer.customerTags || {}).map((tagId) => {
                            const tag = customerTags[tagId];
                            return (
                              <Badge key={tagId}>
                                {tag && tag.name}
                              </Badge>
                            );
                          })
                        }
                      </div>
                    </div>
                    <div>
                      <span>{name}</span>
                      {
                        nameKana && (
                          <span className="ml-2">({nameKana})</span>
                        )
                      }
                    </div>
                  </Link>
                );
              })
            }
          </div>
          <div className="d-flex justify-content-center">
            {
              !isLast && customers.length > 0 && (
                <Button color="primary" onClick={this.loadMore} className={classnames({ disabled: isFetching })}>
                  {isFetching && <span className="fas fa-spinner fa-spin mr-1" />}
                  もっと読み込む
                </Button>
              )
            }
          </div>
        </div>
        <CustomerFormModal slug={slug} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} isNew={true} data={{ customerTags, allergies }} />
      </div>
    );
  }
});

export const texts = {
  // User Role
  admin: '管理者',
  superStaff: '管理スタッフ',
  staff: 'スタッフ',
  // Table Type
  table: 'テーブル',
  counter: 'カウンター',
  // Mode
  walkIn: 'ウォークイン',
  create: '新規',
  update: '変更',
  revoke: '取消',
};

export const routes = {
  walkIn: 'ウォークイン',
  direct: '直接',
  phone: '直電話',
  phoneGN: 'ぐるなび経由の電話',
  phoneTB: '食べログ経由の電話',
  phoneHP: 'ホットペッパー経由の電話',
  netGN: 'ぐるなびWeb',
  netTB: '食べログWeb',
  netHP: 'ホットペッパーWeb',
};

export const summaryRoutes = {
  GN: {
    name: 'ぐるなび',
    routes: ['phoneGN', 'netGN'],
  },
  TB: {
    name: '食べログ',
    routes: ['phoneTB', 'netTB'],
  },
  HP: {
    name: 'ホットペッパー',
    routes: ['phoneHP', 'netHP'],
  },
  direct: {
    name: '直電話・来店',
    routes: ['phone', 'walkIn'],
  },
};

export const colors = [
  '#f395a5',
  '#a3fdff',
  '#ffe6bd',
  '#beffc2',
  '#e6afff',
  '#6ec4c6',
  '#ffadad',
  '#e6cca5',
];

export const genders = {
  male: '男性',
  female: '女性',
  other: 'その他',
};

export const tableSmokings = {
  smokable: '喫煙',
  unsmokable: '禁煙',
  separate: '分煙',
};

export const tablePartitions = {
  open: 'オープン',
  semiPrivate: '半個室',
  private: '個室',
};

export const tableShoes = {
  shoesable: '土足可',
  unshoesable: '土足不可',
};

export const statuses = {
  initial: { name: '来店待ち', color: 'secondary' },
  reminded: { name: '来店待ち', color: 'secondary' },
  remindCancelled: { name: '来店待ち', color: 'secondary' },
  failedToRemind: { name: '来店待ち', color: 'secondary' },
  overStart: { name: '来店時刻到来済み', color: 'warning' },
  came: { name: '来店済み', color: 'info' },
  overEnd: { name: '退店時刻到来済み', color: 'warning' },
  gone: { name: '退店済み', color: 'success' },
  cancel: { name: 'キャンセル', color: 'danger' },
};

export const reminderStatuses = {
  reminded: { name: 'リマインド済み', color: 'success' },
  remindCancelled: { name: 'リマインド停止済み', color: 'secondary' },
  failedToRemind: { name: 'リマインド失敗', color: 'danger' },
};

export const types = {
  create: { name: '新規予約', color: 'info' },
  update: { name: '予約変更', color: 'warning' },
  revoke: { name: '予約取消', color: 'danger' },
  immediateCreate: { name: '即時新規予約', color: 'info' },
  immediateUpdate: { name: '即時予約変更', color: 'warning' },
  immediateRevoke: { name: '即時予約取消', color: 'danger' },
  requestCreate: { name: 'リクエスト新規予約', color: 'info' },
  requestUpdate: { name: 'リクエスト予約変更', color: 'warning' },
  requestRevoke: { name: 'リクエスト予約取消', color: 'danger' },
};

export const services = {
  GN: 'ぐるなび',
  HP: 'ホットペッパー',
  TB: '食べログ',
};

export const cancelReasons = {
  customer: 'お客様都合',
  tenant: 'お店都合',
};

export const viaPhoneNoteStatuses = {
  initial: '未解決',
  resolved: '解決済み',
};


import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';

import MessageForm from '../hocs/MessageForm';

export default MessageForm(class ReservationName extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { name, nameKana } = this.state;
    onAction({ type: 'submitReservationName', payload: { name, nameKana } });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['nameKana'].some(_ => !validations[_]);
  }
  render() {
    const { isActive } = this.props;
    const { name, nameKana } = this.state;
    return (
      <div className="reservation-name">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>お客様のお名前をフルネームで入力してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input value={nameKana} placeholder="[必須] やまだ たろう" onChange={this.onChangeText.bind(this, 'nameKana')} className={this.validationCss('nameKana')} disabled={!isActive} />
                </td>
              </tr>
              <tr>
                <td>
                  <Input value={name} placeholder="[任意] 山田 太郎" onChange={this.onChangeText.bind(this, 'name')} className={this.validationCss('name')} disabled={!isActive} />
                </td>
              </tr>
              <tr>
                <td>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { omit, keyBy, } from 'lodash';
import { useToggle, } from 'react-use';

import firebase, { config } from '../../firebase';
import TableFormModal from '../modals/TableFormModal';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';

import TenantAdminPage from '../hocs/TenantAdminPage';
import { texts, tableSmokings, tablePartitions, tableShoes } from '../../config';

const auth = firebase.auth();
const db = firebase.firestore();
const adminUsersRef = db.collection('adminUsers');
const tenantsRef = db.collection('tenants');
const { entries } = Object;

export default TenantAdminPage(function TenantAdminTables (props) {
  const { user, match: { params: { slug } } } = props;
  const [shouldShowFormModal, toggleFormModal] = useToggle(false);
  const tablesRef = tenantsRef.doc(slug).collection('tables');
  const adminUser = useDocumentSubscription(adminUsersRef.doc(user.uid), [user]);
  const tables = useCollectionSubscription(tablesRef.orderBy('createdAt'), [slug]);
  const tableTypes = useCollectionSubscription(tenantsRef.doc(slug).collection('tableTypes').orderBy('createdAt'), [slug]);
  const tableTypesById = keyBy(tableTypes, 'id');
  const processRow = (batch, row) => {
    batch.set(tablesRef.doc(row.id), {
      ...omit(row, 'id'),
      capacity: parseInt(row.capacity, 10) || 0,
      createdAt: new Date(),
    });
  };
  const validateOnAdd = async (values) => {
    const tableIds = tables.map(_ => _.id);
    if(tableIds.includes(values.name)) {
      toast.error('すでに存在します。');
      return false;
    } else {
      return true;
    }
  };
  const beforeImport = async (rows) => {
    const tableIds = tables.map(_ => _.id);
    if(rows.some(_ => tableIds.includes(_.id))) {
      toast.error('すでに存在します。');
      throw new Error('exists');
    }
  };
  const rowsForExport = () => {
    return tables.map((table) => {
      return {
        ...omit(table, ['ref', 'createdAt']),
      };
    });
  };

  return (
    <div className="tenant-admin-tables">
      <div className="d-flex justify-content-center mb-3">
        <h4>テーブル設定</h4>
      </div>
      <div className="d-flex justify-content-end">
        {
          adminUser != null && (
            <Fragment>
              <ImportButton processRow={processRow} beforeSave={beforeImport} />
              <ExportButton className="ml-2" fileName="テーブル.csv" rows={rowsForExport} />
            </Fragment>
          )
        }
        <AddButton className="ml-2" validateOnSubmit={validateOnAdd} itemRef={_ => tablesRef.doc(_.name)} FormModal={TableFormModal} formProps={{ tableTypes, }} />
      </div>
      <table className="table mt-5">
        <thead>
          <tr>
            <th>テーブル名</th>
            <th>タイプ</th>
            <th>定員</th>
            <th>HPセット名</th>
            <th>GNセット名</th>
            <th>TBセット名</th>
            <th>喫煙可</th>
            <th>間仕切り</th>
            <th>土足可</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            (tables || []).map(({ id, ref, name, tableTypeId, capacity, nameHP, nameGN, nameTB, smoking, partition, shoe }) => {
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td>
                    {(tableTypesById[tableTypeId] || {}).name}
                  </td>
                  <td>
                    {capacity}
                  </td>
                  <td>
                    {nameHP}
                  </td>
                  <td>
                    {nameGN}
                  </td>
                  <td>
                    {nameTB}
                  </td>
                  <td>
                    {tableSmokings[smoking] || '(未設定)'}
                  </td>
                  <td>
                    {tablePartitions[partition] || '(未設定)'}
                  </td>
                  <td>
                    {tableShoes[shoe] || '(未設定)'}
                  </td>
                  <td className="text-nowrap">
                    <EditButton itemRef={ref} FormModal={TableFormModal} formProps={{ tableTypes, }} />
                    <DeleteButton itemRef={ref} className="ml-2" />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
});

import React from 'react';
import { Badge } from 'reactstrap';
import { statuses, reminderStatuses } from '../config';

export default function({ reservation: { cancelCharge, stripeCustomer, cancelProtectionEnabled = false, }, ...options }) {
  const status = !cancelProtectionEnabled ? 'disabled'
    : stripeCustomer == null ? 'waitingToCreateCustomer'
    : cancelCharge == null ? 'customerCreated'
    : 'cancelCharged';
  const { color, label } = ({
    waitingToCreateCustomer: {
      label: '決済情報登録待ち',
      color: 'warning'
    },
    customerCreated: {
      label: '決済情報登録済み',
      color: 'success'
    },
    cancelCharged: {
      label: 'キャンセル料徴収済み',
      color: 'success'
    },
  })[status] || {};

  return cancelProtectionEnabled && (
    <Badge pill color={color} {...options}>
      {label}
    </Badge>
  );
};

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

export default class App extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.router.history.listen(({ pathname, search }) => {
      if(!window.gtag) return;

      window.gtag('config', 'G-0736LL99CT', {
        page_path: pathname + search,
      });
    });
  }
  render() {
    return (
      <div className="root">
        <BrowserRouter ref={_ => this.router = _}>
          {this.props.routes()}
        </BrowserRouter>
        <ToastContainer />
      </div>
    );
  }
};

import React  from 'react';
import { Table, Button } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { Link } from 'react-router-dom';

import { texts, routes, cancelReasons } from '../../config';
import { routeLabel, } from '../../shared/util';
import TableDisplay from '../TableDisplay';

const { keys } = Object;

export default function Fixed ({ slug, onAction, payload: { selectedId, route, startAt, endAt, peopleCount, tableIds, phone, name, nameKana, courseId, tagIds, privateNote, cancelReason, mode }, data: { tenant, customRoutes, courses, tags, tables } }){
  return (
    <div className="fixed">
      <Table>
        <thead>
          <tr>
            <th colSpan={2}>{texts[mode]}登録しました</th>
          </tr>
        </thead>
        <tbody>
          {
            selectedId != null && (
              <tr>
                <td>
                  予約ID
                </td>
                <td>
                  <Link target="_blank" to={`/t/${slug}/reservations/${selectedId}`}>
                    {selectedId}
                    <span className="ml-1 fas fa-external-link-alt" />
                  </Link>
                </td>
              </tr>
            )
          }
          <tr>
            <td>
              開始日時
            </td>
            <td>
              {formatDate(startAt, 'YYYY/MM/DD HH:mm')}
            </td>
          </tr>
          <tr>
            <td>
              終了日時
            </td>
            <td>
              {formatDate(endAt, 'YYYY/MM/DD HH:mm')}
            </td>
          </tr>
          <tr>
            <td>
              人数
            </td>
            <td>
              {peopleCount}
            </td>
          </tr>
          <tr>
            <td>
              テーブル
            </td>
            <td>
              {
                keys(tableIds || {})
                  .map(tableId => tables.find(_ => _.id === tableId))
                  .map((table) => {
                    return (
                      <div key={table.id}>
                        <TableDisplay table={table} />
                      </div>
                    );
                  })
              }
            </td>
          </tr>
          <tr>
            <td>
              電話番号
            </td>
            <td>
              {phone}
            </td>
          </tr>
          <tr>
            <td>
              お名前
            </td>
            <td>
              <div>
                {nameKana}
              </div>
              <div>
                {name}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              コース
            </td>
            <td>
              {(courses.find(_ => _.id === courseId) || {}).name || '席のみ'}
            </td>
          </tr>
          <tr>
            <td>
              予約タグ
            </td>
            <td>
              {
                keys(tagIds).map(tagId => tags.find(_ => _.id === tagId)).map(_ => _.name).join(', ')
              }
            </td>
          </tr>
          <tr>
            <td>
              店舗メモ
            </td>
            <td>
              {privateNote}
            </td>
          </tr>
          {
            mode === 'create' && (
              <tr>
                <td>
                  経路
                </td>
                <td>
                  {routeLabel(route, routes, customRoutes, tenant.routeAliases)}
                </td>
              </tr>
            )
          }
          {
            cancelReason && (
              <tr>
                <td>
                  取消理由
                </td>
                <td>
                  {cancelReasons[cancelReason]}
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  )
};

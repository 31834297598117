module.exports = {
  validations: {
    general: {
      required: '必須項目です',
      greaterThanOrEqualTo0: '0以上で指定してください。',
      samePassword: 'パスワードと一致しません',
      format: '形式が正しくありません',
      inRange: '範囲内で指定してください',
      greaterThanStart: '開始時刻以降で指定してください',
      exclusive: '他と重なっています',
      hiragana: 'ひらがなで入力してください',
      emailFormat: 'メールアドレスの形式が正しくありません',
    },
  },
};

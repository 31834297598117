import React, { Fragment } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { useToggle } from 'react-use';
import { Link } from 'react-router-dom';
import { get, keyBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { addHours, format as formatDate } from 'date-fns';
import classnames from 'classnames';

import firebase from '../../firebase';
import { statuses } from '../../shared/models/serviceMail';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import TenantPage from '../hocs/TenantPage';
import EditButton from '../EditButton';
import TenantRouteAliasesFormModal from '../modals/TenantRouteAliasesFormModal';
import TenantHeaderNav from '../TenantHeaderNav';
import { routes, services, } from '../../config';
import { routeLabel, } from '../../shared/util';
import { initialRoute } from '../../utils';

const { entries } = Object;

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default TenantPage(function TenantServiceMails (props) {
  const { tenant, user, match: { params: { slug } } } = props;
  const serviceMails = useCollectionSubscription(tenantsRef.doc(slug).collection('serviceMails').orderBy('createdAt', 'desc').limit(100), [slug]);
  const receptions = useDocumentsFetch(serviceMails.map(_ => tenantsRef.doc(slug).collection('receptions').doc(_.receptionId)), [serviceMails]);
  const receptionsById = keyBy(receptions, 'id');
  const reservations = useCollectionsFetch(serviceMails.map(_ => tenantsRef.doc(slug).collection('reservations').where('initialReceptionId', '==', _.receptionId)), [serviceMails]);
  const reservationsByReceptionId = keyBy(reservations, 'initialReceptionId');

  return (
    <div className="tenant-service-mails">
      <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
      <div className="container p-4 pt-5 mt-5">
        <h4>予約メール一覧</h4>
        <div className="mt-3">
          <table className="table" style={{ maxWidth: '100%' }}>
            <thead className="thead-light text-nowrap">
              <tr>
                <th style={{ minWidth: 80 }}>状態</th>
                <th>サービス</th>
                <th>受信日時</th>
                <th style={{ minWidth: 150 }}>タイトル</th>
                <th className="d-none d-sm-table-cell">本文</th>
                <th style={{ minWidth: 120 }}>予約</th>
              </tr>
            </thead>
            <tbody>
              {
                serviceMails.map((serviceMail) => {
                  const { id, ref, receptionId, service, errorMessage, createdAt, receivedAt, status, subject, text = '', } = serviceMail;
                  const { label: statusLabel, color, } = statuses[status];
                  const reception = receptionsById[receptionId];
                  const reservation = reservationsByReceptionId[receptionId];

                  return (
                    <tr key={id} className={classnames(`table-${color}`)}>
                      <td>
                        <div>{statusLabel}</div>
                        {get(reception, 'isInsufficient', false) && <div className="small text-danger">[要確認]</div>}
                      </td>
                      <td>{services[service]}</td>
                      <td>{formatDate((receivedAt || createdAt).toDate(), 'YYYY/MM/DD HH:mm')}</td>
                      <td>
                        <SubjectLink serviceMail={serviceMail} statusLabel={statusLabel} reception={reception} />
                      </td>
                      <td className="d-none d-sm-table-cell" title={text}>
                        {text.slice(0, 150)}...
                      </td>
                      <td title={text}>
                        {
                          reservation != null && (
                            <Link to={`/t/${slug}/reservations/${reservation.id}`} target="_blank">
                              予約詳細
                              <span className="ml-1 fas fa-external-link-alt" />
                            </Link>
                          )
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

function SubjectLink (props) {
  const { serviceMail, statusLabel, reception, } = props;
  const { id, ref, receptionId, service, errorMessage, createdAt, receivedAt, status, subject, text = '', } = serviceMail;
  const [showsModal, toggleModal] = useToggle(false);
  const onClickSubject = (e) => {
    e.preventDefault();
    toggleModal(true);
  };

  return (
    <Fragment>
      <a href="" onClick={onClickSubject}>
        {subject}
      </a>
      {
        showsModal && (
          <Modal isOpen>
            <ModalHeader>
              メール詳細
            </ModalHeader>
            <ModalBody>
              <div className="mb-4">
                <div className="text-muted small">状態</div>
                <div>{statusLabel}</div>
                {get(reception, 'isInsufficient', false) && <div className="small text-danger">[要確認]</div>}
              </div>
              <div className="mb-4">
                <div className="text-muted small">サービス</div>
                {services[service]}
              </div>
              <div className="mb-4">
                <div className="text-muted small">受信日時</div>
                {formatDate((receivedAt || createdAt).toDate(), 'YYYY/MM/DD HH:mm')}
              </div>
              <div className="mb-4">
                <div className="text-muted small">タイトル</div>
                {subject}
              </div>
              <div className="mb-4">
                <div className="text-muted small">本文</div>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {text}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal.bind(null, false)}>Close</Button>
            </ModalFooter>
          </Modal>
        )
      }
    </Fragment>
  );
}

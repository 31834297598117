import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import firebase, { config } from '../../firebase';
import initAuth2 from '../../googleApi';
import TenantAdminPage from '../hocs/TenantAdminPage';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { gapi } = window;

export default TenantAdminPage(function TenantAdminPublicReservationUrl (props) {
  const { match: { params: { slug } } } = props;
  const url = `${window.location.origin}/p/${slug}/reservations/new`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };

  return (
    <div className="tenant-admin-calendar">
      <div className="d-flex justify-content-center mb-3">
        <h4>自店舗予約受付用画面URL</h4>
      </div>
      <div className="d-flex">
        <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
        <Button color="primary" onClick={onClickCopy}>
          <span className="fas fa-copy" />
        </Button>
      </div>
    </div>
  );
});

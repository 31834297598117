import React from 'react';
import { tableSmokings, tablePartitions, tableShoes } from '../config';

export default function TableDisplay({ table: { name, capacity, smoking, partition, shoe } }) {
  const info = [tableSmokings[smoking], tablePartitions[partition], tableShoes[shoe]].filter(_ => _).join('/');
  return (
    <span>
      {name} ({capacity}席{info ? ` ${info}` : ''})
    </span>
  );
};

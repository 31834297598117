import React, { Component, Fragment } from 'react';
import { Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';
import { format as formatDate, addMinutes } from 'date-fns';
import classnames from 'classnames';
import { sum, keyBy } from 'lodash';

import MessageForm from '../hocs/MessageForm';
import TableDisplay from '../TableDisplay';

const { keys, } = Object;

export default MessageForm(class ReservationTable extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { tableIds } = this.state;
    onAction({ type: 'submitReservationTables', payload: { tableIds: keys(tableIds || {}).length > 0 ? tableIds : null } });
  }
  isUnsubmittable() {
    return false;
  }
  render() {
    const { isActive, payload: { selectedId, reservations, startAt, endAt, tenant: { marginDuration = 0 } }, data: { tables, courses, waitings = [] } } = this.props;
    const tablesById = keyBy(tables, 'id');
    const { tableIds, peopleCount } = this.state;
    const coursesById = keyBy(courses, 'id');
    const activeWaitings = waitings.filter(_ => !_.cancelReason).filter(_ => formatDate(_.startAt.toDate(), 'YYYYMMDD') === formatDate(startAt, 'YYYYMMDD')).filter(_ => _.id !== selectedId);
    return (
      <div className="reservation-table">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>
                  テーブルを選択してください。<br />
                  結合する場合は複数選択してください。
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="alert alert-info small">
                    <div className="alert-heading mr-1">[ウェイティング一覧]</div>
                    {
                      activeWaitings.length > 0 ? (
                        <ul className="pl-3 mb-0">
                          {
                            activeWaitings.map(({ startAt, endAt, nameKana, peopleCount, courseId }, i) => {
                              const { price = '席のみ' } = coursesById[courseId] || {}
                              return (
                                <li key={i}>
                                  {nameKana} {peopleCount}名 {formatDate(startAt.toDate(), 'HH:mm')}-{formatDate(endAt.toDate(), 'HH:mm')} ¥{price}
                                </li>
                              );
                            })
                          }
                        </ul>
                      ) : (
                        <span>ウェイティングはありません</span>
                      )
                    }
                  </div>
                  {
                    (tables || []).map((table) => {
                      const { id } = table;
                      const conflictedReservations =
                        reservations.filter(_ => (_.tableIds || {})[id]).filter((reservation) => {
                          return startAt < addMinutes(reservation.endAt.toDate(), marginDuration) && addMinutes(endAt, marginDuration) > reservation.startAt.toDate();
                        });
                      return (
                        <FormGroup check key={id}>
                          <Label check>
                            <Input className="mr-1" type="checkbox" value={id} onChange={this.onCheck.bind(this, 'tableIds', id)} checked={tableIds && tableIds[id]} disabled={!isActive} />
                            <span className={classnames({ 'text-very-muted': conflictedReservations.length > 0 })}>
                              <Fragment>
                                <TableDisplay table={table} />
                                {
                                  conflictedReservations.length > 0 ? (
                                    conflictedReservations.map(({ startAt, endAt, nameKana, peopleCount, courseId }) => {
                                      const { price = '席のみ' } = coursesById[courseId] || {}
                                      return `${nameKana} ${peopleCount}名 ${formatDate(startAt.toDate(), 'HH:mm')}-${formatDate(endAt.toDate(), 'HH:mm')} ¥${price}`
                                    }).join(', ')
                                  ) : (
                                    <span>空き</span>
                                  )
                                }
                              </Fragment>
                            </span>
                          </Label>
                        </FormGroup>
                      );
                    })
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <div className="mb-1">
                    <span className="font-weight-bold">
                      {sum(keys(tableIds || {}).map(_ => tablesById[_]).map(_ => _.capacity))}
                    </span>
                    <span className="small">
                      席確保
                    </span>
                    &nbsp;-&nbsp;
                    <span className="small">
                      今回の人数：
                    </span>
                    <span className="font-weight-bold">
                      {peopleCount}
                    </span>
                  </div>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

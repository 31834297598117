import React, { Component } from 'react';
import { Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';
import { format as formatDate } from 'date-fns';

import MessageForm from '../hocs/MessageForm';

const { keys } = Object;

export default MessageForm(class OverEnds extends Component {
  onClickGone = (reservationId) => {
    if(!window.confirm('本当に退店済みにしますか？')) return;
    const { onAction } = this.props;
    onAction({ type: 'updateStatus', payload: { status: 'gone', reservationId, nextType: 'viewOverEnds' } });
  }
  onClickEdit = (reservationId) => {
    const { onAction } = this.props;
    onAction({ type: 'editSpecifiedReservation', payload: { reservationId, locksAllocation: true } });
  }
  render() {
    const { onAction, payload: { overEnds }, data: { tables }, isActive } = this.props;
    return (
      <div className="over-ends">
        {
          overEnds.length > 0 ? (
            <Table>
              <tbody>
                {
                  overEnds.map(({ id, startAt, endAt, peopleCount, nameKana, tableIds }) => {
                    return (
                      <tr key={id}>
                        <td>
                          <div className="small">
                            <span className="text-muted">[{id}]</span>
                          </div>
                          <div>
                            {formatDate(startAt.toDate(), 'MM/DD HH:mm')} - {formatDate(endAt.toDate(), 'HH:mm')} {keys(tableIds || {}).map(id => tables.find(_ => _.id === id).name).join(', ')}
                            <br />
                            {nameKana} {peopleCount}名様
                          </div>
                          <div className="mt-1 d-flex justify-content-around">
                            <Button color="success" size="sm" onClick={this.onClickGone.bind(this, id)} disabled={!isActive}>
                              退店済み
                            </Button>
                            <Button color="default" size="sm" onClick={this.onClickEdit.bind(this, id)} disabled={!isActive}>
                              変更
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          ) : (
            <div>来店時刻到来済み予約はありません</div>
          )
        }
        <div className="mt-2">
          <Button color="primary" onClick={() => onAction({ type: 'toHome' })} disabled={!isActive}>
            終了
          </Button>
        </div>
      </div>
    );
  }
});

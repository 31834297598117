import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { format as formatDate, parse as parseDate, startOfDay, } from 'date-fns';
import { useToggle, useAsync } from 'react-use';

import firebase, { functions } from '../../firebase';
import PublicPage from '../hocs/PublicPage';
import PublicTenantBlock from '../PublicTenantBlock';
import ReservationForm from '../forms/ReservationForm';

const getPublicTenant = functions.httpsCallable('getPublicTenant');

export default PublicPage(function PublicNewReservation(props) {
  const { match: { params: { slug: tenantId } } } = props;
  const [isSubmitted, toggleSubmitted] = useToggle(false);
  const { value: tenant } = useAsync(async () => {
    const { data: tenant } = await getPublicTenant({ tenantId });
    return tenant;
  }, [tenantId]);

  return tenant != null && (
    <div className="container py-3">
      <PublicTenantBlock tenant={tenant} />
      <div className="mt-4 row">
        <div className="col-md-6 offset-md-3">
          <div className="card p-3">
            <div>
              {
                !isSubmitted ? (
                  <ReservationForm {...{ tenantId, tenant, }} onSubmitted={toggleSubmitted.bind(null, true)} />
                ) : (
                  <div className="mt-3">
                    <div className="mt-4">
                      <p>
                        予約申し込みありがとうございました。
                      </p>
                      <p>
                        ただいま予約申し込み確認メールをお送りしました。
                        <br />
                        <span className="font-weight-bold">予約申し込みは、まだ完了しておりません。</span>
                      </p>
                      <p>
                        そちらのメールに記載の内容に沿って予約申し込み確認をお済ませください。
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


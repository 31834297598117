import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input } from 'reactstrap';

import firebase from '../../firebase';

const auth = firebase.auth();
const db = firebase.firestore();

export default function PublicPageHOC(WrappedComponent) {
  return function PublicPage (props) {
    return (
      <div className="public-page" style={{ backgroundColor: '#eee', minHeight: '100vh' }}>
        <WrappedComponent {...props} />
      </div>
    );
  }
};


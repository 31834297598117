import React, { Component } from 'react';
import { Badge, Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';
import { format as formatDate } from 'date-fns';

import MessageForm from '../hocs/MessageForm';

const { keys } = Object;

export default MessageForm(class Waitings extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction, payload: { waitings } } = this.props;
    const { selectedId } = this.state;
    onAction({ type: 'submitReservationSelection', payload: { selectedReservationId: selectedId, reservations: waitings } });
  }
  isUnsubmittable() {
    return !this.state.selectedId;
  }
  render() {
    const { payload: { waitings }, data: { tables }, isActive } = this.props;
    const { selectedId } = this.state;
    return (
      <div className="reservation-selection">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th colSpan="2">
                  予約を選択してください。
                </th>
              </tr>
            </thead>
            <tbody>
              {
                waitings.filter(_ => !_.cancelReason).map(({ type, id, startAt, endAt, peopleCount, nameKana, tableIds }) => {
                  return (
                    <tr key={id}>
                      <td>
                        <FormGroup check inline className="mr-0">
                          <Input type="radio" name="selectedId" checked={selectedId === id} onChange={this.onChoose.bind(this, 'selectedId', id)} disabled={!isActive} />
                        </FormGroup>
                      </td>
                      <td>
                        <div className="small">
                          <span className="text-muted mr-1">[{id}]</span>
                          {
                            type === 'requestCreate' && (
                              <Badge color="info">
                                リクエスト予約
                              </Badge>
                            )
                          }
                        </div>
                        {formatDate(startAt.toDate(), 'MM/DD HH:mm')} - {formatDate(endAt.toDate(), 'HH:mm')} {keys(tableIds || {}).map(id => tables.find(_ => _.id === id).name).join(', ')}
                        <br />
                        {nameKana} {peopleCount}名様
                      </td>
                    </tr>
                  );
                })
              }
              <tr>
                <td colSpan="2">
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

import React from 'react';
import { format as formatDate, setHours, startOfHour, endOfDay, differenceInMinutes } from 'date-fns';
import { useDrag } from 'react-dnd';

export default function ReservationBox({ onMoveEnd, startHours, reservation, style, onClick, table, prevReservations, index, }) {
  const { id, startAt, endAt, nameKana, peopleCount, route, versions = [] } = reservation;
  const content = `${nameKana}様 ${peopleCount}名`;
  const [, dragRef] = useDrag({
    item: { reservation, type: 'ReservationBox', table },
    end: (item, monitor) => {
      const { reservation, table: sourceTable } = item;
      const { date, table: targetTable } = monitor.getDropResult();
      onMoveEnd({ reservation, date, sourceTable, targetTable });
    },
  });
  const conflictedReservations = prevReservations.filter(_ => _.startAt.isEqual(startAt));

  return (
    <div ref={dragRef} className="border border-dark cursor-pointer text-nowrap d-flex flex-column justify-content-center p-2 small reservation-timebar rounded position-absolute" style={{ ...style, top: style.top + conflictedReservations.length * 5 }} onClick={onClick}>
      <div className="text-truncate" title={content}>
        <span>{content}</span>
      </div>
    </div>
  );
};

import React from 'react';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import { keyBy, omit } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import AdminPage from '../hocs/AdminPage';
import CustomRouteFormModal from '../modals/CustomRouteFormModal';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';

const db = firebase.firestore();
const customRoutesRef = db.collection('customRoutes');

export default AdminPage(function CustomRoutes (props) {
  const [showsFormModal, toggleFormModal] = useToggle();
  const customRoutes = useCollectionSubscription(customRoutesRef.orderBy('createdAt'));
  const onSubmitForm = async (values) => {
    try {
      await customRoutesRef.doc(values.id).set({ ...omit(values, ['id', 'ref']), createdAt: new Date() });
      toast.success('追加しました');
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
    toggleFormModal();
  };

  return (
    <div className="custom-routes container">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>カスタム経路</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <Button className="ml-2" color="primary" onClick={toggleFormModal}>
            <span className="fas fa-plus mr-1" />
            追加
          </Button>
        </div>
        <div>
          {
            customRoutes.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>ID</th>
                    <th>表示名</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    customRoutes.map((customRoute) => {
                      const { id, ref, label, createdAt } = customRoute;
                      return (
                        <tr key={id}>
                          <td>
                            {id}
                          </td>
                          <td>
                            {label}
                          </td>
                          <td className="text-right">
                            <EditButton itemRef={ref} FormModal={CustomRouteFormModal} />
                            <DeleteButton item={customRoute} itemRef={ref} className="ml-2" />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                カスタム経路は未登録です
              </div>
            )
          }
        </div>
        {showsFormModal && <CustomRouteFormModal isOpen onClickClose={toggleFormModal} onSubmit={onSubmitForm} />}
      </div>
    </div>
  );
});


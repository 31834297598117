import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { pick, omit, debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classnames from 'classnames';

import firebase from '../../firebase';
import dateToTime from '../../dateToTime';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const DURATION_MIN = 0;
const DURATION_MAX = 600;

const fields = ['lunchStart', 'lunchEnd', 'dinnerStart', 'dinnerEnd', 'lunchDuration', 'dinnerDuration', 'otherDuration', 'marginDuration', 'rbOtherServiceMailIntegrationEnabled', 'tableOnlyPrice'];

export default class TenantFormModal extends Component {
  constructor() {
    super();
    this.state = {
      validations: {},
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      const dateFields = ['lunchStart', 'lunchEnd', 'dinnerStart', 'dinnerEnd'];
      dateFields.forEach(name => {
        const value = this.props.tenant[name];
        this.setState({ [name]: value && value.toDate() });
      });
      this.setState({ ...omit(this.props.tenant, dateFields) });
      fields.forEach(_ => setTimeout(this.validate.bind(this, _), 0));
    }
    fields.forEach((name) => {
      if(prevState[name] !== this.state[name]) {
        this.validate(name);
      }
    });
  }
  validate = (name) => {
    const { validations } = this.state;
    const value = this.state[name];
    let isValid;
    switch (name) {
      case 'lunchStart': 
      case 'lunchEnd': 
        isValid = this.state.lunchStart < this.state.lunchEnd;
        this.setState({ validations: { ...validations, lunchStart: isValid, lunchEnd: isValid } });
        break;
      case 'dinnerStart': 
      case 'dinnerEnd': 
        isValid = this.state.dinnerStart < this.state.dinnerEnd;
        this.setState({ validations: { ...validations, dinnerStart: isValid, dinnerEnd: isValid } });
        break;
      case 'lunchDuration':
      case 'dinnerDuration':
      case 'otherDuration':
      case 'marginDuration':
        this.setState({ validations: { ...validations, [name]: (value >= DURATION_MIN && value <= DURATION_MAX) } });
        break
      case 'tableOnlyPrice':
        this.setState({ validations: { ...validations, [name]: value >= 0, } });
        break
      default:
        this.setState({ validations: { ...validations, [name]: true } });
    }
  }
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    onSubmit({ ...pick(this.state, fields) });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return fields.some(_ => !validations[_]);
  }
  onChangeTime = (name, value) => {
    this.setState({ [name]: dateToTime(value.toDate()) });
  }
  onChangeNumber = (name, { target: { value } }) => {
    this.setState({ [name]: parseFloat(value, 10) || null });
  }
  onCheck = (name, { target: { checked: value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  render() {
    const { isOpen, onClickClose } = this.props;
    const { lunchStart, lunchEnd, dinnerStart, dinnerEnd, lunchDuration, dinnerDuration, otherDuration, marginDuration, rbOtherServiceMailIntegrationEnabled = false, tableOnlyPrice, } = this.state;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          一般設定編集
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>ランチ時間帯</Label>
              <div className="d-flex align-items-center">
                <DatePicker
                  className={classnames('form-control', 'time-only', this.validationCss('lunchStart'))}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  selected={lunchStart && moment(lunchStart)}
                  onChange={this.onChangeTime.bind(this, 'lunchStart')}
                />
                <span className="mx-3">-</span>
                <DatePicker
                  className={classnames('form-control', 'time-only', this.validationCss('lunchEnd'))}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  selected={lunchEnd && moment(lunchEnd)}
                  onChange={this.onChangeTime.bind(this, 'lunchEnd')}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label>ディナー時間帯</Label>
              <div className="d-flex align-items-center">
                <DatePicker
                  className={classnames('form-control', 'time-only', this.validationCss('dinnerStart'))}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  selected={dinnerStart && moment(dinnerStart)}
                  onChange={this.onChangeTime.bind(this, 'dinnerStart')}
                />
                <span className="mx-3">-</span>
                <DatePicker
                  className={classnames('form-control', 'time-only', this.validationCss('dinnerEnd'))}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  selected={dinnerEnd && moment(dinnerEnd)}
                  onChange={this.onChangeTime.bind(this, 'dinnerEnd')}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label>ランチ確保時間</Label>
              <InputGroup style={{ width: 130 }}>
                <Input type="number" value={lunchDuration} onChange={this.onChangeNumber.bind(this, 'lunchDuration')} className={this.validationCss('lunchDuration')} min={DURATION_MIN} max={DURATION_MAX} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>分</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>ディナー確保時間</Label>
              <InputGroup style={{ width: 130 }}>
                <Input type="number" value={dinnerDuration} onChange={this.onChangeNumber.bind(this, 'dinnerDuration')} className={this.validationCss('dinnerDuration')} min={DURATION_MIN} max={DURATION_MAX} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>分</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>その他確保時間</Label>
              <InputGroup style={{ width: 130 }}>
                <Input type="number" value={otherDuration} onChange={this.onChangeNumber.bind(this, 'otherDuration')} className={this.validationCss('otherDuration')} min={DURATION_MIN} max={DURATION_MAX} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>分</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>リセット時間</Label>
              <InputGroup style={{ width: 130 }}>
                <Input type="number" value={marginDuration} onChange={this.onChangeNumber.bind(this, 'marginDuration')} className={this.validationCss('marginDuration')} min={DURATION_MIN} max={DURATION_MAX} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>分</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>レストランボード グルメサイトメール取り込みの設定</Label>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={rbOtherServiceMailIntegrationEnabled} onChange={this.onCheck.bind(this, 'rbOtherServiceMailIntegrationEnabled')} className={this.validationCss('rbOtherServiceMailIntegrationEnabled')} />
                  レストランボードにてONにしている
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label>席のみ料金</Label>
              <InputGroup style={{ width: 130 }}>
                <Input type="number" value={tableOnlyPrice} onChange={this.onChangeNumber.bind(this, 'tableOnlyPrice')} className={this.validationCss('tableOnlyPrice')} min={DURATION_MIN} max={DURATION_MAX} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>円</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};


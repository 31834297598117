import React, { Component, Fragment } from 'react';
import { Button, Card, CardBody, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { sortBy, uniqBy, omit, debounce } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import TenantHeaderNav from '../TenantHeaderNav';
import ReservationListItem from '../ReservationListItem';
import CustomerFormModal from '../modals/CustomerFormModal';
import { canReadCustomers } from '../../abilities';
import { genders } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { keys } = Object;

export default TenantPage(class TenantCustomers extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.authorizeRole();
    this.listenCustomer();
    this.listenReservations();
    this.listenTables();
    this.listenCourses();
    this.listenCustomerTags();
    this.listenAllergies();
  }
  authorizeRole() {
    const { history, user: { role }, match: { params: { slug } } } = this.props;
    if(canReadCustomers(role)) return;
    history.replace(`/t/${slug}`);
    toast.error('Not Authorized');
  }
  listenCustomer() {
    const { match: { params: { slug, customerId } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('customers')
      .doc(customerId)
      .onSnapshot((snap) => {
        this.setState({ customer: snap.data() });
      });
  }
  listenReservations() {
    const { match: { params: { slug, customerId } } } = this.props;
    if(customerId === '08000000000') return;
    tenantsRef
      .doc(slug)
      .collection('reservations')
      .where('phone', '==', customerId)
      .onSnapshot((snap) => {
        this.setState({
          reservations: snap.docs.map(_ => ({ ..._.data(), id: _.id })),
        });
      });
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ tables: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y}), {}) });
      });
  }
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .onSnapshot((snapshot) => {
        this.setState({ courses: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  listenCustomerTags() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('customerTags')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customerTags: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  listenAllergies() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('allergies')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ allergies: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })).reduce((x, y) => ({ ...x, [y.id]: y }), {}) });
      });
  }
  onClickDelete = async () => {
    const { history, match: { params: { slug, customerId } } } = this.props;
    if (!window.confirm('本当に削除しますか？')) return;
    await tenantsRef
      .doc(slug)
      .collection('customers')
      .doc(customerId)
      .delete();
    toast.success('削除しました');
    history.push(`/t/${slug}/customers`);
  }
  onClickEdit = () => {
    const { customer } = this.state;
    this.setState({ targetCustomerValues: { ...customer, birthday: (customer.birthday && customer.birthday.toDate()) } });
    this.openFormModal();
  }
  onSubmitForm = async ({ nameKana, name, phone, customerTags, gender, email, birthday, allergies, favorites, dislikes, smoking }) => {
    const { history, match: { params: { slug, customerId } } } = this.props;
    const error = await tenantsRef
      .doc(slug)
      .collection('customers')
      .doc(phone)
      .set({ nameKana, name, phone, customerTags, gender, email, birthday, allergies, favorites, dislikes, smoking })
      .catch((e) => {
        toast.error(`失敗しました / ${e.message}`);
        return e;
      });
    if (!error) {
      if (customerId !== phone) {
        await tenantsRef
          .doc(slug)
          .collection('customers')
          .doc(customerId)
          .delete();
        history.replace(`/t/${slug}/customers`);
      }
      toast.success('更新しました');
    }
    this.closeFormModal();
  }
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  formatNothing = _ => _ || <span className="text-muted">(未設定)</span>
  render() {
    const { history, user, tenant, match: { params: { slug, customerId } } } = this.props;
    const { customer, reservations = [], tables = {}, courses = {}, customerTags = {}, allergies = {}, shouldShowFormModal, targetCustomerValues } = this.state;
    return (
      <div className="tenant-customer">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} renderButtons={() =>
          <Fragment>
            <Button color="secandary" className="mr-1" onClick={this.onClickEdit}>
              <span className="fas fa-edit" />
            </Button>
            <Button color="danger" onClick={this.onClickDelete}>
              <span className="fas fa-trash" />
            </Button>
          </Fragment>
        } />
        <div className="container p-4 pt-4 mt-5">
          <div className="d-flex justify-content-start mb-3">
            <a className="text-primary" onClick={history.goBack}>
              <span className="fas fa-arrow-left mr-1" />
              Back
            </a>
          </div>
          <h4 className="text-center mb-4">顧客詳細</h4>
          {
            customer && (
              <div>
                <Card>
                  <CardBody>
                    <dl>
                      <dt className="small text-muted">ふりがな</dt>
                      <dd>
                        {this.formatNothing(customer.nameKana)}
                      </dd>
                      <dt className="small text-muted">名前</dt>
                      <dd>
                        {customer.name}
                      </dd>
                      <dt className="small text-muted">電話番号</dt>
                      <dd>
                        {customer.phone}
                      </dd>
                      <dt className="small text-muted">属性</dt>
                      <dd>
                        {this.formatNothing(keys(customer.customerTags || {}).map(_ => customerTags[_]).filter(_ => _).map(_ => _.name).join(', '))}
                      </dd>
                      <dt className="small text-muted">性別</dt>
                      <dd>
                        {this.formatNothing(genders[customer.gender])}
                      </dd>
                      <dt className="small text-muted">メールアドレス</dt>
                      <dd>
                        {this.formatNothing(customer.email)}
                      </dd>
                      <dt className="small text-muted">誕生日</dt>
                      <dd>
                        {this.formatNothing(customer.birthday && formatDate(customer.birthday.toDate(), 'YYYY/MM/DD'))}
                      </dd>
                      <dt className="small text-muted">アレルギー</dt>
                      <dd>
                        {this.formatNothing(keys(customer.allergies || {}).map(_ => allergies[_]).filter(_ => _).map(_ => _.name).join(', '))}
                      </dd>
                      <dt className="small text-muted">好きなもの</dt>
                      <dd>
                        {this.formatNothing(customer.favorites)}
                      </dd>
                      <dt className="small text-muted">嫌いなもの</dt>
                      <dd>
                        {this.formatNothing(customer.dislikes)}
                      </dd>
                      <dt className="small text-muted">喫煙情報</dt>
                      <dd>
                        {this.formatNothing(({ true: '喫煙者', false: '非喫煙者' })[customer.smoking])}
                      </dd>
                    </dl>
                  </CardBody>
                </Card>
                {
                  reservations.length > 0 && (
                    <div>
                      <hr />
                      <div>
                        <h5 className="mb-3">予約一覧</h5>
                        <div>
                          {
                            sortBy(reservations, 'startAt').reverse().map((reservation) => {
                              return (
                                <Link key={reservation.id} className="d-block mb-3" to={`/t/${slug}/reservations/${reservation.id}`}>
                                  <ReservationListItem reservation={reservation} tables={tables} courses={courses} />
                                </Link>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
        <CustomerFormModal values={targetCustomerValues} customerId={customerId} slug={slug} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} data={{ customerTags, allergies}} />
      </div>
    );
  }
});

import React from 'react';
import { format as formatDate } from 'date-fns';

import ReservationStatusBadge from './ReservationStatusBadge';

const { keys } = Object;

export default function ReservationListItem({ reservation: { id, name, nameKana, peopleCount, startAt, endAt, tableIds, courseId, cancelReason, versions = [], status }, tables, courses }) {
  const relatedTables = keys(tableIds || {}).map(_ => (tables || {})[_]).filter(_ => _);
  const course = (courses || {})[courseId] || {};
  return (
    <div>
      <div className="small">
        <span className="text-muted">{id}</span>
        <ReservationStatusBadge className="ml-2" status={status} />
        {
          cancelReason && (
            <span className="ml-2 text-danger">[取消済み]</span>
          )
        }
        {
          !cancelReason && tableIds === null && (
            <span className="ml-2 text-warning">
              <span className="mr-2 fas fa-exclamation-triangle" />
              ウェイティング
            </span>
          )
        }
      </div>
      <div className="small">
        <span>{formatDate(startAt.toDate(), 'MM/DD HH:mm')}</span>
        <span> - </span>
        <span>{formatDate(endAt.toDate(), 'HH:mm')}</span>
        <span className="ml-2">{peopleCount}名</span>
        <span className="ml-2">{relatedTables.map(_ => _.name).join(', ')}</span>
        <span className="ml-2">{course.name || '席のみ'}</span>
      </div>
      <div>
        <span>{name} ({nameKana}) 様</span>
      </div>
    </div>
  );
};

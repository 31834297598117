import React, { Component } from 'react';
import { Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';

const { entries } = Object;

export default class HeaderNav extends Component {
  render() {
    const { currentPath, tenant } = this.props;
    const titles = {
      '/admin': 'RSBL Admin',
      '/t/:slug/admin': `${tenant && tenant.name} 管理画面`,
    };
    const paths = {
      '/admin': '/admin',
      '/t/:slug/admin': `/t/${tenant && tenant.slug}/admin`,
    }
    const title = entries(titles).find(([k, v]) => currentPath.startsWith(k))[1];
    const path = entries(paths).find(([k, v]) => currentPath.startsWith(k))[1];
    return (
      <header>
        <Navbar color="light" light expand="md">
          <Link to={path} className="mr-2" style={{ color: 'black', textDecoration: 'none' }}>{title}</Link>
        </Navbar>
      </header>
    );
  }
};

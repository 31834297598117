import { isWithinRange } from 'date-fns';

import dateToTime from './dateToTime';

export function computeDuration(date, { lunchStart, lunchEnd, dinnerStart, dinnerEnd, lunchDuration = 0, dinnerDuration = 0, otherDuration = 0 }) {
  if(!lunchStart || !lunchEnd || !dinnerStart || !dinnerEnd) return 0;
  const time = dateToTime(date);
  return isWithinRange(time, lunchStart.toDate(), lunchEnd.toDate()) ?
    lunchDuration
  : isWithinRange(time, dinnerStart.toDate(), dinnerEnd.toDate()) ?
    dinnerDuration
  : otherDuration;
};

function initialRoute(reservation) {
  return (reservation.versions && reservation.versions[0].route) || reservation.route;
};

module.exports = {
  routeLabel: function routeLabel(routeId, routes, customRoutes = [], aliases = {}) {
    return aliases[routeId] || routes[routeId] || (customRoutes.find(_ => _.id === routeId) || {}).label;
  },
  initialRoute,
};

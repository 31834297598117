import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase, { config } from '../../firebase';

import TenantAdminPage from '../hocs/TenantAdminPage';
import { texts } from '../../config';

import './TenantAdminServiceCredentials.css';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { entries } = Object;

export default TenantAdminPage(class TenantAdminServiceCredentials extends Component {
  constructor() {
    super();
    this.state = {isDisplayedHP: true, isDisplayedGN: true, isDisplayedTB: true};
  }
  componentDidMount() {
    this.listenServiceCredentials();
  }
  listenServiceCredentials() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('serviceCredentials')
      .onSnapshot((snapshot) => {
        this.setState({ serviceCredentials: snapshot.docs.map(_ => ({ ..._.data(), service: _.id })).reduce((x, y) => ({ ...x, [y.service]: y }), {}) });
      });
  }
  onChange = (service, propName, { target: { value } }) => {
    const { match: { params: { slug } } } = this.props;
    const { serviceCredentials } = this.state;
    tenantsRef
      .doc(slug)
      .collection('serviceCredentials')
      .doc(service)
      .set({ ...serviceCredentials[service], [propName]: value });
  }
  toggleInputHP() {
    this.setState({isDisplayedHP: !this.state.isDisplayedHP});
  }
  toggleInputGN() {
    this.setState({isDisplayedGN: !this.state.isDisplayedGN});
  }
  toggleInputTB() {
    this.setState({isDisplayedTB: !this.state.isDisplayedTB});
  }
  render() {
    const { match: { params: { slug } } } = this.props;
    const { serviceCredentials = {} } = this.state;
    return (
      <div className="tenant-admin-service-credentials">
        <div className="d-flex justify-content-center mb-3">
          <h4>予約サイト ログイン設定</h4>
        </div>
        <table className="table mt-5">
          <tbody>
            <tr>
              <th>ホットペッパー</th>
              <td>
                <Input type={this.state.isDisplayedHP ? "password" : ""} value={serviceCredentials.HP && serviceCredentials.HP.id} onChange={this.onChange.bind(this, 'HP', 'id')} placeholder="ログインID" />
              </td>
              <td>
                <Input type={this.state.isDisplayedHP ? "password" : ""} value={serviceCredentials.HP && serviceCredentials.HP.pass} onChange={this.onChange.bind(this, 'HP', 'pass')} placeholder="パスワード" />
              </td>
              <td class="toggle-link-td">
                <Button color="link" onClick={() => {this.toggleInputHP()}}>
                <span className={classnames('far', { 'fa-eye': this.state.isDisplayedHP, 'fa-eye-slash': !this.state.isDisplayedHP}, 'fa-lg')} style={{ color: 'black' }} />
                </Button>
              </td>
            </tr>
            <tr>
              <th>ぐるなび</th>
              <td>
                <Input type={this.state.isDisplayedGN ? "password" : ""} value={serviceCredentials.GN && serviceCredentials.GN.id} onChange={this.onChange.bind(this, 'GN', 'id')} placeholder="ログインID" />
              </td>
              <td>
                <Input type={this.state.isDisplayedGN ? "password" : ""} value={serviceCredentials.GN && serviceCredentials.GN.pass} onChange={this.onChange.bind(this, 'GN', 'pass')} placeholder="パスワード" />
              </td>
              <td class="toggle-link-td">
                <Button color="link" onClick={() => {this.toggleInputGN()}}>
                  <span className={classnames('far', { 'fa-eye': this.state.isDisplayedGN, 'fa-eye-slash': !this.state.isDisplayedGN}, 'fa-lg')} style={{ color: 'black' }} />
                </Button>
              </td>
            </tr>
            <tr>
              <th>食べログ</th>
              <td>
                <Input type={this.state.isDisplayedTB ? "password" : ""} value={serviceCredentials.TB && serviceCredentials.TB.id} onChange={this.onChange.bind(this, 'TB', 'id')} placeholder="ログインID" />
              </td>
              <td>
                <Input type={this.state.isDisplayedTB ? "password" : ""} value={serviceCredentials.TB && serviceCredentials.TB.pass} onChange={this.onChange.bind(this, 'TB', 'pass')} placeholder="パスワード" />
              </td>
              <td class="toggle-link-td">
                <Button color="link" onClick={() => {this.toggleInputTB()}}>
                  <span className={classnames('far', { 'fa-eye': this.state.isDisplayedTB, 'fa-eye-slash': !this.state.isDisplayedTB}, 'fa-lg')} style={{ color: 'black' }} />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
});

import React, { Fragment } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import { colors } from '../../../config';

export default function AppPieChart({ data }) {
  return (
    <PieChart width={320} height={320}>
      <Pie
        dataKey="value"
        data={data}
        startAngle={90}
        endAngle={-270}
        labelLine={false}
        outerRadius="75%"
        label={({ cx, cy, x, y, fill, percent, value }) => {
          const adjustX = x > cx ? 10 : -10;
          const adjustY = y > cy ? -6 : 6;
          return (
            <Fragment>
              <text x={x + adjustX} y={y - 6 + adjustY} fill={fill} textAnchor="middle" dominantBaseline="central" fontSize="10">
                {value}
              </text>
              <text x={x + adjustX} y={y + 6 + adjustY} fill={fill} textAnchor="middle" dominantBaseline="central" fontSize="10">
                {`${(percent * 100).toFixed(1)}%`}
              </text>
            </Fragment>
          );
        }}
      >
        {
          data.map((_, i) => (
            <Cell key={i} fill={colors[i]} />
          ))
        }
      </Pie>
      <Tooltip />
      <Legend wrapperStyle={{ fontSize: '9px' }} />
    </PieChart>
  );
};

import React, { Component } from 'react';
import {
  Nav,
  NavItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import AdminPage from '../hocs/AdminPage';

export default AdminPage(class AdminRoot extends Component {
  render() {
    return (
      <div className="admin-root">
        <div className="container p-5">
          <Nav vertical>
            <NavItem>
              <Link to="/admin/tenants">テナント管理</Link>
            </NavItem>
            <NavItem>
              <Link to="/admin/googleAuth">Google連携</Link>
            </NavItem>
            <NavItem>
              <Link to="/admin/serviceSyncJobs">ServiceSyncJobs</Link>
            </NavItem>
            <NavItem>
              <Link to="/admin/customRoutes">カスタム経路</Link>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }
});

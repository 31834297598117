import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import MessageForm from '../hocs/MessageForm';

const { entries } = Object;

export default MessageForm(class TargetDate extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { targetDate } = this.state;
    onAction({ type: 'submitTargetDate', payload: { targetDate } });
  }
  isUnsubmittable() {
    return false;
  }
  render() {
    const { isActive, payload: { isNotFound } } = this.props;
    const { targetDate } = this.state;
    return (
      <div className="target-date">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>
                  {
                    isNotFound ? (
                      <span className="text-warning">
                        指定した日付に予約がありません。予約日を確認の上、再入力してください。
                      </span>
                    ) : (
                      <span>
                        予約日を選択してください。
                      </span>
                    )
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-nowrap">
                  予約日
                </td>
                <td>
                  <DatePicker
                    dateFormat="YYYY/MM/DD"
                    withPortal
                    selected={targetDate && moment(targetDate)}
                    onChange={this.onChangeDate.bind(this, 'targetDate')}
                    disabled={!isActive}
                    minDate={new Date()}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

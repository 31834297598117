import React, { useState, useEffect } from 'react';

import firebase, { functions } from '../../firebase';

const findShortUrl = functions.httpsCallable('findShortUrl');

export default function ShortUrl(props) {
  const { history, match: { params: { shortUrlId } } } = props;
  useEffect(() => {
    (async () => {
      const { data: shortUrl } = await findShortUrl({ shortUrlId });
      if(shortUrl && shortUrl.url) {
        history.replace(shortUrl.url);
      }
    })();
  }, []);

  return null;
};

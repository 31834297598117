import React from 'react';
import { isEmpty } from 'lodash';

export default function PublicTenantBlock (props) {
  const { tenant } = props;

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3">
        {
          !isEmpty(tenant.logoImageFileUrl) && (
            <div style={{ width: '100%', height: 200, backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${tenant.logoImageFileUrl})`, }}>
            </div>
          )
        }
        <div className="mt-3 text-right">
          <div>
            {tenant.name}
          </div>
          <div>
            <a href={`tel:${tenant.phone}`}>
              {tenant.phone}
            </a>
          </div>
          <div className="small">
            {tenant.address}
          </div>
        </div>
      </div>
    </div>
  );
}

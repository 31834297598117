import React, { Component } from 'react';
import { Button } from 'reactstrap';

import firebase from '../firebase';

const auth = firebase.auth();

export default class SideNav extends Component {
  signOut = () => {
    auth.signOut();
    window.location.reload();
  }
  render() {
    const { user } = this.props;
    return (
      <div className="bg-dark text-light p-3" style={{ width: '220px', height: '100vh' }}>
        {
          user && (
            <div>
              <div className="small mb-2">{user.name}</div>
              <Button size="sm" onClick={this.signOut}>ログアウト</Button>
            </div>
          )
        }
      </div>
    );
  }
};

import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { omit } from 'lodash';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import TenantFormModal from '../modals/TenantFormModal';
import AdminPage from '../hocs/AdminPage';

const db = firebase.firestore();
const serviceSyncJobsRef = db.collection('serviceSyncJobs');

export default AdminPage(class AdminServiceSyncJobs extends Component {
  constructor() {
    super();
    this.state = {};
  }
  onChangeText = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  onClickClone = async () => {
    const { targetId } = this.state;
    const snap = await serviceSyncJobsRef.doc(targetId).get();
    if(!snap.exists) return toast.error('存在しません');
    const newId = formatDate(new Date(), 'YYMMDDHHmmss') + Math.random().toString(36).slice(-3);
    await serviceSyncJobsRef.doc(newId).set({ ...omit(snap.data(), ['errorMessage', 'failedAt']), completedAt: null });
    toast.success(`複製しました。${newId}`);
  }
  render() {
    const { targetId = '' } = this.state;
    return (
      <div className="admin-tenant">
        <div className="container p-5">
          <div className="d-flex justify-content-center">
            <h4>ServiceSyncJobs</h4>
          </div>
          <div className="d-flex justify-content-end">
            <Input className="mr-1" style={{ width: 200 }} value={targetId} onChange={this.onChangeText.bind(this, 'targetId')} />
            <Button color="primary" onClick={this.onClickClone}>
              複製
            </Button>
          </div>
        </div>
      </div>
    );
  }
});


const { range, isEmpty, } = require('lodash');
const numeral = require('numeral');
const moment = require('moment');
const { isHiragana } = require('wanakana');
const { isMultibyte, isEmail, } = require('validator');
const { addDays, format: formatDate, addHours, startOfDay, endOfDay, } = require('date-fns');

const cancelFeeFields = ({ price, peopleCount } = {}) => {
  return {
    cancelFee: {
      label: 'キャンセル料',
      type: 'integer',
      initialValue: price * peopleCount,
    },
  };
};

module.exports = {
  cancelFeeFields
};

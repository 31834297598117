import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';

import MessageForm from '../hocs/MessageForm';

export default MessageForm(class ReservationPhone extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { phone } = this.state;
    onAction({ type: 'submitReservationPhone', payload: { phone } });
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['phone'].some(_ => !validations[_]);
  }
  render() {
    const { isActive } = this.props;
    const { phone } = this.state;
    return (
      <div className="reservation-phone">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>お客様の電話番号を入力してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input value={phone} onChange={this.onChangeText.bind(this, 'phone')} className={this.validationCss('phone')} placeholder="[必須] 08012345678" disabled={!isActive} />
                </td>
              </tr>
              <tr>
                <td>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

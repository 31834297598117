const { isEmpty, } = require('lodash');

module.exports = {
  fields: () => {
    return {
      id: {
        label: 'ID',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      label: {
        label: '表示名',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
    };
  },
};

import React, { Component } from 'react';

import firebase from '../../firebase';
import Page from './Page';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default function TenantPageHOC(WrappedComponent) {
  return Page(class TenantPage extends Component {
    constructor() {
      super();
      this.state = {
        hasCheckedTenantUser: false,
      };
    }
    componentDidMount() {
      this.listenUser();
      this.listenTenant();
      this.updateUserAccess();
    }
    signOut = () => {
      auth.signOut();
      window.location.reload();
    }
    componentDidUpdate(prevProps) {
      if(prevProps.match.params.slug !== this.props.match.params.slug) {
        this.unlistenTenant && this.unlistenTenant();
        this.listenTenant();
      }
    }
    listenUser() {
      const { uid, match: { params: { slug } } } = this.props;
      tenantsRef
        .doc(slug)
        .collection('users')
        .doc(uid)
        .onSnapshot((snapshot) => {
          this.setState({ user: snapshot.data(), hasCheckedTenantUser: true });
        });
    }
    listenTenant() {
      const { uid, match: { params: { slug } } } = this.props;
      this.unlistenTenant = tenantsRef
        .doc(slug)
        .onSnapshot((snapshot) => {
          this.setState({ tenant: snapshot.data() });
        });
    }
    async updateUserAccess() {
      const { uid, match: { params: { slug } } } = this.props;
      try {
        await tenantsRef
          .doc(slug)
          .collection('users')
          .doc(uid)
          .update({
            lastAccessedAt: new Date(),
          });
      } catch (e) {
        if (e.message.match('No document to update')) {
          return;
        } else {
          throw e;
        }
      }
    }
    render() {
      const { uid } = this.props;
      const { user, tenant, hasCheckedTenantUser } = this.state;
      return (
        <div className="tenant-page">
          {
            hasCheckedTenantUser && (user ? (
              tenant && <WrappedComponent {...this.props} {...{ user, tenant }}/>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                Not Authorized
                <span className="btn btn-link" onClick={this.signOut}>
                  ログアウト
                </span>
              </div>
            ))
          }
        </div>
      );
    }
  });
};

const { isEmpty, } = require('lodash');

module.exports = {
  publicPageFields: () => {
    return {
      phone: {
        label: '電話番号',
        type: 'string',
      },
      address: {
        label: '所在地',
        type: 'string',
      },
      logoImageFile: {
        label: 'ロゴ画像ファイル',
        type: 'file',
      },
    };
  },
};


import React from 'react';
import { Table } from 'reactstrap';
import classnames from 'classnames';
import { differenceBy } from 'lodash';

export default function Home({ onAction, isActive, data: { waitings = [], overflowings = [], viaPhoneNotes = [], overStarts = [], overEnds = [], } }) {
  return (
    <div className="home">
      <Table>
        <thead>
          <tr>
            <th>メニューを選択してください</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'walkIn' })}>
                ウォークイン
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'reserve' })}>
                新規予約
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'editReservation' })}>
                予約変更
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'revokeReservation' })}>
                予約取消
              </a>
            </td>
          </tr>
          {
            waitings.filter(_ => !_.cancelReason).length > 0 && (
              <tr>
                <td>
                  <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'viewWaitings' })}>
                    <span className="text-warning fas fa-exclamation-triangle mr-2" />
                    ウェイティング予約
                  </a>
                </td>
              </tr>
            )
          }
          {
            differenceBy(overflowings, waitings, _ => _.id).filter(_ => !_.cancelReason && !_.hasAllowedOverflowings).length > 0 && (
              <tr>
                <td>
                  <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'viewOverflowings' })}>
                    <span className="text-warning fas fa-exclamation-triangle mr-2" />
                    定員オーバー予約
                  </a>
                </td>
              </tr>
            )
          }
          {
            viaPhoneNotes.length > 0 && (
              <tr>
                <td>
                  <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'viewViaPhoneNotes' })}>
                    <span className="text-warning fas fa-exclamation-triangle mr-2" />
                    AIロボ経由の連絡事項
                  </a>
                </td>
              </tr>
            )
          }
          {
            overStarts.length > 0 && (
              <tr>
                <td>
                  <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'viewOverStarts' })}>
                    <span className="text-warning fas fa-exclamation-triangle mr-2" />
                    来店時刻到来済み予約
                  </a>
                </td>
              </tr>
            )
          }
          {
            overEnds.length > 0 && (
              <tr>
                <td>
                  <a className={classnames('btn', 'btn-link', { disabled: !isActive })} onClick={onAction.bind(this, { type: 'viewOverEnds' })}>
                    <span className="text-warning fas fa-exclamation-triangle mr-2" />
                    退店時刻到来済み予約
                  </a>
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  );
};

import React from 'react';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import { get, keyBy, omit } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import TenantAdminPage from '../hocs/TenantAdminPage';
import EditButton from '../EditButton';
import TenantRouteAliasesFormModal from '../modals/TenantRouteAliasesFormModal';
import { routes } from '../../config';

const { entries } = Object;

const db = firebase.firestore();
const customRoutesRef = db.collection('customRoutes');
const tenantsRef = db.collection('tenants');

export default TenantAdminPage(function TenantAdminRouteAliases (props) {
  const { tenant, match: { params: { slug } } } = props;
  const customRoutes = useCollectionSubscription(customRoutesRef.orderBy('createdAt'));
  const allRoutes = [
    ...entries(routes).map(([id, label]) => ({ id, label })),
    ...customRoutes,
  ];

  return (
    <div className="custom-routes container">
      <div className="d-flex justify-content-center mb-2">
        <h4>経路表示名設定</h4>
      </div>
      <div className="d-flex justify-content-end">
        <EditButton itemRef={tenantsRef.doc(slug)} FormModal={TenantRouteAliasesFormModal} formProps={{ tenant, routes: allRoutes, }} />
      </div>
      <div className="mt-3">
        <table className="table">
          <thead>
            <tr>
              <th>経路</th>
              <th>表示名</th>
            </tr>
          </thead>
          <tbody>
            {
              allRoutes.map(({ id, label }) => {
                return (
                  <tr>
                    <td>{label}</td>
                    <td>{get(tenant, `routeAliases.${id}`) || label}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

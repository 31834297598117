import React, { useState, } from 'react';
import fileDownload from 'js-file-download';
import { TextEncoder, TextDecoder } from 'text-encoding';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import classnames from 'classnames';
import { Button, } from 'reactstrap';
import { union, isFunction, } from 'lodash';

const { values, keys } = Object;

export default function ExportButton ({ fileName, rows, ...extraProps }) {
  const [isExporting, setIsExporting] = useState(false);

  const onClickExport = async () => {
    setIsExporting(true);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const _rows = isFunction(rows) ? (await rows()) : rows;
    const fields = union(..._rows.map(keys));
    const fileContent = encoder.encode(unparseCsv({ fields, data: _rows.map(r => fields.map(_ => r[_])) }));
    fileDownload(fileContent, fileName);
    setIsExporting(false);
  };

  return (
    <Button color="secondary" onClick={onClickExport} disabled={isExporting} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      エクスポート
    </Button>
  );
};


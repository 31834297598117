import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';

import MessageForm from '../hocs/MessageForm';

export default MessageForm(class ReservationNote extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { privateNote } = this.state;
    onAction({ type: 'submitReservationNote', payload: { privateNote } });
  }
  isUnsubmittable() {
    return false;
  }
  render() {
    const { isActive } = this.props;
    const { privateNote } = this.state;
    return (
      <div className="reservation-note">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th>店舗メモ： 連絡事項等あれば入力してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input type="textarea" value={privateNote} onChange={this.onChangeText.bind(this, 'privateNote')} className={this.validationCss('privateNote')} placeholder="[任意] 店舗メモ" disabled={!isActive} />
                </td>
              </tr>
              <tr>
                <td>
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

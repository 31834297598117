import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { omitBy } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';
import { isEmail } from 'validator';

import firebase from '../../firebase';

const { keys } = Object;
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const defaultState = {
  id: null,
  name: '',
  nameHP: '',
  nameGN: '',
  nameTB: '',
  validations: {},
  isSubmitting: false,
};
const { entries } = Object;

export default class TableFormModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  setValues() {
    const { values } = this.props;
    this.setState({ ...omitBy(values, _ => _ === undefined) });
    entries(values || {}).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      case 'name': 
        this.setState({ validations: { ...validations, [propName]: !!value } });
        break;
      default:
        this.setState({ validations: { ...validations, [propName]: true } });
        break;
    }
  }
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onMultiSelect = (name, selectedOptions) => {
    const value = selectedOptions.reduce((x, y) => ({ ...x, [y.value]: true }), {});
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    const { id, name, tables, nameHP, nameGN, nameTB } = this.state;
    this.setState({ isSubmitting: true });
    onSubmit({ id, name, tables, nameHP, nameGN, nameTB });
  }
  isUnsubmittable() {
    const { isSubmitting, validations } = this.state;
    return isSubmitting || ['name'].some(_ => !validations[_]);
  }
  render() {
    const { isOpen, tables: _tables, onClickClose } = this.props;
    const { id, name, tables = {}, nameHP, nameGN, nameTB } = this.state;
    const isNew = !id;
    const tableOptions = (_tables || []).map(_ => ({ label: _.name, value: _.id }));
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {isNew ? '新規テーブルグループ' : 'テーブルグループ編集'}
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>テーブル名</Label>
              <Input value={name} onChange={this.onChange.bind(this, 'name')} className={this.validationCss('name')} disabled={!isNew} />
            </FormGroup>
            <FormGroup>
              <Label>HPセット名</Label>
              <Input value={nameHP} onChange={this.onChange.bind(this, 'nameHP')} className={this.validationCss('nameHP')}/>
            </FormGroup>
            <FormGroup>
              <Label>GNセット名</Label>
              <Input value={nameGN} onChange={this.onChange.bind(this, 'nameGN')} className={this.validationCss('nameGN')}/>
            </FormGroup>
            <FormGroup>
              <Label>TBセット名</Label>
              <Input value={nameTB} onChange={this.onChange.bind(this, 'nameTB')} className={this.validationCss('nameTB')}/>
            </FormGroup>
            <FormGroup>
              <Label>テーブル</Label>
              <Select
                value={tableOptions.filter(_ => keys(tables).includes(_.value))}
                onChange={this.onMultiSelect.bind(this, 'tables')}
                options={tableOptions}
                isMulti
                isClearable={false}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

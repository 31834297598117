import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { debounce } from 'lodash';
import classnames from 'classnames';

import firebase from '../../firebase';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

const defaultState = {
  name: '',
  slug: '',
  validations: {},
  isValidating: false,
};

export default class TenantFormModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isOpen !== this.props.isOpen) {
      this.clear();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      case 'name': 
        this.setState({ validations: { ...validations, name: !!value } });
        break;
      case 'slug': 
        this.setState({ isValidating: true });
        this.validateSlug(value);
        break;
      default:
    }
  }
  validateSlug = debounce((value) => {
    const { validations } = this.state;
    if (!value) {
      this.setState({ validations: { ...validations, slug: false } });
      this.setState({ isValidating: false });
    } else {
      tenantsRef.doc(value).get().then(({ exists }) => {
        this.setState({ validations: { ...validations, slug: !exists } });
        this.setState({ isValidating: false });
      });
    }
  }, 500)
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    const { name, slug } = this.state;
    onSubmit({ name, slug });
  }
  isUnsubmittable() {
    const { isValidating, validations } = this.state;
    return isValidating || !validations.name || !validations.slug;
  }
  render() {
    const { isOpen, onClickClose } = this.props;
    const { name, slug } = this.state;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          新規テナント
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>テナント名</Label>
              <Input value={name} onChange={this.onChange.bind(this, 'name')} className={this.validationCss('name')}/>
            </FormGroup>
            <FormGroup>
              <Label>Slug</Label>
              <Input value={slug} onChange={this.onChange.bind(this, 'slug')} className={this.validationCss('slug')}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};


import React, { Fragment } from 'react';
import { omit, isFunction, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';

export default function AddButton ({ initialValues, validateOnSubmit = _ => true, itemRef, FormModal, formProps, processValues = _ => _, ...extraProps }) {
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    const ref = isFunction(itemRef) ? itemRef(values) : itemRef;
    const { id } = ref;
    if(!(await validateOnSubmit({ id, ...values }))) return;

    try {
      await ref.set({ ...omit(await processValues({ id, ...values }), ['id', 'ref']), createdAt: new Date(), });
      toast.success('追加しました');
      toggleModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Fragment>
      <Button color="primary" onClick={_ => toggleModal(true)} {...extraProps}>
        <span className="fas fa-plus mr-1" />
        追加
      </Button>
      <FormModal isOpen={showsModal} values={initialValues} onClickClose={_ => toggleModal(false)} onSubmit={onSubmit} {...formProps} />
    </Fragment>
  );
};

import React, { Component } from 'react';
import { Form, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import TenantAdminPage from '../hocs/TenantAdminPage';
import { texts } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default TenantAdminPage(class TenantAdminCustomerTags extends Component {
  constructor() {
    super();
    this.state = {
      newCustomerTag: ''
    };
  }
  componentDidMount() {
    this.listenCustomerTags();
  }
  listenCustomerTags() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('customerTags')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customerTags: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  clear = () => {
    this.setState({ newCustomerTag: '' });
  }
  onSubmit = (event) => {
    event.preventDefault();
    const { tenant: { customerTags = [] }, match: { params: { slug } } } = this.props;
    const { newCustomerTag } = this.state;
    if (!newCustomerTag) return;
    tenantsRef
      .doc(slug)
      .collection('customerTags')
      .add({ name: newCustomerTag, createdAt: new Date() })
      .then(this.clear)
      .then(() => toast.success('追加しました'));
  }
  delete = (id) => {
    if (!window.confirm('本当に削除しますか？')) return;
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('customerTags')
      .doc(id)
      .delete()
      .then(() => toast.success('削除しました'));
  }
  onChangeCustomerTag = ({ target: { value } }) => {
    this.setState({ newCustomerTag: value });
  }
  render() {
    const { match: { params: { slug } } } = this.props;
    const { newCustomerTag, customerTags } = this.state;
    return (
      <div className="admin-tenants">
        <div className="d-flex justify-content-center mb-3">
          <h4>顧客属性設定</h4>
        </div>
        <Form className="d-flex justify-content-end" onSubmit={this.onSubmit}>
          <Input className="mr-2" style={{ width: '200px' }} value={newCustomerTag} onChange={this.onChangeCustomerTag} />
          <Button type="submit" color="primary" disabled={!newCustomerTag}>
            <span className="fas fa-plus mr-1" />
            追加
          </Button>
        </Form>
        <table className="table mt-5">
          <tbody>
            {
              (customerTags || []).map(({ name, id }) => {
                return (
                  <tr key={id}>
                    <td>
                      {name}
                    </td>
                    <td>
                      <Button color="danger" onClick={this.delete.bind(this, id)}>
                        <span className="fas fa-trash mr-1" />
                        削除
                      </Button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
});

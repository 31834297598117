import React, { Fragment } from 'react';
import { Form, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import { omit } from 'lodash';
import { useToggle, } from 'react-use';

import firebase from '../../firebase';
import TenantAdminPage from '../hocs/TenantAdminPage';
import CourseFormModal from '../modals/CourseFormModal';
import { texts } from '../../config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';

const db = firebase.firestore();
const adminUsersRef = db.collection('adminUsers');
const tenantsRef = db.collection('tenants');

export default TenantAdminPage(function TenantAdminCourses (props) {
  const { tenant, user, match: { params: { slug } } } = props;
  const [shouldShowFormModal, toggleFormModal] = useToggle(false);
  const coursesRef = tenantsRef.doc(slug).collection('courses');
  const adminUser = useDocumentSubscription(adminUsersRef.doc(user.uid), [user]);
  const courses = useCollectionSubscription(coursesRef.orderBy('price'), [slug]);
  const processRow = (batch, row) => {
    batch.set(coursesRef.doc(...(row.id ? [row.id] : [])), {
      ...omit(row, 'id'),
      price: parseInt(row.price, 10) || 0,
      createdAt: new Date(),
    });
  };
  const beforeImport = async (rows) => {
    const courseIds = courses.map(_ => _.id);
    if(rows.some(_ => courseIds.includes(_.id))) {
      toast.error('すでに存在します。');
      throw new Error('exists');
    }
  };
  const rowsForExport = () => {
    return courses.map((course) => {
      return {
        ...omit(course, ['ref', 'createdAt']),
      };
    });
  };

  return (
    <div className="tenant-admin-courses">
      <div className="d-flex justify-content-center mb-3">
        <h4>コース設定</h4>
      </div>
      <div className="d-flex justify-content-end">
        {
          adminUser != null && (
            <Fragment>
              <ImportButton processRow={processRow} beforeSave={beforeImport} />
              <ExportButton className="ml-2" fileName="コース.csv" rows={rowsForExport} />
            </Fragment>
          )
        }
        <AddButton className="ml-2" itemRef={_ => coursesRef.doc()} FormModal={CourseFormModal} formProps={{ tenant, }} />
      </div>
      <table className="table mt-5">
        <thead>
          <tr>
            <th>コース名</th>
            {
              (tenant.servicesIntegrationEnabled || false) && (
                <Fragment>
                  <th>HP名</th>
                  <th>GN名</th>
                  <th>TB名</th>
                </Fragment>
              )
            }
            <th>金額</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            (courses || []).map(({ id, ref, name, nameHP, nameGN, nameTB, price }) => {
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  {
                    (tenant.servicesIntegrationEnabled || false) && (
                      <Fragment>
                        <td>
                          {nameHP}
                        </td>
                        <td>
                          {nameGN}
                        </td>
                        <td>
                          {nameTB}
                        </td>
                      </Fragment>
                    )
                  }
                  <td className="text-right">
                    {numeral(price).format('0,0')}
                  </td>
                  <td className="text-nowrap">
                    <EditButton itemRef={ref} FormModal={CourseFormModal} formProps={{ tenant }} />
                    <DeleteButton itemRef={ref} className="ml-2" />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
});

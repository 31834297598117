import React, { Component, Fragment, useState, useEffect, } from 'react';
import { format as formatDate } from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sortBy, uniqBy, omit, debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { Button, Table, FormGroup, Label, Input } from 'reactstrap';
import { differenceInMinutes, startOfDay, addDays } from 'date-fns';

import firebase from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import TenantHeaderNav from '../TenantHeaderNav';
import { viaPhoneNoteStatuses } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { keys } = Object;

export default TenantPage(class TenantViaPhoneNotes extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.listenViaPhoneNotes();
  }
  componentDidUpdate(_, prevState) {
  }
  listenViaPhoneNotes() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('viaPhoneNotes')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        this.setState({ viaPhoneNotes: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  render() {
    const { tenant, user, match: { params: { slug } } } = this.props;
    const { viaPhoneNotes = [], } = this.state;

    return (
      <div className="tenant-via-phone-notes">
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
        <div className="container p-4 pt-5 mt-5">
          <h4 className="text-center mb-4">AIロボ経由の連絡事項</h4>
          <div className="d-flex mb-3 align-items-center justify-content-end">
          </div>
          <Table>
            <thead className="text-center">
              <tr>
                <th>状態</th>
                <th>予約ID</th>
                <th>本文</th>
                <th>録音</th>
                <th>受信日時</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                viaPhoneNotes.map((viaPhoneNote) => {
                  return (
                    <Row key={viaPhoneNote.id} {...{ viaPhoneNote, slug, }} />
                  );
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
});

function Row(props) {
  const { slug, viaPhoneNote } = props;
  const { id, body, receptionId, voiceUrl, createdAt, status, } = viaPhoneNote;
  const [reservationId, setReservationId] = useState(viaPhoneNote.reservationId);
  const setViaPhoneNoteStatus = (status) => {
    if(!window.confirm(`本当に「${viaPhoneNoteStatuses[status]}」にしますか？`)) return;

    tenantsRef
      .doc(slug)
      .collection('viaPhoneNotes')
      .doc(id)
      .update({ status });
  }
  useEffect(() => {
    (async () => {
      if(receptionId) {
        const { docs: [doc] } = await tenantsRef.doc(slug).collection('reservations').where('initialReceptionId', '==', receptionId).limit(1).get();
        if(doc) {
          setReservationId(doc.id);
        }
      }
    })();
  }, []);

  return (
    <tr>
      <td>
        <span className={classnames('badge', { 'badge-secondary': status === 'initial', 'badge-success': status === 'resolved' })}>
          {viaPhoneNoteStatuses[status]}
        </span>
      </td>
      <td>
        {
          reservationId && (
            <Link to={`/t/${slug}/reservations/${reservationId}`}>
              {reservationId}
            </Link>
          )
        }
      </td>
      <td style={{ whiteSpace: 'pre-line' }}>
        {body}
      </td>
      <td>
        {voiceUrl && <audio src={voiceUrl} controls preload />}
      </td>
      <td>
        {formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
      </td>
      <td className="text-right text-nowrap">
        {
          status === 'initial' ? (
            <Button size="sm" color="success" onClick={setViaPhoneNoteStatus.bind(null, 'resolved')}>
              解決済みにする
            </Button>
          ) : (
            <Button size="sm" color="secondary" onClick={setViaPhoneNoteStatus.bind(null, 'initial')}>
              未解決にする
            </Button>
          )
        }
      </td>
    </tr>
  );
}

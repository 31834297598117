import React, { useState, useEffect } from 'react';
import { Button, Form, } from 'reactstrap';
import { omit, sumBy, get, last, chunk, mapValues } from 'lodash';
import { useToggle, } from 'react-use';
import { format as formatDate, parse as parseDate, } from 'date-fns';

import { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import { fields, candidateFields, reservationFields, } from '../../shared/models/publicReservation';
import Field from '../Field';

const { entries, keys } = Object;
const findTableCandidates = functions.httpsCallable('findTableCandidates');
const createReservation = functions.httpsCallable('createReservation');

export default function ReservationForm (props) {
  const { values, tenantId, tenant, onChange, onSubmitted, } = props;
  const { courses = [] } = tenant || {};
  const [tableCandidates, setTableCandidate] = useState();
  const [selectedCandidate, selectCandidate] = useState();
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const statedFields = useFormState(null, fields({ courses }));
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onChangeCandidateFields = async ({ peopleCount, date, time }) => {
    if(!(peopleCount && date && time)) return;

    selectCandidate(null);
    const { data: tableCandidates } = await findTableCandidates({ tenantId, peopleCount, date: formatDate(date, 'YYYY/MM/DD'), time: formatDate(time, 'HH:mm'), });
    setTableCandidate(tableCandidates);
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleSubmitting(true);

    await createReservation({
      tenantId,
      data: {
        ...omit(mapValues(statedFields, 'value'), ['date', 'time']),
        startAt: formatDate(selectedCandidate.startAt, 'YYYY/MM/DD HH:mm:ss'),
        endAt: formatDate(selectedCandidate.endAt, 'YYYY/MM/DD HH:mm:ss'),
      },
    });

    onSubmitted();
  };
  useEffect(() => {
    onChangeCandidateFields({ ...mapValues(statedFields, 'value'), });
  }, keys(candidateFields()).map(_ => statedFields[_].value));

  return (
    <div>
      <div className="d-md-flex align-items-end">
        {
          keys(candidateFields()).map((fieldName) => {
            const fieldSetting = statedFields[fieldName];
            return  (
              <div className="mr-2" style={{ minWidth: 150 }}>
                <Field
                  key={fieldName}
                  name={fieldName}
                  {...fieldSetting}
                />
              </div>
            );
          })
        }
      </div>
      {
        tableCandidates && (
          <div className="mt-4 d-flex flex-column align-items-center">
            {
              chunk(tableCandidates, 3).map((_tableCandidates, i) => {
                return (
                  <div key={i} className="mb-3 d-flex justify-content-around" style={{ width: 300 }}>
                    {
                      _tableCandidates.map((tableCandidate, i) => {
                        const { startAt: _startAt, candidateTables, candidateTableCombinables, isVacant = false, } = tableCandidate;
                        return (
                          <Button key={i} disabled={!isVacant} onClick={selectCandidate.bind(null, tableCandidate)} color={tableCandidate === selectedCandidate ? 'info' : 'secondary'}>
                            {formatDate(parseDate(_startAt), 'HH:mm')}
                          </Button>
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        )
      }
      {
        selectedCandidate && (
          <Form className="mt-4" disabled={isUnsubmittable} onSubmit={onSubmit}>
            {
              keys(reservationFields()).map((fieldName) => {
                const fieldSetting = statedFields[fieldName];
                return  (
                  <div>
                    <Field
                      key={fieldName}
                      name={fieldName}
                      {...fieldSetting}
                    />
                  </div>
                );
              })
            }
            <div className="mt-2">
              <Button size="lg" block disabled={isUnsubmittable || isSubmitting}>
                確定する
              </Button>
            </div>
         </Form>
        )
      }
    </div>
  );
};

import React from 'react';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import { Link } from 'react-router-dom';
import { get, keyBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { addHours, format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import { statuses } from '../../shared/models/serviceSyncJob';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import TenantPage from '../hocs/TenantPage';
import EditButton from '../EditButton';
import TenantRouteAliasesFormModal from '../modals/TenantRouteAliasesFormModal';
import TenantHeaderNav from '../TenantHeaderNav';
import { routes, services, } from '../../config';
import { routeLabel, } from '../../shared/util';
import { initialRoute } from '../../utils';

const { entries } = Object;

const db = firebase.firestore();
const serviceSyncJobsRef = db.collection('serviceSyncJobs');
const tenantsRef = db.collection('tenants');
const customRoutesRef = db.collection('customRoutes');

export default TenantPage(function TenantServiceSyncJobs (props) {
  const { tenant, user, match: { params: { slug } } } = props;
  const serviceSyncJobs = useCollectionSubscription(serviceSyncJobsRef.where('tenantSlug', '==', slug).where('completedAt', '==', null).orderBy('createdAt', 'desc').limit(200), [slug]);
  const customRoutes = useCollectionSubscription(customRoutesRef.orderBy('createdAt'));

  return (
    <div className="tenant-service-sync-jobs">
      <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
      <div className="container p-4 pt-5 mt-5">
        <h4>席在庫更新履歴</h4>
        <div className="mt-3">
          <table className="table">
            <thead className="thead-light text-nowrap">
              <tr>
                <th>対象サービス</th>
                <th>処理開始日時</th>
                <th>エラーメッセージ</th>
                <th style={{ minWidth: 200 }}></th>
                <th>予約</th>
              </tr>
            </thead>
            <tbody>
              {
                serviceSyncJobs.map(({ id, ref, failedAt, service, errorMessage, receptNum, reservation, createdAt, retriedAt, retryCount = 0, }) => {
                  const status = failedAt ? (retriedAt > failedAt ? 'retrying' : 'failed') : 'initial';
                  const { label: statusLabel, } = statuses[status];
                  const message = errorMessage && (errorMessage.match(/^\[/) ? errorMessage.replace(/(HP|GN|TB):/, '') : `[${id}] 予期せぬエラーが発生しました。`);
                  const onClickRetry = async () => {
                    if(!window.confirm('本当に再実行しますか？')) return;

                    await ref.update({ retryCount: firebase.firestore.FieldValue.increment(1), retriedAt: new Date(), });
                  };
                  const isOver24Hours = new Date() > addHours(createdAt.toDate(), 24);
                  const canRetry = !isOver24Hours && ['failed', 'retrying'].includes(status) && retryCount < 3;

                  return (
                    <tr key={id}>
                      <td>{services[service]}</td>
                      <td>{formatDate((retriedAt || createdAt).toDate(), 'YYYY/MM/DD HH:mm')}</td>
                      <td>{status === 'failed' && message}</td>
                      <td>
                        {
                          canRetry && (
                            <Button onClick={onClickRetry} disabled={status === 'retrying'}>
                              再実行
                            </Button>
                          )
                        }
                        {
                          isOver24Hours && (
                            <div className="text-muted small">
                              最初の処理開始から24時間以上経過しています
                            </div>
                          )
                        }
                      </td>
                      <td>
                        <Link to={`/t/${slug}/reservations/${receptNum}`} target="_blank">
                          予約詳細
                          <span className="ml-1 fas fa-external-link-alt" />
                        </Link>
                        <div className="text-nowrap">{routeLabel(initialRoute(reservation), routes, customRoutes, tenant.routeAliases)}</div>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

import React from 'react';
import { format as formatDate, setHours, startOfHour, endOfDay, differenceInMinutes } from 'date-fns';
import { useDrop } from 'react-dnd';
import classnames from 'classnames';
import dedent from 'dedent';
import qs from 'qs';

export default function ReservationFrame({ slug, date, table, className, isWaiting = false, ...extraProps }) {
  const [{ isOver }, dropRef] = useDrop({
    accept: 'ReservationBox',
    drop: () => ({ date, table }),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });
  const onClick = () => {
    const message = dedent`
    ${formatDate(date, 'YYYY/MM/DD HH:mm')} 〜
    ${table != null ? table.name : ''}
    この内容で予約開始しますか？
    `;
    if(!window.confirm(message)) return;

    window.open(`/t/${slug}?${qs.stringify({ state: { startAt: formatDate(date, 'YYYY/MM/DD HH:mm:ss'), ...(table && { tableIds: { [table.id]: true, }, disableAllocation: true }), }, response: { type: 'reserve' }, })}`, '_blank');
  };

  return (
    <td ref={dropRef} className={classnames('cursor-pointer schedule-frame', className, { 'table-info': isOver })} {...extraProps} onClick={isWaiting ? (_ => _) : onClick}>
    </td>
  );
};


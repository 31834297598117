import React, { Component } from 'react';
import { Table, Input, Form, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { addMinutes } from 'date-fns';

import MessageForm from '../hocs/MessageForm';
import { computeDuration } from '../../duration';
import './ReservationDate.css';

const { entries } = Object;

export default MessageForm(class ReservationDate extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onAction } = this.props;
    const { startAt, endAt, peopleCount } = this.state;
    onAction({ type: 'submitReservationDate', payload: { startAt, endAt, peopleCount } });
  }
  onSelectStartAt = (value) => {
    this.onChangeDate('startAt', value);
    this.setEndAt(value.toDate());
  }
  setEndAt(startAt) {
    const { payload: { tenant } } = this.props;
    const duration = computeDuration(startAt, tenant);
    this.setState({ endAt: addMinutes(startAt, duration) });
  }
  componentDidMount() {
    this.setEndAt(this.state.startAt);
  }
  isUnsubmittable() {
    const { validations } = this.state;
    return ['peopleCount'].some(_ => !validations[_]);
  }
  render() {
    const { isActive } = this.props;
    const { startAt, endAt, peopleCount } = this.state;
    return (
      <div className="reservation-date">
        <Form onSubmit={this.onSubmit}>
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>日時・人数を選択してください</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-nowrap">
                  開始日時
                </td>
                <td>
                  <DatePicker
                    showTimeSelect
                    dateFormat="YYYY/MM/DD HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    withPortal
                    selected={startAt && moment(startAt)}
                    onChange={this.onSelectStartAt}
                    disabled={!isActive}
                  />
                  {
                    startAt < new Date() && (
                      <div className="text-warning">予約日が過去日です</div>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>
                  終了日時
                </td>
                <td>
                  <DatePicker
                    showTimeSelect
                    dateFormat="YYYY/MM/DD HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    withPortal
                    selected={endAt && moment(endAt)}
                    onChange={this.onChangeDate.bind(this, 'endAt')}
                    disabled={!isActive}
                  />
                  {
                    endAt < new Date() && (
                      <div className="text-warning">予約日が過去日です</div>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>
                  人数
                </td>
                <td>
                  <Input type="number" value={peopleCount || ''} onChange={this.onChangeNumber.bind(this, 'peopleCount')} className={this.validationCss('peopleCount')} disabled={!isActive} placeholder="0" pattern="\d*" />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable() || !isActive}>OK</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </div>
    );
  }
});

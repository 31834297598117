import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { debounce } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';
import { isEmail, isMobilePhone } from 'validator';
import { isHiragana } from 'wanakana';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import firebase from '../../firebase';
import { texts, genders } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const defaultState = {
  nameKana: '',
  name: '',
  phone: undefined,
  customerTags: {},
  gender: 'male',
  email: '',
  birthday: '',
  allergies: {},
  favorites: '',
  dislikes: '',
  smoking: false,
  //  address: '',
  //  division: '',
  //  position: '',
  //  receiptTitle: '',
  //  dm: false,
  //  note: '',
  validations: {},
  isValidating: false,
  isSubmitting: false,
};
const { entries, values, keys } = Object;

export default class CustomerFormModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  setValues() {
    const { values = {} } = this.props;
    this.setState({ ...values });
    entries(values || {}).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      case 'nameKana':
        this.setState({ validations: { ...validations, nameKana: isHiragana(value) } });
        break;
      case 'name':
        this.setState({ validations: { ...validations, name: !!value } });
        break;
      case 'phone':
        this.setState({ isValidating: true });
        this.validatePhone(value);
        break;
      case 'email':
        this.setState({ validations: { ...validations, email: !value || isEmail(value) } });
        break;
      default:
    }
  }
  validatePhone = debounce((value) => {
    const { slug, customerId } = this.props;
    const { validations } = this.state;
    if (value && value === customerId) {
      this.setState({ validations: { ...validations, phone: true } });
      this.setState({ isValidating: false });
      return;
    }
    if (!value) {
      this.setState({ validations: { ...validations, phone: false } });
      this.setState({ isValidating: false });
    } else {
      tenantsRef.doc(slug).collection('customers').doc(value).get().then(({ exists }) => {
        this.setState({ validations: { ...validations, phone: !exists } });
        this.setState({ isValidating: false });
      });
    }
  }, 500)
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChangeText = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    const { nameKana, name, phone, customerTags, gender, email, birthday = null, allergies, favorites, dislikes, smoking } = this.state;
    this.setState({ isSubmitting: true });
    onSubmit({ nameKana, name, phone, customerTags, gender, email, birthday, allergies, favorites, dislikes, smoking });
  }
  onMultiSelect = (name, selectedOptions) => {
    const value = selectedOptions.reduce((x, y) => ({ ...x, [y.value]: true }), {});
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onChoose = (name, value) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onChangeDate = (name, value) => {
    this.validate(name, value);
    this.setState({ [name]: value.toDate() });
  }
  onCheck = (name, { target: { checked: value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  isUnsubmittable() {
    const { isValidating, isSubmitting, validations } = this.state;
    return isSubmitting || isValidating || ['nameKana', 'name', 'phone', 'email'].some(_ => validations[_] === false);
  }
  render() {
    const { isOpen, isNew, onClickClose, data } = this.props;
    const { nameKana, name, phone, customerTags, gender, email, birthday, allergies, favorites, dislikes, smoking } = this.state;
    const customerTagOptions = values(data.customerTags).map(_ => ({ label: _.name, value: _.id }));
    const allergyOptions = values(data.allergies).map(_ => ({ label: _.name, value: _.id }));
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {isNew ? '新規顧客' : '顧客編集'}
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody style={{ maxHeight: '70vh', overflow: 'scroll' }}>
            <FormGroup>
              <Label>ふりがな</Label>
              <Input value={nameKana} onChange={this.onChangeText.bind(this, 'nameKana')} className={this.validationCss('nameKana')}/>
            </FormGroup>
            <FormGroup>
              <Label>名前</Label>
              <Input value={name} onChange={this.onChangeText.bind(this, 'name')} className={this.validationCss('name')}/>
            </FormGroup>
            <FormGroup>
              <Label>電話番号</Label>
              <Input value={phone || ''} onChange={this.onChangeText.bind(this, 'phone')} className={this.validationCss('phone')}/>
            </FormGroup>
            <FormGroup>
              <Label>属性</Label>
              <Select
                isMulti
                options={customerTagOptions}
                value={customerTagOptions.filter(_ => keys(customerTags).includes(_.value))}
                onChange={this.onMultiSelect.bind(this, 'customerTags')}
              />
            </FormGroup>
            <FormGroup>
              <Label className="d-block">性別</Label>
              {
                entries(genders).map(([k, v]) => {
                  return (
                    <FormGroup check inline key={k}>
                      <Label check>
                        <Input type="radio" checked={gender === k} onChange={this.onChoose.bind(this, 'gender', k)} />
                        {v}
                      </Label>
                    </FormGroup>
                  )
                })
              }
            </FormGroup>
            <FormGroup>
              <Label>メールアドレス</Label>
              <Input value={email} onChange={this.onChangeText.bind(this, 'email')} className={this.validationCss('email')}/>
            </FormGroup>
            <FormGroup>
              <Label className="d-block">誕生日</Label>
              <DatePicker
                className="form-control"
                dateFormat="YYYY/MM/DD"
                withPortal
                showYearDropdown
                dropdownMode="select"
                selected={birthday && moment(birthday)}
                onChange={this.onChangeDate.bind(this, 'birthday')}
              />
            </FormGroup>
            <FormGroup>
              <Label>アレルギー</Label>
              <Select
                isMulti
                options={allergyOptions}
                value={allergyOptions.filter(_ => keys(allergies).includes(_.value))}
                onChange={this.onMultiSelect.bind(this, 'allergies')}
              />
            </FormGroup>
            <FormGroup>
              <Label>好きなもの</Label>
              <Input value={favorites} onChange={this.onChangeText.bind(this, 'favorites')} className={this.validationCss('favorites')} />
            </FormGroup>
            <FormGroup>
              <Label>嫌いなもの</Label>
              <Input value={dislikes} onChange={this.onChangeText.bind(this, 'dislikes')} className={this.validationCss('dislikes')} />
            </FormGroup>
            <FormGroup>
              <Label className="d-block">喫煙情報</Label>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={smoking} onChange={this.onCheck.bind(this, 'smoking')} />
                  喫煙
                </Label>
              </FormGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

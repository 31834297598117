import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/ja';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import Root from './components/Root';
import routes from './routes';

import './index.css';

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

ReactDOM.render(
  <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
    <Root routes={routes} />
  </DndProvider>,
  document.getElementById('root')
);

moment.locale('ja');

if (module.hot) {
  module.hot.accept();
}

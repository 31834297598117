import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { keyBy } from 'lodash';
import qs from 'qs';

import ReservationCard from '../ReservationCard';

export default class ReservationModal extends Component {
  render() {
    const { isOpen, onClickClose, slug, customRoutes = [], reservation = {}, tables, courses, tags } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          予約詳細
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="d-flex justify-content-end mb-2">
              <Button tag={Link} to={`/t/${slug}?${qs.stringify({ state: { mode: 'update' }, response: { type: 'submitReservationSelection', payload: { selectedReservationId: reservation.id } } })}`} target="_blank">
                <span className="fas fa-edit mr-1" />
                変更する
              </Button>
            </div>
            <ReservationCard className="mb-3" customRoutes={customRoutes} reservation={reservation} tables={keyBy(tables, 'id')} courses={keyBy(courses, 'id')} tags={keyBy(tags, 'id')} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClickClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
};


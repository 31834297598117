import React from 'react';
import { Badge } from 'reactstrap';
import { statuses, reminderStatuses } from '../config';

export default function({ status, reminder = false, ...options }) {
  const { color, name } = (reminder ? reminderStatuses : statuses)[status] || {};
  return color != null && (
    <Badge pill color={color} {...options}>
      {name}
    </Badge>
  );
};

import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import firebase, { config } from '../../firebase';
import initAuth2 from '../../googleApi';
import TenantAdminPage from '../hocs/TenantAdminPage';

const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { gapi } = window;

export default TenantAdminPage(class TenantAdminCalendar extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.listenTenant();
  }
  listenTenant() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .onSnapshot((snapshot) => {
        this.setState({ tenant: snapshot.data() });
      });
  }
  integrate = async () => {
    const { match: { params: { slug } } } = this.props;
    const auth2 = await initAuth2();
    auth2.grantOfflineAccess().then(({ code }) => {
      tenantsRef
        .doc(slug)
        .update({ googleAuthorizationCode: code });
    });
  }
  unintegrate = () => {
    if(!window.confirm('本当に解除しますか？')) return;
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .update({ googleAuthorizationCode: null, tokens: null });
  }
  render() {
    const { match: { params: { slug } } } = this.props;
    const { tenant } = this.state;
    return (
      <div className="tenant-admin-calendar">
        <div className="d-flex justify-content-center mb-3">
          <h4>カレンダー連携</h4>
        </div>
        {
          tenant && (
            <div className="d-flex justify-content-center">
              {
                tenant.googleAuthorizationCode ? (
                  <Button color="danger" onClick={this.unintegrate}>
                    連携解除
                  </Button>
                ) : (
                  <Button color="primary" onClick={this.integrate}>
                    連携
                  </Button>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
});

import React, { Component, useState, useEffect, } from 'react';
import { Table, Input, Form, Button, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { format as formatDate } from 'date-fns';
import { differenceBy } from 'lodash';

import firebase from '../../firebase';
import MessageForm from '../hocs/MessageForm';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const { keys } = Object;

export default MessageForm(class ViaPhoneNotes extends Component {
  render() {
    const { slug, onAction, payload: { viaPhoneNotes = [] }, data: { tables, waitings = [] }, isActive } = this.props;
    const { selectedId } = this.state;
    return (
      <div className="overflowings">
        {
          viaPhoneNotes.length > 0 ? (
            <Table>
              <tbody>
                {
                  viaPhoneNotes.map((viaPhoneNote) => {
                    return (
                      <Row key={viaPhoneNote.id} {...{ viaPhoneNote, slug, isActive, selectedId, onAction, }} />
                    );
                  })
                }
              </tbody>
            </Table>
          ) : (
            <div>定員オーバー予約はありません</div>
          )
        }
        <div className="mt-2">
          <Button color="primary" onClick={() => onAction({ type: 'toHome' })} disabled={!isActive}>
            終了
          </Button>
        </div>
      </div>
    );
  }
});

function Row(props) {
  const { onAction, selectedId, slug, isActive, viaPhoneNote } = props;
  const { id, body, receptionId, voiceUrl, createdAt } = viaPhoneNote;
  const onClickResolve = () => {
    if(!window.confirm('本当に解決済みにしますか？')) return;
    onAction({ type: 'resolveViaPhoneNotes', payload: { viaPhoneNoteId: id, nextType: 'viewViaPhoneNotes' } });
  }
  const [reservationId, setReservationId] = useState(viaPhoneNote.reservationId);
  useEffect(() => {
    (async () => {
      if(receptionId) {
        const { docs: [doc] } = await tenantsRef.doc(slug).collection('reservations').where('initialReceptionId', '==', receptionId).limit(1).get();
        if(doc) {
          setReservationId(doc.id);
        }
      }
    })();
  }, []);

  return (
    <tr>
      <td className="border-bottom">
        <div className="small">
          該当予約:
          {
            reservationId != null ? (
              <Link to={`/t/${slug}/reservations/${reservationId}`}>
                [{reservationId}]
              </Link>
            ) : (
              <span className="text-muted">なし</span>
            )
          }
        </div>
        <div style={{ whiteSpace: 'pre-line' }}>
          {body}
        </div>
        <audio src={voiceUrl} controls preload />
        <div className="mt-1 d-flex justify-content-around">
          <Button color="success" size="sm" onClick={onClickResolve} disabled={!isActive}>
            解決済みにする
          </Button>
        </div>
      </td>
    </tr>
  );
}

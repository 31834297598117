import React, { Component } from 'react';
import { Form, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import { omit } from 'lodash';

import firebase from '../../firebase';
import TenantAdminPage from '../hocs/TenantAdminPage';
import HpOtherLabelFormModal from '../modals/HpOtherLabelFormModal';
import { texts } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default TenantAdminPage(class TenantAdminHpOtherLabels extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowFormModal: false,
    };
  }
  componentDidMount() {
    this.listenHpOtherLabels();
  }
  listenHpOtherLabels() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('hpOtherLabels')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ hpOtherLabels: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  onSubmitForm = async (values) => {
    const { id } = values;
    const { match: { params: { slug } } } = this.props;
    const isNew = id == null;
    const hpOtherLabelsRef = tenantsRef.doc(slug).collection('hpOtherLabels');
    try {
      await (isNew ? hpOtherLabelsRef.add({ ...omit(values, 'id'), createdAt: firebase.firestore.FieldValue.serverTimestamp() }) : hpOtherLabelsRef.doc(id).update(omit(values, 'id')));
      toast.success(`${isNew ? '追加' : '更新'}しました`);
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    } finally {
      this.closeFormModal()
    }
  }
  delete = (id) => {
    if (!window.confirm('本当に削除しますか？')) return;
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('hpOtherLabels')
      .doc(id)
      .delete()
      .then(() => toast.success('削除しました'));
  }
  onClickAdd = () => {
    this.setState({ targetHpOtherLabelValues: null });
    this.openFormModal();
  }
  onClickEdit = (values) => {
    this.setState({ targetHpOtherLabelValues: values });
    this.openFormModal();
  }
  render() {
    const { match: { params: { slug } } } = this.props;
    const { hpOtherLabels, shouldShowFormModal, targetHpOtherLabelValues } = this.state;
    return (
      <div className="tenant-admin-hp-other-labels">
        <div className="d-flex justify-content-center mb-3">
          <h4>HP看板設定</h4>
        </div>
        <div className="d-flex justify-content-end">
          <Button color="primary" onClick={this.onClickAdd}>
            <span className="fas fa-plus mr-1" />
            新規HP看板
          </Button>
        </div>
        <table className="table mt-5">
          <thead>
            <tr>
              <th>レストランボードでの名称</th>
              <th>予約メールでの名称</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              (hpOtherLabels || []).map(({ id, nameForRB, nameForMail, }) => {
                return (
                  <tr key={id}>
                    <td>
                      {nameForRB}
                    </td>
                    <td>
                      {nameForMail}
                    </td>
                    <td className="text-nowrap text-right">
                      <Button className="mr-2" color="secondary" onClick={this.onClickEdit.bind(this, { id, nameForRB, nameForMail, })}>
                        <span className="fas fa-edit mr-1" />
                        編集
                      </Button>
                      <Button color="danger" onClick={this.delete.bind(this, id)}>
                        <span className="fas fa-trash mr-1" />
                        削除
                      </Button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <HpOtherLabelFormModal values={targetHpOtherLabelValues} slug={slug} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} />
      </div>
    );
  }
});
